import React, { useState } from 'react'
import { t } from 'i18next';
import ModalChangesNotification from 'components/ModalChangesNotification';

export const ElemTable = ({ isCanEdit, selectedText, text, setEditElement, editElement, elem, keyElem }) => {

    const [modalNotification, setModalNotification] = useState({
        success: false,
        onClose: () => setModalNotification({ isOpen: false }),
        isOpen: false,
        text: '',
      });

    const change = (e) => {
        const v = e.target.value;
        setEditElement({
            key: keyElem,
            id: elem.id,
            oldValue: text,
            newValue: v,
            elem
        })
    };

    const openChange = () => {
        if (editElement.id) return;
        if ((keyElem === 'id' || keyElem === 'driverId' || keyElem === 'createdAt' || keyElem === 'country' || keyElem === 'city' || keyElem === 'balance' || keyElem === 'language' || keyElem === 'rating' || keyElem === 'currency' || keyElem === 'isActivated') && isCanEdit) {
            setModalNotification({
                success: false,
                onClose: () => setModalNotification({ isOpen: false }),
                isOpen: true,
                text: t('forbidden_changes'),
              });
            return;
        }
        if (isCanEdit === false) return
        setEditElement({
            key: keyElem,
            id: elem.id,
            oldValue: text,
            newValue: text,
            elem
        })
    };

    return (
        <>
            {modalNotification.isOpen && editElement && <ModalChangesNotification {...modalNotification} />}
            <div className={'table_colums-item ' + (editElement.id === elem.id ? 'active-edit' : '')}>
                {(editElement.id === elem.id && editElement.key === keyElem)
                    ? <input
                        onChange={change}
                        autoFocus={true}
                        value={editElement.newValue}
                    />
                    : <p onClick={openChange}>{selectedText(text)}</p>}
            </div>
        </>
    )
}
