import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import ModalConfirmDelete from '../../ModalConfirmDelete'
import { t } from 'i18next'

@observer
class CarMarkInfo extends Component {
  state = {
    isModalConfirmDelete: false,
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  };

  delBrand = brandID => {
    this.props.close()
    this.props.delBrand(brandID)
  }

  render() {
    const currentBrand = this.props.carBrands.filter( brand => {
      console.log(this.props.carBrands, this.props.brandID, brand.id === this.props.brandID)
      if(brand.id === this.props.brandID){
        return true
      }
      return false
    })

    const models = currentBrand[0].models ? currentBrand[0].models.map( model => {
      return (<div className="row form-row">
        <div className="col-8">{model.name}</div>
        <div className="col-4"><span className="btn btn-warning" 
        onClick={() => { 
           this.setState({ modalSettings : {
            onYes : () => this.props.delModel(this.props.brandID, model.id),
            onClose : () => this.setState({modalSettings : {isOpen : false}}),
            isOpen : true,
            text : t('confirm_delete')
          }})
         }}>{t('delete')}</span></div>
    
        </div>)
    }) : null

    return (
      <div className="col-12">
        <ModalConfirmDelete {...this.state.modalSettings} />
        <div className="row form-row">
          <div className="col-8">
            <strong>{t('marks')}:</strong> {currentBrand[0].name}
          </div>
          <div className="col-4">
            <button className="btn btn-danger"  onClick={() => { 
           this.setState({ modalSettings : {
            onYes : () => this.delBrand(this.props.brandID),
            onClose : () => this.setState({modalSettings : {isOpen : false}}),
            isOpen : true,
            text : t('confirm_delete')
          }})
         }}>{t('delete')}</button>      
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
          <strong>{t('models')}</strong>
          </div>
        </div>
        {models}  
      </div>
    )
  }
}

const withData = inject(stores => ({
  carBrands: stores.CarsMarkStore.carBrands,
  delModel: stores.CarsMarkStore.delModel,
  delBrand: stores.CarsMarkStore.delBrand,
}))

export default withData(CarMarkInfo)
