import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import { MdBlock } from "react-icons/md";
import { t } from "i18next";
import { Input, Button, Icon, Tooltip } from "antd";
import Table from '../Table/index'
import moment from "moment";
import "./styles.scss"
import { TechReviewInfo } from "../../pages/InfoPage/types";
import typeTable from "../Table/constants/typesTable";
import { takePageElements } from "shared/constants";

@observer
class Drivers extends Component {
  state = {
    showModal: false,
    activeDriverID: 0,
    showBlackList: false,
    showCreateDriver: false,
    searchText: "",
    searchedColumn: "",
    csvData: [],
    filterFirstName: "",
    filterLastName: "",
    filterPhoneNumber: "",
    filtercallSign: "",
    filterBalanceFrom: "",
    filterBalanceTo: "",
    filterCommissionFrom: "",
    filterCommissionTo: "",
    filterRatingFrom: "",
    filterRatingTo: "",
    skip: 0,
    take: takePageElements,
    filterDateFrom: null,
    filterDateTo: null,
    filterActivity: "",
    datas: [],
    isGetDatas: false,
    search: '',
    isInputActive: false,
    settingsVisible: false
  };

  applyFilters = () => {
    const filterOptions = {
      skip: this.state.skip,
      take: this.state.take,
      firstName: this.state.filterFirstName,
      lastName: this.state.filterLastName,
      phoneNumber: this.state.filterPhoneNumber,
      callSign: this.state.filtercallSign,
      balanceFrom: this.state.filterBalanceFrom
        ? Number(this.state.filterBalanceFrom)
        : "",
      balanceTo: this.state.filterBalanceTo
        ? Number(this.state.filterBalanceTo)
        : "",
      commissionFrom: this.state.filterCommissionFrom
        ? Number(this.state.filterCommissionFrom)
        : "",
      commissionTo: this.state.filterCommissionTo
        ? Number(this.state.filterCommissionTo)
        : "",
      ratingFrom: this.state.filterRatingFrom
        ? Number(this.state.filterRatingFrom)
        : "",
      ratingTo: this.state.filterRatingTo
        ? Number(this.state.filterRatingTo)
        : "",
    };
    if (this.state.filterDateFrom) {
      const { filterDateFrom } = { ...this.state };
      filterOptions.dateFrom = moment(filterDateFrom).utcOffset(0).endOf("day");
    }
    if (this.state.filterDateTo) {
      const { filterDateTo } = { ...this.state };
      filterOptions.dateTo = filterDateTo.utcOffset(0).endOf("day");
    }
    if (this.state.filterActivity !== "") {
      filterOptions.isActive = (this.state.filterActivity === "true");
    }

    this.props.getDriversData(filterOptions);
  };

  cancelFilters = () => {
    this.setState({
      filterFirstName: "",
      filterLastName: "",
      filterPhoneNumber: "",
      filtercallSign: "",
      filterBalanceFrom: "",
      filterBalanceTo: "",
      filterCommissionFrom: "",
      filterCommissionTo: "",
      filterRatingFrom: "",
      filterRatingTo: "",
      filterDateFrom: null,
      filterDateTo: null,
      filterActivity: "",
    });
  };

  selectedText(additionalText) {
    const search = this.state.search;
    const text = '' + additionalText;
    if (search.trim() === '') return <>{text}</>;
    const startSearch = text.indexOf(search);
    if (startSearch === -1) return <>{text}</>;
    const endSearch = startSearch + search.length;

    const startText = text.substring(0, startSearch);
    const selectedText = text.substring(startSearch, endSearch)
    const endText = text.substring(endSearch);

    return <>{startText}<span className="selectedText" style={{ background: 'red' }}>{selectedText}</span>{endText}</>
  }

  close = () => {
    this.setState({
      showModal: false,
      activeDriverID: 0,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeDriverID: id,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Поиск по ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t('clear')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  csvHeaders = [
    { label: t('id'), key: "id" },
    { label: t('date_of_creation'), key: "createdAt" },
    { label: t('activity'), key: "isActivated" },
    { label: t('call_sign'), key: "callSign" },
    { label: t('name'), key: "firstName" },
    { label: t('last_name'), key: "lastName" },
    { label: t('mobile_number'), key: "phone" },
    { label: t('balance'), key: "balance" },
    { label: t('commission'), key: "commission" },
    { label: t('rating'), key: "rating" },
  ];

  columns = [
    {
      title: t('id'),
      dataIndex: "id",
      render: t => this.selectedText(t)
    },
    {
      title: t('date_of_creation'),
      dataIndex: "createdAt",
      render: (text, record) => {
        const stillUtc = moment.utc(record.createdAt).toDate();
        const local = moment(stillUtc).local().format("DD-MM-YYYY HH:mm");
        return this.selectedText(local)
      },
    },
    {
      title: t('activity'),
      dataIndex: "isActivated",
      render: (text, record) => {
        return this.selectedText(text ? t('active') : t('not_active'))
      },
    },
    {
      title: t('call_sign'),
      dataIndex: "callSign",
      render: t => this.selectedText(t)
    },
    {
      title: t('name'),
      dataIndex: "firstName",
      render: t => this.selectedText(t)
    },
    {
      title: t('last_name'),
      dataIndex: "lastName",
      render: t => this.selectedText(t)
    },
    {
      title: t('mobile_number'),
      dataIndex: "phone",
      render: t => this.selectedText(t)
    },
    {
      title: t('country'),
      dataIndex: "country",
      render: t => this.selectedText(t)
    },
    {
      title: t('balance'),
      dataIndex: "balance",
      render: t => this.selectedText(t)
    },
    {
      title: t('commission'),
      dataIndex: "commission",
      render: t => this.selectedText(t)
    },
    {
      title: t('rating'),
      dataIndex: "rating",
      render: t => this.selectedText(t)
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        return (
          <Tooltip title={t('move_to_blacklist')}>
            <MdBlock
              onClick={() => {
                this.close();
                this.props.block(record.id);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  componentDidMount() {
  }
  componentDidUpdate() {
    if (this.state.datas.length === 0 && this.state.isGetDatas === false) {
      this.setState({ datas: this.props.driversList, isGetDatas: true })
    }

  }

  chgBlacklistStatus = () => {
    this.setState({
      showBlackList: !this.state.showBlackList,
    });
  };

  closeCreateDriver = () => {
    this.setState({
      showCreateDriver: false,
    });
  };

  openCreateDriver = () => {
    this.setState({
      showCreateDriver: true,
    });
  };

  chgDateRange = (date) => {
    if (date.length === 2) {
      this.setState({
        filterDateFrom: date[0].startOf("day"),
        filterDateTo: date[1].endOf("day"),
      });
    }
    console.log(date);
  };

  searchDatas = (event) => {
    const mainValue = event.target.value;
    const valueLowerCase = event.target.value.toLowerCase().trim();

    const datas = this.props.driversList.filter(driver => {
      if (driver.id.includes(valueLowerCase)) return true;
      if (driver.activationDate?.includes(valueLowerCase)) return true;
      if (driver.createdAt?.includes(valueLowerCase)) return true;
      if ((driver.phone + '')?.includes(valueLowerCase)) return true;
      if ((driver.rating + '')?.includes(valueLowerCase)) return true;
      if ((driver.commission + '')?.includes(valueLowerCase)) return true;
      if ((driver.callSign + '')?.includes(valueLowerCase)) return true;
      if ((driver.balance + '')?.includes(valueLowerCase)) return true;
      if (driver.city?.toLowerCase().includes(valueLowerCase)) return true;
      if (driver.country?.toLowerCase().includes(valueLowerCase)) return true;
      if (driver.currency?.toLowerCase().includes(valueLowerCase)) return true;
      if (driver.firstName?.toLowerCase().includes(valueLowerCase)) return true;
      if (driver.language?.toLowerCase().includes(valueLowerCase)) return true;
      if (driver.lastName?.toLowerCase().includes(valueLowerCase)) return true;
    })
    this.setState({ search: mainValue, datas })
  }

  chgInp = (evt) => {
    if (
      evt.target.name === "filterBalanceFrom" ||
      evt.target.name === "filterBalanceTo" ||
      evt.target.name === "filterCommissionFrom" ||
      evt.target.name === "filterCommissionTo" ||
      evt.target.name === "filterRatingFrom" ||
      evt.target.name === "filterRatingTo"
    ) {
      const numberPattern = new RegExp(/^-?\d*\.?\d*$/);
      const regexpResult = numberPattern.test(evt.target.value);

      if (!regexpResult) {
        return;
      }
    }
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  handleInputFocus = () => {
    this.setState({ isInputActive: true });
  };

  handleInputBlur = () => {
    this.setState({ isInputActive: false });
  };

  clearSearch = () => {
    this.setState({ search: '' });
  };

  toggleSettings = () => {
    this.setState({ settingsVisible: true });
  };

  render() {
    return (
      <div>
        <Table
          headerName={'driver info'}
          keys={typeTable.driver.keys}
          showKeys={'driver'}
          special={TechReviewInfo.DRIVER_INFO}
          getData={this.props.getDriversData}
          changeLengthForHeader={this.props.getLengthForHeader}
          data={this.props.driversList}
          totalCount={this.props.driverAmount}
          getUsersNameForHeader={this.props.getUsersNameForHeader}
          openInfoPage={this.props.openInfoPage}
          getLengthForHeader={this.props.getLengthForHeader}
          delDriver={this.props.delDriver}
          blockDriver={this.props.blockDriver}
          unblockDriver={this.props.unblockDriver}
          cityList={this.props.cityList}
          getCityData={this.props.getCityData}
          addCity={this.props.addCity}
          delCity={this.props.delCity}
          updCityName={this.props.updCityName}
          updateDriver={this.props.updateDriver}
          brandList={this.props.brandList}
          brandsWithModels={this.props.brandsWithModels}
          carClassList={this.props.carClassList}
          getCarClassData={this.props.getCarClassData}
        />
      </div>
    );
  }
}

const withData = inject((stores) => ({
  openInfoPage: stores.InfoPageStore.openInfoPage,
  getUsersNameForHeader: stores.HeaderStore.getUsersNameForHeader,
  driversList: stores.DriversStore.driversList,
  driverAmount: stores.DriversStore.driverAmount,
  getDriversData: stores.DriversStore.getDriversData,
  getDriver: stores.DriversStore.getDriver,
  block: stores.DriversStore.block,
  getLengthForHeader: stores.HeaderStore.getLengthForHeader,
  delDriver: stores.DriversStore.delDriver,
  blockDriver: stores.DriversStore.blockDriver,
  unblockDriver: stores.DriversStore.unblockDriver,
  cityList: stores.CityStore.cityList,
  getCityData: stores.CityStore.getCityData,
  addCity: stores.CityStore.addCity,
  delCity: stores.CityStore.delCity,
  updCityName: stores.CityStore.updCityName,
  updateDriver: stores.DriversStore.updateDriver,
  brandList: stores.CarsMarkStore.carBrands,
  brandsWithModels: stores.CarStore.brandsWithModels,
  carClassList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
}));

export default withData(Drivers);