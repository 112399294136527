import React, { Fragment } from 'react'
import './ModalConfirmDelete.scss'
import { t } from 'i18next';
import { ReactComponent as Close } from './Close.svg';

const ModalConfirmDelete = ({ onYes, onClose, text, isOpen }) => {
    if (isOpen === false) return null;
    const onYesAndClose = (data) => {
        onYes(data)
        onClose()
    }
    return (
        <Fragment>
            <div className="container-fluid p-0 m-0 modal-g">
                <div className="row m-0 modal-c">
                    <div className="modal">
                        <div className="modalalert-section col-sm-12 p-0">
                            <div className='modal-text-top'>
                                <p className='top-nav-text-delete'>{t('warning')}</p>
                                <Close className='close-icon' onClick={onClose}/>
                            </div>
                            <div className='middle_text_main'>
                                <p>{t('confirm_patern')} {text}</p>
                            </div>
                            <div className='confirm-delete-section'>
                                <button onClick={onYesAndClose}><p>{t('yes')}</p></button>
                                <button onClick={onClose}><p>{t('no')}</p></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ModalConfirmDelete;