import React, { useMemo } from 'react';
import { t } from "i18next";
import './styles.scss';
import { getNameHeader } from '../Table/store';

const ToogleSettings = ({ setKeys, keys }) => {

    const memoKeys = useMemo(() => {
        const data = []
        const change = (key, data) => setKeys({ ...keys, [key]: data })
        for (const key in keys) {
            const isData = keys[key]
            data.push(<div className='toogle-settings-item' key={key}>
                <p>{t(getNameHeader(key))}</p>
                <label className={`switch ${isData ? 'checked' : ''}`}>
                    <input type="checkbox" checked={isData} onChange={() => change(key, !isData)} />
                    <span className={`slider ${isData ? 'round' : ''}`}></span>
                </label>
            </div>)
        }
        return data
    }, [keys])

    return (
        <div className='toogle-settings-block'>
            {memoKeys}
        </div>
    );
};

export default ToogleSettings;