import React from 'react';
import { t } from 'i18next';
import { DatePicker } from 'antd';
import { ReactComponent as SearchIcon } from "../../../../assets/SearchIcon.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/ClearIcon.svg";
import { ReactComponent as ChevronDownIcon } from '../../../../assets/ChevronDownIcon.svg';
import { ReactComponent as ChevronUpIcon } from '../../../../assets/ChevronUpIcon.svg';
import { ReactComponent as CheckLineIcon } from '../../../../assets/CheckLineIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/CalendarIcon.svg';
import { TechReviewInfo } from 'pages/InfoPage/types';
import './styles.scss'

const FiltersBlock = ({
    isFocusInput,
    setFocusInput,
    searchText,
    setSearchText,
    changeSearchText,
    clearSearchText,
    isDropdownDate,
    setIsDropdownDate,
    selectedTimeFilter,
    setSelectedTimeFilter,
    selectedDateRange,
    handleDateRangeChange,
    isDropdownStatus,
    setIsDropdownStatus,
    selectedStatusFilter,
    handleStatusOption,
    optionsStatuses,
    isDropdownCountry,
    setIsDropdownCountry,
    selectedCountryFilter,
    handleCountryOption,
    optionsCountry,
    isDropdownCities,
    setIsDropdownCities,
    selectedCityFilter,
    handleCityOption,
    optionsCities,
    special,
    setSelectedCityFilter,
    optionsBrands,
    isDropdownBrand,
    setIsDropdownBrand,
    selectedBrandFilter,
    handleBrandOption,
    optionsModels,
    isDropdownModel,
    setIsDropdownModel,
    selectedModelFilter,
    handleModelOption,
    optionsCarClass,
    isDropdownCarClass,
    setIsDropdownCarClass,
    selectedCarClassFilter,
    handleCarClassOption,
    handleClearDate,
    isDatePickerOpen,
    setIsDatePickerOpen
}) => {
    const renderFilters = () => {
        switch (special) {
            case TechReviewInfo.DRIVER_INFO:
                return (
                    <div className="left-search-bar">
                        <div className={`all-search-input ${isFocusInput ? 'all-search-input-active' : ''}`}>
                            <SearchIcon />
                            <input
                                onBlur={() => setFocusInput(false)}
                                onFocus={() => setFocusInput(true)}
                                name='searchText'
                                onChange={(e) => { setSearchText(e.target.value); changeSearchText(e.target.value) }}
                                placeholder={t('input_placeholder')}
                                value={searchText}
                            />
                            {searchText !== '' &&
                                <ClearIcon
                                    className="clear-btn-icon"
                                    name='searchText'
                                    onClick={clearSearchText}
                                />
                            }
                        </div>
                        <div className='filters-bar'>
                            <div className='change-big-filter-block'>
                                <div className={`change-filter-block-selected-option ${isDatePickerOpen ? 'change-filter-block-selected-option-active' : ''} ${selectedTimeFilter === 'create_date' ? 'default-color' : 'selected-date-filter'}`}>
                                    <input
                                        value={t(selectedTimeFilter)}
                                        onChange={(e) => setSelectedTimeFilter(e.target.value)}
                                    />
                                    {selectedTimeFilter !== 'create_date'
                                        ? <ClearIcon onClick={handleClearDate} />
                                        : <CalendarIcon />
                                    }
                                </div>
                                <div className='change-filter-block-options date-picker-block'>
                                    <DatePicker.RangePicker
                                        value={selectedDateRange}
                                        onChange={handleDateRangeChange}
                                        locale={{ momentLocale: 'uk' }}
                                        style={{ background: 'red' }}
                                        onOpenChange={isOpen => setIsDatePickerOpen(isOpen)}
                                    />
                                </div>
                            </div>
                            <div className='change-filter-block'>
                                <div className={`change-filter-block-selected-option ${isDropdownStatus ? 'change-filter-block-selected-option-active' : ''}`} onClick={() => setIsDropdownStatus(!isDropdownStatus)}>
                                    <p className={selectedStatusFilter === 'all_statuses' ? 'default-color' : ''}
                                    >{t(selectedStatusFilter)}</p>
                                    {isDropdownStatus ?
                                        <ChevronUpIcon /> :
                                        <ChevronDownIcon />
                                    }
                                </div>
                                {isDropdownStatus && (
                                    <div className='change-filter-block-options'>
                                        {optionsStatuses.map((c) => (
                                            <div
                                                key={c}
                                                className={`change-filter-block-single-option ${selectedStatusFilter === c ? 'change-filter-block-single-option-active' : ''}`}
                                                onClick={() => handleStatusOption(c, 'isActive')}
                                            >
                                                <p>{t(c)}</p>
                                                {selectedStatusFilter === c && <CheckLineIcon />}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className='change-filter-block'>
                                <div className={`change-filter-block-selected-option ${isDropdownCountry ? 'change-filter-block-selected-option-active' : ''}`} onClick={() => setIsDropdownCountry(!isDropdownCountry)}>
                                    <p className={selectedCountryFilter === 'all_countries' ? 'default-color' : ''}>{t(selectedCountryFilter)}</p>
                                    {isDropdownCountry ?
                                        <ChevronUpIcon /> :
                                        <ChevronDownIcon />
                                    }
                                </div>
                                {isDropdownCountry && (
                                    <div className='change-filter-block-options'>
                                        {optionsCountry.map((c) => (
                                            <div
                                                key={c}
                                                className={`change-filter-block-single-option ${selectedCountryFilter === c ? 'change-filter-block-single-option-active' : ''}`}
                                                onClick={() => handleCountryOption(c)}
                                            >
                                                <p>{t(c)}</p>
                                                {selectedCountryFilter === c && <CheckLineIcon />}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className='change-second-big-filter-block'>
                                <div className={`change-filter-block-selected-option ${isDropdownCities ? 'change-filter-block-selected-option-active' : ''} ${selectedCityFilter.name === 'all_cities' ? 'default-color' : ''}`} onClick={() => setIsDropdownCities(!isDropdownCities)}>
                                    <input
                                        value={t(selectedCityFilter.name)}
                                        onChange={(e) => setSelectedCityFilter(e.target.value)}
                                    />
                                    {isDropdownCities ?
                                        <ChevronUpIcon /> :
                                        <ChevronDownIcon />
                                    }
                                </div>
                                {isDropdownCities && (
                                    <div className='change-filter-block-options'>
                                        {optionsCities.map((c) => (
                                            <div
                                                key={c.id}
                                                className={`change-filter-block-single-option ${selectedCityFilter.id === c.id ? 'change-filter-block-single-option-active' : ''}`}
                                                onClick={() => handleCityOption(c, 'city')}
                                            >
                                                <p>{t(c.name)}</p>
                                                {selectedCityFilter.id === c.id && <CheckLineIcon />}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            case TechReviewInfo.INSPECTION_INFO:
                return (
                    <div className="left-search-bar">
                        <div className='parts-of-filters'>
                            <div className='first_part_filters'>
                                <div className={`all-search-input ${isFocusInput ? 'all-search-input-active' : ''}`}>
                                    <SearchIcon />
                                    <input
                                        onBlur={() => setFocusInput(false)}
                                        onFocus={() => setFocusInput(true)}
                                        name='searchText'
                                        onChange={(e) => { setSearchText(e.target.value); changeSearchText(e.target.value) }}
                                        placeholder={t('input_placeholder')}
                                        value={searchText}
                                    />
                                    {searchText !== '' &&
                                        <ClearIcon
                                            className="clear-btn-icon"
                                            name='searchText'
                                            onClick={clearSearchText}
                                        />
                                    }
                                </div>
                                <div className='filters-bar'>
                                    {/* times filter */}
                                    <div className='change-big-filter-block'>
                                        <div className={`change-filter-block-selected-option ${isDatePickerOpen ? 'change-filter-block-selected-option-active' : ''} ${selectedTimeFilter === 'create_date' ? 'default-color' : 'selected-date-filter'}`}>
                                            <input
                                                value={t(selectedTimeFilter)}
                                                onChange={(e) => setSelectedTimeFilter(e.target.value)}
                                            />
                                            {selectedTimeFilter !== 'create_date'
                                                ? <ClearIcon onClick={handleClearDate} />
                                                : <CalendarIcon />
                                            }
                                        </div>
                                        <div className='change-filter-block-options date-picker-block'>
                                            <DatePicker.RangePicker
                                                value={selectedDateRange}
                                                onOpenChange={isOpen => setIsDatePickerOpen(isOpen)}
                                                onChange={handleDateRangeChange}
                                                locale={{ momentLocale: 'uk' }}
                                            />
                                        </div>
                                    </div>
                                    {/* statuses filter */}
                                    <div className='change-filter-block'>
                                        <div className={`change-filter-block-selected-option ${isDropdownStatus ? 'change-filter-block-selected-option-active' : ''}`} onClick={() => setIsDropdownStatus(!isDropdownStatus)}>
                                            <p className={selectedStatusFilter === 'all_statuses' ? 'default-color' : ''}
                                            >{t(selectedStatusFilter)}</p>
                                            {isDropdownStatus ?
                                                <ChevronUpIcon /> :
                                                <ChevronDownIcon />
                                            }
                                        </div>
                                        {isDropdownStatus && (
                                            <div className='change-filter-block-options'>
                                                {optionsStatuses.map((c) => (
                                                    <div
                                                        key={c}
                                                        className={`change-filter-block-single-option ${selectedStatusFilter === c ? 'change-filter-block-single-option-active' : ''}`}
                                                        onClick={() => handleStatusOption(c, 'isActive')}
                                                    >
                                                        <p>{t(c)}</p>
                                                        {selectedStatusFilter === c && <CheckLineIcon />}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {/* classes filter */}
                                    <div className='change-filter-block'>
                                        <div className={`change-filter-block-selected-option ${isDropdownCarClass ? 'change-filter-block-selected-option-active' : ''}`} onClick={() => setIsDropdownCarClass(!isDropdownCarClass)}>
                                            <p className={selectedCarClassFilter.name === 'car_class' ? 'default-color' : ''}>{t(selectedCarClassFilter.name)}</p>
                                            {isDropdownCarClass ?
                                                <ChevronUpIcon /> :
                                                <ChevronDownIcon />
                                            }
                                        </div>
                                        {isDropdownCarClass && (
                                            <div className='change-filter-block-options'>
                                                {optionsCarClass.map((c) => (
                                                    <div
                                                        key={c}
                                                        className={`change-filter-block-single-option ${selectedCarClassFilter === c ? 'change-filter-block-single-option-active' : ''}`}
                                                        onClick={() => handleCarClassOption(c, 'CarClass')}
                                                    >
                                                        <p>{t(c.name)}</p>
                                                        {selectedCarClassFilter.id === c.id && <CheckLineIcon />}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='second-part-filters'>
                                <div className='top-filters'>
                                    {/* counties filter */}
                                    <div className='change-filter-block'>
                                        <div className={`change-filter-block-selected-option ${isDropdownCountry ? 'change-filter-block-selected-option-active' : ''}`} onClick={() => setIsDropdownCountry(!isDropdownCountry)}>
                                            <p className={selectedCountryFilter === 'all_countries' ? 'default-color' : ''}>{t(selectedCountryFilter)}</p>
                                            {isDropdownCountry ?
                                                <ChevronUpIcon /> :
                                                <ChevronDownIcon />
                                            }
                                        </div>
                                        {isDropdownCountry && (
                                            <div className='change-filter-block-options'>
                                                {optionsCountry.map((c) => (
                                                    <div
                                                        key={c}
                                                        className={`change-filter-block-single-option ${selectedCountryFilter === c ? 'change-filter-block-single-option-active' : ''}`}
                                                        onClick={() => handleCountryOption(c)}
                                                    >
                                                        <p>{t(c)}</p>
                                                        {selectedCountryFilter === c && <CheckLineIcon />}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {/* cities filter */}
                                    <div className={`change-filter-block ${selectedCountryFilter === 'all_countries' ? 'change-filter-block-disabled' : ''}`}>
                                        <div className={`change-filter-block-selected-option ${isDropdownCities ? 'change-filter-block-selected-option-active' : ''} ${selectedCityFilter.name === 'all_cities' ? 'default-color' : ''}`} onClick={() => setIsDropdownCities(!isDropdownCities)}>
                                            <p className={selectedCityFilter.name === 'all_cities' ? 'default-color' : ''}>{t(selectedCityFilter.name)}</p>
                                            {isDropdownCities ?
                                                <ChevronUpIcon /> :
                                                <ChevronDownIcon />
                                            }
                                        </div>
                                        {isDropdownCities && (
                                            <div className='change-filter-block-options'>
                                                {optionsCities.map((c) => (
                                                    <div
                                                        key={c.id}
                                                        className={`change-filter-block-single-option ${selectedCityFilter.id === c.id ? 'change-filter-block-single-option-active' : ''}`}
                                                        onClick={() => handleCityOption(c, 'city')}
                                                    >
                                                        <p>{t(c.name)}</p>
                                                        {selectedCityFilter.id === c.id && <CheckLineIcon />}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='top-filters'>
                                    {/* brands filter */}
                                    <div className='change-filter-block'>
                                        <div className={`change-filter-block-selected-option ${isDropdownBrand ? 'change-filter-block-selected-option-active' : ''}`} onClick={() => setIsDropdownBrand(!isDropdownBrand)}>
                                            <p className={selectedBrandFilter.name === 'car_brand' ? 'default-color' : ''}>{t(selectedBrandFilter.name)}</p>
                                            {isDropdownBrand ?
                                                <ChevronUpIcon /> :
                                                <ChevronDownIcon />
                                            }
                                        </div>
                                        {isDropdownBrand && (
                                            <div className='change-filter-block-options'>
                                                {optionsBrands.map((c) => (
                                                    <div
                                                        key={c}
                                                        className={`change-filter-block-single-option ${selectedBrandFilter === c ? 'change-filter-block-single-option-active' : ''}`}
                                                        onClick={() => handleBrandOption(c, 'brand')}
                                                    >
                                                        <p>{t(c.name)}</p>
                                                        {selectedBrandFilter.id === c.id && <CheckLineIcon />}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {/* models filter */}
                                    <div className={`change-filter-block ${selectedBrandFilter.name === 'car_brand' ? 'change-filter-block-disabled' : ''}`}>
                                        <div className={`change-filter-block-selected-option ${isDropdownModel ? 'change-filter-block-selected-option-active' : ''}`} onClick={() => setIsDropdownModel(!isDropdownModel)}>
                                            <p className={selectedModelFilter.name === 'car_model' ? 'default-color' : ''}>{t(selectedModelFilter.name)}</p>
                                            {isDropdownModel ?
                                                <ChevronUpIcon /> :
                                                <ChevronDownIcon />
                                            }
                                        </div>
                                        {isDropdownModel && (
                                            <div className='change-filter-block-options'>
                                                {optionsModels.map((c) => (
                                                    <div
                                                        key={c}
                                                        className={`change-filter-block-single-option ${selectedModelFilter === c ? 'change-filter-block-single-option-active' : ''}`}
                                                        onClick={() => handleModelOption(c, 'model')}
                                                    >
                                                        <p>{t(c.name)}</p>
                                                        {selectedModelFilter.id === c.id && <CheckLineIcon />}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {renderFilters()}
        </>
    );
}

export default FiltersBlock;
