import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ReactComponent as AvatarIcon } from "../../assets/AvatarIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/LogoutIcon.svg";
import { t } from 'i18next';
import "./styles.scss";
import ConfirmActionModal from "../ConfirmActionModal";

@observer
class TopHeader extends Component {
  state = {
    isHovered: false,
    modalLogout: {
      onAction: () => { },
      onClose: () => this.setState({ modalLogout: { isOpen: false } }),
      isOpen: false,
      text: '',
    },
  };

  confirmLogout = () => {
    const onSave = () => {
      this.props.LoginFromStore.logout()
    };

    this.setState({
      modalLogout: {
        onAction: onSave,
        onClose: () => this.setState({ modalLogout: { isOpen: false } }),
        isOpen: true,
        text: t('confirm_logout'),
      },
    });
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {

    const { pageTitle, lengthHeader, usersName } = this.props;
    const currentPath = window.location.pathname;

    return (
      <div className="top-main-header">
        {this.state.modalLogout.isOpen && (
          <ConfirmActionModal {...this.state.modalLogout} />
        )}
        {currentPath === "/info-page"
          ?
          <div className="left-side-bar">
            <div className="page-title-grey">
              <p>{t(pageTitle)}</p>
            </div>
            <div className="count-of-users">
              <p>{lengthHeader === null ? '' : lengthHeader} /</p>
            </div>
            <div className="users-name">
              <p>{usersName}</p>
            </div>
          </div>
          :
          <div className="left-side-bar">
            <div className="page-title">
              <p>{t(pageTitle)}</p>
            </div>
            {currentPath !== "/gps-problem" &&
              <div className="count-of-users">
                <p>{lengthHeader === null ? '' : lengthHeader}</p>
              </div>
            }
          </div>
        }
        <div
          className="right-side-bar"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}>
          <div className="operator-info">
            <p>Nemo</p>
            <AvatarIcon />
          </div>
          {this.state.isHovered &&
            <div className="modal-exit-window" onClick={this.confirmLogout}>
              <LogoutIcon />
              <p>{t('exit_logout')}</p>
            </div>}
        </div>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  LoginFromStore: stores.LoginFromStore,
  lengthHeader: stores.HeaderStore.lengthHeader,
  usersName: stores.HeaderStore.usersName,
}));

export default withData(TopHeader);