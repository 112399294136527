import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../config";

class CashOutStoreStore {
  @observable cashOutList = [];
  @observable isError = "";

  getCashOutList = (filter) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}cashOut/list`, filter, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.cashOutList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  approveCashOut = (id, type) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}cashOut/approve`,
        { id, method: type },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCashOutList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  declineCashOut = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}cashOut/decline`,
        { id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCashOutList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new CashOutStoreStore();
