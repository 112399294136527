import React from "react";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import { t } from "i18next";

const AddEditCarClass = observer(
  ({
    id,
    carClassName,
    priority,
    isError,
    addCarClass,
    updClass,
    clearForm,
  }) => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            {id ? <>{t('edit_class')}</> : <>{t('add_class')}</>}
          </div>
        </div>
        <Formik
          initialValues={{ carClassName, priority }}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          validate={(values) => {
            const errors = {};

            if (!values.carClassName) {
              errors.carClassName = t('required_field');
            } else if (values.carClassName.length < 5) {
              errors.carClassName = t('specify_class_name');
            }

            if (!values.priority) {
              errors.priority = t('required_field');
            } else if (!Number.isInteger(values.priority)) {
              errors.priority = t('incorrect_priority_value');
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (id) {
              updClass(id, values.carClassName, values.priority);
              clearForm();
            } else {
              addCarClass(values.carClassName, values.priority);
            }
            values.carClassName = "";
            values.priority = "";
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="row">
                  {isError ? (
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        {isError}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-4">
                    <div className="col-12">
                      <b>{t('class_name')}:</b>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        name="carClassName"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.carClassName}
                      />
                    </div>
                    {errors.carClassName &&
                    touched.carClassName &&
                    errors.carClassName ? (
                      <div className="col-12" style={{ marginTop: "20px" }}>
                        <div className="alert alert-danger" role="alert">
                          {errors.carClassName &&
                            touched.carClassName &&
                            errors.carClassName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-4">
                    <div className="col-12">
                      <b>{t('priority')}:</b>
                    </div>
                    <div className="col-12">
                      <input
                        type="number"
                        name="priority"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.priority}
                      />
                    </div>
                    {errors.priority && touched.priority && errors.priority ? (
                      <div className="col-12" style={{ marginTop: "20px" }}>
                        <div className="alert alert-danger" role="alert">
                          {errors.priority &&
                            touched.priority &&
                            errors.priority}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="col-2"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary btn-block"
                    >
                      {id ? t('change') : t('add')}
                    </button>
                  </div>
                  {id ? (
                    <div
                      className="col-2"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={() => clearForm()}
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  }
);

const withData = inject((stores) => ({
  addCarClass: stores.CarsClassStore.addCarClass,
  updClass: stores.CarsClassStore.updClass,
  isError: stores.FeesReasonsStore.isError,
}));

export default withData(AddEditCarClass);
