import { observable, action } from "mobx";
import axios from "axios";
import { createNotification } from "../Notifications";
import moment from "moment";
import { takePageElements } from 'shared/constants'
// import {updToken} from '../../helpers/refresh'

import { apiIdentity } from "../../config";
class CarsStore {
  @observable isError = false;
  @observable carsList = [];
  @observable activeCar = {};
  @observable activeCarTO = [];
  @observable isCarInspError = false;

  getCarsData = (options) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}cars/list`, options, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.carsList = [...response.data.data.content];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addNewCar = (carData) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}cars`, carData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCarsData({
            skip: 0,
            take: takePageElements,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  @action
  getCar = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    fetch(`${apiIdentity}cars/${id}`, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Work get car");
        if (result.success === false) {
          this.isError = true;
          this.activeCar = {};
        } else {
          this.activeCar = { ...result.data };
        }
      })
      .catch((err) => {
        console.log(err);
        createNotification("error", "Get data error", `Server error`, 3000);
      });
    // axios.get(`${apiIdentity}cars/${id}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
    //   .then( response => {
    //     console.log(response.data)
    //     if(response.data.success === false){
    //       this.isError = true
    //       this.activeCar = {}
    //     } else {
    //       this.activeCar = {...response.data.data}
    //     }
    //   })
    //   .catch( err => {
    //     console.log(err)
    //   })
  };

  updateCar = () => {
    const accessToken = localStorage.getItem("accessToken");
    const updCarInfo = { ...this.activeCar };
    delete updCarInfo.orderPreferences;

    axios
      .put(`${apiIdentity}cars/${this.activeCar.id}`, updCarInfo, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCarsData({
            skip: 0,
            take: takePageElements,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  chgCarInfo = (evt) => {
    console.log(evt);
    this.activeCar[evt.target.name] = evt.target.value;
  };

  delCar = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}cars/${this.activeCar.id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCarsData({
            skip: 0,
            take: takePageElements,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCarTO = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}v2/inspection`,
        { skip: 0, take: takePageElements, carId: this.activeCar.id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          const fullEditRecord = response.data.data.map((record) => {
            const editRecord = { ...record };
            if (editRecord.status == 0) {
              editRecord.status = "Новый";
            } else if (editRecord.status == 1) {
              editRecord.status = "Одобрен";
            } else if (editRecord.status == 2) {
              editRecord.status = "Отклонен";
            }

            const stillUtc = moment.utc(record.createdAt).toDate();
            const local = moment(stillUtc)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
            editRecord.createdAt = local;

            return editRecord;
          });

          this.activeCarTO = [...fullEditRecord];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDriverCarsData = (driverId) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}cars/list`,
        { skip: 0, take: takePageElements, driverId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.carsList = [...response.data.data.content];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resetRequireCarToError = () => {
    this.isCarInspError = false;
  };

  requireCarTo = (driverId, carId, callback) => {
    const accessToken = localStorage.getItem("accessToken");
    this.isCarInspError = false;
    axios
      .post(
        `${apiIdentity}v2/drivers/${driverId}/reset-car-inspection`,
        { carId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isCarInspError = true;
        } else {
          callback();
        }
      })
      .catch((err) => {
        this.isCarInspError = true;
        console.log(err);
      });
  };
}

export default new CarsStore();
