import React from 'react';
import "./styles.scss";

const LoadingElement = () => {
  return (
    <div className='modal_opacity-loading'>
      <div className='modal-content-block-loading'>
        <div className='modal-content-block-loading-circle'></div>
      </div>
    </div>
  );
};

export default LoadingElement;
