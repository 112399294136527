import React, { Component } from "react";
import NavMenu from "../../components/NavMenu";
import Payments from "../../components/Payments";
import { t } from "i18next";

export default class PaymentsPage extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-1">
          <NavMenu />
        </div>
        <div className="col-11">
          <div className="row">
            <div className="col">
              <h3>{t('transactions')}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Payments />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
