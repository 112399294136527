import React, { Component } from "react";
import { Table } from "antd";
import { inject, observer } from "mobx-react";
import InspectionInfo from "../../pages/CarInspections/components/InspectionInfo";
import ModalSimple from "../ModalSimple";
import { t } from "i18next";

@observer
class CarInfoTO extends Component {
  state = {
    showModal: false,
    activeInspectionID: 0,
  };

  componentDidMount() {
    this.props.getCarTO();
  }

  columns = [
    {
      title: t('first_name_last_name'),
      dataIndex: "driverName",
    },
    {
      title: t('driver'),
      dataIndex: "carBrandAndModel",
    },
    {
      title: t('date_of_creation'),
      dataIndex: "createdAt",
    },
    {
      title: t('status'),
      dataIndex: "status",
    },
  ];

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeInspectionID: id,
    });
  };

  close = () => {
    this.setState({
      showModal: false,
      activeInspectionID: 0,
    });
  };

  render() {
    return (
      <div>
        <Table
          columns={this.columns}
          dataSource={this.props.activeCarTO}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <InspectionInfo inspectionID={this.state.activeInspectionID} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  activeCarTO: stores.CarsStore.activeCarTO,
  getCarTO: stores.CarsStore.getCarTO,
}));

export default withData(CarInfoTO);
