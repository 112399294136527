import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../../config";

class CarsMarkStore {
  @observable isError = false;
  @observable carBrands = [];

  getCarBrandsData = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}catalog/brands-with-models`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.carBrands = [...response.data.data.items];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addCarBrand = (brandName) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .post(
        `${apiIdentity}catalog/brands`,
        { name: brandName },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.Success === false) {
          this.isError = true;
        } else {
          this.getCarBrandsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addCarMark = (brandName, markName) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .post(
        `${apiIdentity}catalog/brands/${brandName}/models`,
        { brandId: brandName, name: markName },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.Success === false) {
          this.isError = true;
        } else {
          this.getCarBrandsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeBrand = (brandId) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .delete(`${apiIdentity}catalog/brands/${brandId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.Success === false) {
          this.isError = true;
        } else {
          this.getCarBrandsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeBrandMark = (brandId, markId) => {
    const accessToken = localStorage.getItem("accessToken");
    const brandsData = [...this.carBrands];
    const updBrandsList = brandsData.map((item) => {
      if (item.brand.id === brandId) {
        const updMarksList = item.models.filter((model) => {
          if (model.id === markId) {
            return false;
          }
          return true;
        });
        return { ...item, marks: updMarksList };
      }
      return item;
    });
    axios
      .post(`${apiIdentity}catalog/brands`, updBrandsList, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.Success === false) {
          this.isError = true;
        } else {
          this.getCarBrandsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delModel = (brandID, markID) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}catalog/brands/${brandID}/models/${markID}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.Success === false) {
          this.isError = true;
        } else {
          this.getCarBrandsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delBrand = (brandID) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}catalog/brands/${brandID}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.Success === false) {
          this.isError = true;
        } else {
          this.getCarBrandsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  importFromAutoRia = () => {
    console.log("importFromAutoRia");
    axios
      .get("https://api.auto.ria.com/categories/1/marks")
      .then((response) => {
        let timeout = 1000;
        response.data.map((item, i) => {
          const accessToken = localStorage.getItem("accessToken");
          if (i % 20 === 0) {
            timeout = timeout + 1000;
          }
          axios
            .post(
              `${apiIdentity}catalog/brands`,
              { name: item.name },
              { headers: { Authorization: `Bearer ${accessToken}` } }
            )
            .then((response) => {
              if (response.data.Success === false) {
              } else {
                const importBrandID = response.data.data.id;
                axios
                  .get(
                    "https://api.auto.ria.com/categories/1/marks/" +
                      item.value +
                      "/models"
                  )
                  .then((marksResp) => {
                    marksResp.data.map((mark) => {
                      setTimeout(
                        this.addCarMark(importBrandID, mark.name),
                        timeout
                      );
                      return mark;
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
          return item;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new CarsMarkStore();
