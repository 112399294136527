import React, { Component } from "react";
import { Table, DatePicker } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { t } from 'i18next'

import OrderInfo from "../Orders/OrderInfo";
import ModalSimple from "../ModalSimple";
import PassengersInfo from "../Passengers/PassengersInfo";

const { RangePicker } = DatePicker;

@observer
class DriverHistory extends Component {
  state = {
    showModal: false,
    activeOrder: 0,
    showClientModal: false,
    activeClientID: 0,
    fromTime: null,
    tillTime: null,
  };

  componentDidMount() {
    this.props.getDriverOrdersData(this.props.driverId);
  }

  columns = [
    {
      title: t('route'),
      dataIndex: "destinationPoints",
    },
    {
      title: t('client'),
      dataIndex: "clientFullName",
      render: (text, record) => (
        <span
          onClick={() => this.showClientModal(record.clientId)}
          style={{ color: "#007bff", cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: t('amount'),
      dataIndex: "price",
    },
    {
      title: t('order_options'),
      dataIndex: "orderPreferences",
    },
  ];

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeOrder: id,
    });
  };

  showClientModal = (id) => {
    this.setState({
      showClientModal: true,
      activeClientID: id,
    });
  };

  close = () => {
    this.setState({
      showModal: false,
      showClientModal: false,
      activeOrder: 0,
    });
  };

  chgDate = (dates) => {
    this.setState({
      fromTime: dates[0],
      tillTime: dates[1],
    });
  };

  clearDate = () => {
    this.setState({
      fromTime: false,
      tillTime: false,
    });
    this.props.getDriverOrdersData(this.props.driverId);
  };

  filterDate = () => {
    this.props.getDriverOrdersData(
      this.props.driverId,
      moment.utc(this.state.fromTime),
      moment.utc(this.state.tillTime)
    );
  };

  render() {
    const fromTime = this.state.fromTime
      ? moment(this.state.fromTime, "YYYY-MM-DD")
      : null;
    const tillTime = this.state.tillTime
      ? moment(this.state.tillTime, "YYYY-MM-DD")
      : null;

    return (
      <div>
        <div className="row">
          <div
            className="col-12"
            style={{ paddingTop: "10px", paddingBottom: "20px" }}
          >
            <RangePicker
              placeholder={[t('create_date_from'), t('create_date_to')]}
              value={[fromTime, tillTime]}
              onChange={this.chgDate}
              disabledDate={(current) => {
                return current && current > moment().add(1, "day");
              }}
              allowClear={false}
            />
            <button
              className="btn btn-warning"
              onClick={this.filterDate}
              style={{ margin: "10px" }}
            >
              {t('apply')}
            </button>
            <button className="btn btn-info" onClick={this.clearDate}>
              {t('clear')}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={this.props.ordersList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <OrderInfo activeOrder={this.state.activeOrder} />
          </ModalSimple>
        ) : null}
        {this.state.showClientModal ? (
          <ModalSimple close={this.close}>
            <PassengersInfo activeClientID={this.state.activeClientID} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getDriverOrdersData: stores.OrdersStore.getDriverOrdersData,
  ordersList: stores.OrdersStore.ordersList,
  getOrdersStatus: stores.OrdersStore.getOrdersStatus,
  getPaymentStatus: stores.OrdersStore.getPaymentStatus,
  getPaymentTypes: stores.OrdersStore.getPaymentTypes,
  getOrderClasses: stores.OrdersStore.getOrderClasses,
  getOrderPreferences: stores.OrdersStore.getOrderPreferences,
}));

export default withData(DriverHistory);
