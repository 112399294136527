import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { MdDeleteForever } from "react-icons/md";
import ModalConfirmDelete from "../../ModalConfirmDelete";

import { Table } from "antd";
import AddFee from "./addFee";
import EditFee from "./editFee";
import { t } from "i18next";

const FeesReasons = observer(({ getFeesData, feesList, deleteFee }) => {
  const [showNew, showAddNewFee] = useState(true);
  const [editId, setEditId] = useState(0);
  const [amount, setAmount] = useState(0);
  const [fineReason, setFineReason] = useState("");
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false); // State for modal

  const showNewForm = () => {
    showAddNewFee(true);
    setEditId(0);
    setAmount(0);
    setFineReason("");
  };

  const handleDeleteClick = (record) => {
    setIsModalConfirmDelete(true); // Show the delete confirmation modal
    setEditId(record.id); // Set the id in the state to delete later
  };

  useEffect(() => {
    getFeesData();
  }, [getFeesData]);

  const tableColums = [
    {
      title: t('reason'),
      dataIndex: "description",
    },
    {
      title: t('fine'),
      dataIndex: "defaultFee",
    },
    {
      title: t('actions'),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <MdDeleteForever onClick={() => handleDeleteClick(record)} />
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-12">{t('fines')}</div>
      </div>
      {showNew ? (
        <AddFee />
      ) : (
        <EditFee
          id={editId}
          showAddNewFee={showNewForm}
          amount={amount}
          fineReason={fineReason}
        />
      )}
      <div className="row" style={{ paddingBottom: "20px" }}></div>
      <div>
        <Table
          columns={tableColums}
          dataSource={feesList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              setEditId(record.id);
              setAmount(record.defaultFee);
              setFineReason(record.description);
              showAddNewFee(false);
            },
          })}
        />
      </div>
      <ModalConfirmDelete
        onYes={() => {
          deleteFee(editId); // Delete using the stored id
          setIsModalConfirmDelete(false); // Close the modal
        }}
        onClose={() => setIsModalConfirmDelete(false)}
        isOpen={isModalConfirmDelete}
        text={t('confirm_delete')}
      />
    </>
  );
});

const withData = inject((stores) => ({
  feesList: stores.FeesReasonsStore.feesList,
  isError: stores.FeesReasonsStore.isError,
  getFeesData: stores.FeesReasonsStore.getFeesData,
  deleteFee: stores.FeesReasonsStore.deleteFee,
}));

export default withData(FeesReasons);
