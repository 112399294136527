import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { t } from 'i18next'

@observer
class FeedbackInfo extends Component {
  state = {
    responseMsg: '',
  }

  inpChg = evt => {
    this.setState({
      [evt.target.name]: evt.target.value,
    })
  }

  sendFeedback = () =>{
    this.props.sendFeedbackMsg(this.props.who, this.props.feedbackID, this.state.responseMsg)
    this.setState({responseMsg: ''})
    this.props.getFeedbackInfo(this.props.feedbackID, this.props.who)
  }

  intervalLink = null

  componentDidMount(){
    this.props.markedAsSee(this.props.who)
    this.intervalLink = setInterval( () => {
      this.props.getFeedbackInfo(this.props.feedbackID, this.props.who)
    }, 5000)
  }

  componentWillUnmount(){
    clearInterval(this.intervalLink)
  }

  render() {
    const messages = this.props.activeFeedback ? this.props.activeFeedback : []

    const messagesList = messages.map( msg => {
      const blockClass = msg.userName && !msg.adminUserName ? 'col-12 feedbackMsg alert alert-secondary' : 'col-12 feedbackMsg alert alert-info'

      const stillUtc = moment.utc(msg.createdAt).toDate();
      const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');

      // const stillUtcreadByUserAt = moment.utc(msg.readByUserAt).toDate();
      // const localreadByUserAt = moment(stillUtcreadByUserAt).local().format('YYYY-MM-DD HH:mm:ss');

      return (<div className={blockClass}>
        <span>{msg.userName && !msg.adminUserName ? <>{msg.userName}</> : <>{msg.adminUserName}</>}</span>
        {msg.topic.length > 0 && !msg.adminUserName ? <p>{msg.topic}</p> : <></> }
        <p>{msg.content}</p>
        <span>{t('sent')}: {local}</span>
        {/* <span>Прочитано: {localreadByUserAt}</span> */}
      </div>)
    })

    return (
      <div className="col-12">
        <div className="row form-row">
          {messagesList}
        </div>
        <div className="row form-row">
          <div className="col-12" id="scroll-chat" style={{marginBottom: '20px'}}>
            <textarea name="responseMsg" value={this.state.responseMsg} className="form-control" onChange={this.inpChg} />
          </div>
          <div className="col-12 text-center" style={{marginBottom: '20px'}}>
            <button className="btn btn-success" onClick={this.sendFeedback}>{t('send_message')}</button>
          </div>
        </div>
      </div>
    )
  }
}

const withData = inject(stores => ({
  activeFeedback: stores.FeedbackStore.activeFeedback,
  getFeedbackInfo: stores.FeedbackStore.getFeedbackInfo,
  sendFeedbackMsg: stores.FeedbackStore.sendFeedbackMsg,
  markedAsSee: stores.FeedbackStore.markedAsSee,
  adminID: stores.LoginFromStore.id,
}))

export default withData(FeedbackInfo)