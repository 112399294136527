import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import { t } from "i18next";

const FineModal = observer(
  ({
    setFine,
    driverId,
    setFineStatus,
    setFineError,
    getFeesData,
    feesList,
  }) => {
    const [fineId, setFineId] = useState(0);

    useEffect(() => {
      getFeesData();
    }, [getFeesData]);

    const fineOptions = feesList.map((item, id) => (
      <option key={item.id} value={item.id}>
        {item.description} - {item.defaultFee} грн
      </option>
    ));

    const setDefaultFine = () => {
      const choosedFine = feesList.filter((item) => item.id === fineId);
      setFine(driverId, choosedFine[0].defaultFee, choosedFine[0].description);
    };

    return (
      <>
        <div className="col-12">
          <select
            className="form-control"
            onChange={(e) => setFineId(e.target.value)}
          >
            <option key="0" value="">
              - {t('reason')} -
            </option>
            {fineOptions}
            <option key="other" value="other">
            {t('other')}...
            </option>
          </select>
        </div>
        {fineId !== "other" ? (
          <div
            className="col-12"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <button
              type="submit"
              disabled={fineId ? false : true}
              className="btn btn-primary btn-block"
              onClick={setDefaultFine}
            >
              {t('impose_fine')}
            </button>
          </div>
        ) : null}
        {fineId === "other" ? (
          <Formik
            initialValues={{ amount: "", fineReason: "" }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={(values) => {
              const errors = {};

              if (!values.amount) {
                errors.amount = t('required_field');
              } else if (!Number.isInteger(values.amount)) {
                errors.amount = t('incorrect_penalty_value');
              }

              if (!values.fineReason) {
                errors.fineReason = t('required_field');
              } else if (values.fineReason.length < 5) {
                errors.fineReason = t('reason_for_the_penalty');
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setFine(driverId, values.amount, values.fineReason);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="col-12">
                  <div className="row">
                    {setFineError ? (
                      <div
                        className="col-12"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <div className="alert alert-danger" role="alert">
                          {setFineError}
                        </div>
                      </div>
                    ) : null}
                    {setFineStatus ? (
                      <div
                        className="col-12"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <div className="alert alert-success" role="alert">
                          {setFineStatus}
                        </div>
                      </div>
                    ) : null}
                    <div className="col-12">
                      <b>{t('amount_for_the_penalty')}:</b>
                    </div>
                    <div className="col-12">
                      <input
                        type="number"
                        name="amount"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.amount}
                      />
                    </div>
                    {errors.amount && touched.amount && errors.amount ? (
                      <div className="col-12" style={{ marginTop: "20px" }}>
                        <div className="alert alert-danger" role="alert">
                          {errors.amount && touched.amount && errors.amount}
                        </div>
                      </div>
                    ) : null}

                    <div className="col-12" style={{ marginTop: "20px" }}>
                      <b>{t('reason_for_the_penalty')}:</b>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        name="fineReason"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fineReason}
                      />
                    </div>
                    {errors.fineReason &&
                    touched.fineReason &&
                    errors.fineReason ? (
                      <div className="col-12" style={{ marginTop: "20px" }}>
                        <div className="alert alert-danger" role="alert">
                          {errors.fineReason &&
                            touched.fineReason &&
                            errors.fineReason}
                        </div>
                      </div>
                    ) : null}

                    <div
                      className="col-12"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary btn-block"
                      >
                        {t('impose_fine')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        ) : null}
      </>
    );
  }
);

const withData = inject((store) => ({
  setFine: store.DriversStore.setFine,
  setFineStatus: store.DriversStore.setFineStatus,
  setFineError: store.DriversStore.setFineError,
  feesList: store.FeesReasonsStore.feesList,
  getFeesData: store.FeesReasonsStore.getFeesData,
}));

export default withData(FineModal);
