import { observable, action } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../config";
import { takePageElements } from "shared/constants";
import { axiosInstanceWithToken } from "shared/api/axiosInstance";

class DriverStore {
  @observable driversList = [];
  @observable driverAmount = 0;
  @observable driversBlackList = [];
  @observable activeDriver = {};
  @observable ratingList = [];
  @observable isUpdError = null;
  @observable updErrMsg = "";
  @observable setFineStatus = null;
  @observable setFineError = null;

  setFine = async (driverId, amount, reason) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const result = await axios.post(
        `${apiIdentity}v2/drivers/${driverId}/fines`,
        {
          amount,
          reason,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (result.status === 204) {
        this.setFineStatus = "Штраф наложен";

        this.getDriver(driverId);
      } else {
        this.setFineError = "При наложении штрафа возникла ошибка";
      }
      console.log("result fine", result);
      setTimeout(() => {
        this.setFineStatus = null;
        this.setFineError = null;
      }, 1500);
    } catch (e) {
      console.log(e);
    }
  };

  getDriversData = (filters) => {
    axiosInstanceWithToken.post(`${apiIdentity}v2/drivers/search`, filters)
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.driversList = [...response.data.data];
          this.driverAmount = response.data.total;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDriverRating = (id, fromDate, toDate) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}v2/drivers/rating`,
        { id, fromDate, toDate, skip: 0, take: takePageElements },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.ratingList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDriversBlackListData = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}v2/drivers/blacklist/?skip=0&take=${takePageElements}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.driversBlackList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  block = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}v2/drivers/${id}/blacklist`,
        { id, reason: "Test" },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getDriversData({ skip: 0, take: takePageElements });
          this.getDriversBlackListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  restore = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}v2/drivers/${id}/blacklist/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getDriversData({ skip: 0, take: takePageElements });
          this.getDriversBlackListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addDriver = (driverData) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}v2/drivers`, driverData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getDriversData({ skip: 0, take: takePageElements });
          this.getDriversBlackListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  @action
  getDriver = (id) => {
    this.isUpdError = null;
    this.updErrMsg = "";
    const accessToken = localStorage.getItem("accessToken");
    return axios
      .get(`${apiIdentity}v2/drivers/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {

        if (response.data.success === false) {
          this.isError = true;
          this.activeDriver = {};
        } else {
          this.activeDriver = { ...response.data.data };
        }
        return response
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateDriver = (id, drive) => {
    this.isUpdError = null;
    this.updErrMsg = "";

    const accessToken = localStorage.getItem("accessToken");

    return axios.put(
      `${apiIdentity}v2/drivers/${id}`,
      drive,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          return false;
        } else {
          this.getDriversData({ skip: 0, take: takePageElements });
          this.getDriversBlackListData();
          return true;
        }
      })
      .catch((err) => {
        this.isUpdError = true;
        if (err.response.data.Data === "driver_call_sign_already_exists") {
          this.updErrMsg = "Такой позывной уже занят";
        }
        console.log(err.response);
        // Пробрасываем ошибку в вызывающий код
        throw err;
      });
  };

  addBonus = (bonusCount, id) => {
    const accessToken = localStorage.getItem("accessToken");
    const userIds = [id];
    const bonusAmount = Number(bonusCount);
    return axios.post(
        `${apiIdentity}drivers/promotion`,
        { userIds, bonusAmount },
        { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  chgDriverInfo = (evt) => {
    this.activeDriver[evt.target.name] = evt.target.value;
  };

  delDriver = (driverId) => {
    const accessToken = localStorage.getItem("accessToken");
    return axios.delete(`${apiIdentity}v2/drivers/${driverId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };

  activateDriver = (driverId) => {
    const accessToken = localStorage.getItem("accessToken");
    return axios
      .post(
        `${apiIdentity}v2/drivers/${driverId}/activate`,
        { id: driverId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        this.getDriver(driverId);
        return response
      })
      .catch((err) => {
        console.log(err);
      });
  };

  blockDriver = (driverId) => {
    const accessToken = localStorage.getItem("accessToken");
    return axios
      .post(
        `${apiIdentity}v2/drivers/${driverId}/block`,
        { id: driverId, reason: "Заблокирован администратором" },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        this.getDriver(driverId);
        return response
      })
      .catch((err) => {
        console.log(err);
      });
  };

  unblockDriver = (driverId) => {
    const accessToken = localStorage.getItem("accessToken");
    return axios
      .post(
        `${apiIdentity}v2/drivers/${driverId}/unblock`,
        { id: driverId, reason: "Разблокирован администратором" },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        this.getDriver(driverId);
        return response
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new DriverStore();
