import { observable } from "mobx"
import axios from "axios"

import { apiIdentity } from "../../config"

class FAQStore {
  @observable faqList = []
  @observable activeFAQ = {}

  getFAQSData = () => {
    const accessToken = localStorage.getItem("accessToken")
    axios
      .get(`${apiIdentity}faq`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data)
        if (response.data.success === false) {
          this.isError = true
        } else {
          this.faqList = [...response.data.data]
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getFAQInfo = (id) => {
    const accessToken = localStorage.getItem("accessToken")
    axios
      .get(`${apiIdentity}faq/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data)
        if (response.data.success === false) {
          this.isError = true
          this.activeFAQ = {}
        } else {
          this.activeFAQ = { ...response.data.data }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  chgFAQInfo = (evt) => {
    const faqInfo = { ...this.activeFAQ }
    if (evt.target.name === "target") {
      faqInfo[evt.target.name] = Number(evt.target.value)
    } else {
      faqInfo[evt.target.name] = evt.target.value
    }
    this.activeFAQ = faqInfo
  }

  updFAQInfo = () => {
    const accessToken = localStorage.getItem("accessToken")
    const data = { ...this.activeFAQ }
    let targetVal = "All"
    if (data.target === 0) {
      targetVal = "Driver"
    } else if (data.target === 1) {
      targetVal = "Client"
    }
    data.target = targetVal
    axios
      .put(`${apiIdentity}faq/${this.activeFAQ.id}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data)
        if (response.data.success === false) {
          this.isError = true
        } else {
          this.getFAQSData()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  addFAQ = (title, body, target) => {
    const accessToken = localStorage.getItem("accessToken")
    axios
      .post(
        `${apiIdentity}faq`,
        { title, content: body, target },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data)
        if (response.data.success === false) {
          this.isError = true
        } else {
          this.getFAQSData()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  delFAQ = (id) => {
    const accessToken = localStorage.getItem("accessToken")
    axios
      .delete(`${apiIdentity}faq/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data)
        if (response.data.success === false) {
          this.isError = true
        } else {
          this.getFAQSData()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export default new FAQStore()
