// export const api = 'https://identity.taxidev.pro/docs/index.html'
//https://api.taxi.quadrobit.com/admin/
// process.env.REACT_APP_DEV ? "https://api.taxi.quadrobit.com/admin/" : "https://api.l-t.taxi/admin/"
export const apiIdentity = process.env.REACT_APP_BACKEND_URL + "/admin/";

//https://api.taxi.quadrobit.com/admin/
// process.env.REACT_APP_DEV ? "https://api.taxi.quadrobit.com/identity/" : "https://api.l-t.taxi/identity/"
export const apiRefresh = process.env.REACT_APP_BACKEND_URL + "/identity/";

export const paymentGate = process.env.REACT_APP_PAYMENT_GATE + "/";

export const accessLogins = process.env.REACT_APP_ACCESS_LOGINS;

export const accessChangeBalanceLogins = process.env.REACT_APP_ACCESS_CHANGE_BALANCE;