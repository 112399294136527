import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'

@observer
class Settings extends Component {
 
  componentDidMount(){
    this.props.getSettings()
  }

  render() {
    return (
      <>
        <div className="row" style={{paddingBottom: "15px"}}>
          <div className="col-4">
            {t('clients_bonus_coefficient')}
          </div>
          <div className="col-8">
            <input type="text" value={this.props.clientBonusCoefficient} onChange={this.props.chgSettings} name="clientBonusCoefficient" className="form-control"/>
          </div>
        </div>
        <div className="row" style={{paddingBottom: "15px"}}>
          <div className="col-4">
            {t('drivers_bonus_coefficient')}
          </div>
          <div className="col-8">
            <input type="text" value={this.props.driverBonusCoefficient} onChange={this.props.chgSettings} name="driverBonusCoefficient" className="form-control"/>
          </div>
        </div>
        <div className="row" style={{paddingBottom: "15px"}}>
          <div className="col-12">
            <button className="btn btn-warning" onClick={this.props.updSettings}>{t('update_settings')}</button>
          </div>
        </div>
      </>
    )
  }
}

const withData = inject(stores => ({
  clientBonusCoefficient: stores.SettingsStore.clientBonusCoefficient,
  driverBonusCoefficient: stores.SettingsStore.driverBonusCoefficient,
  getSettings: stores.SettingsStore.getSettings,
  updSettings: stores.SettingsStore.updSettings,
  chgSettings: stores.SettingsStore.chgSettings,
}))

export default withData(Settings)
