import React, { Component } from 'react'
import NavMenu from '../../components/NavMenu'
import GateHistory from '../../components/GateHistory'
import { t } from 'i18next'

export default class GateHistoryPage extends Component {
    render() {
        return (<div className="row">
            <div className="col-1 nav-menu">
                <NavMenu />
            </div>
            <div className="col-11">
                <div className="row">
                    <div className="col">
                        <h3>{t('list_of_transactions')}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <GateHistory />
                    </div>
                </div>
            </div>
        </div>)
    }
}
