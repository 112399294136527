import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ModalConfirmDelete from "../ModalConfirmDelete";
import CarInfoTO from "./CarInfoTO";
import { t } from "i18next";
import { takePageElements } from 'shared/constants'

import "./CarInfo.scss";
import { checkCarNumber, checkYear, CAR_PRODUCE_MINIMUM_YEAR } from "./carsConfig";
@observer
class CarInfo extends Component {
  state = {
    activeTab: 0,
    isModalConfirmDelete: false,
    error: false,
  };

  componentDidMount() {
    this.props.getCar(this.props.carID);
    this.props.getDriversData({ skip: 0, take: takePageElements });
    this.props.getCarBrandsData();
    this.props.getColorData();
    this.props.getCarClassData();
  }

  chgTab = (id) => {
    this.setState({
      activeTab: id,
    });
  };

  delCar = () => {
    this.props.delCar();
    this.props.close();
  };


  updateCurrentCar = () => {
    if (
      this.props.activeCar.brandId.length > 0 &&
      this.props.activeCar.modelId.length > 0 &&
      this.props.activeCar.classId.length > 0 &&
      this.props.activeCar.colorId.length > 0 &&
      this.props.activeCar.driverId.length > 0 &&
      checkCarNumber.test(this.props.activeCar.govNumber) &&
      (checkYear.test(this.props.activeCar.manufactureYear) && 
      this.props.activeCar.manufactureYear >= CAR_PRODUCE_MINIMUM_YEAR &&
      this.props.activeCar.manufactureYear <= new Date().getFullYear()
      )
    ) {
      this.props.updateCar();
      this.setState({
        error: false,
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  handleBrandChange = (e) => {
    this.props.chgCarInfo(e);
    this.props.activeCar.modelId = "";
  }
  render() {
    const {
      driverId,
      brandId,
      modelId,
      classId,
      colorId,
      govNumber,
      manufactureYear,
    } = this.props.activeCar;

    const colors = this.props.colorList.map((color) => {
      return (
        <option value={color.id} key={color.id}>
          {color.name}
        </option>
      );
    });

    const carClass = this.props.classList.map((carClass) => {
      return (
        <option value={carClass.id} key={carClass.id}>
          {carClass.name}
        </option>
      );
    });

    const carBrand = this.props.carBrands.map((carBrand) => {
      return (
        <option value={carBrand.id} key={carBrand.id}>
          {carBrand.name}
        </option>
      );
    });

    let carMarks = null;
    if (brandId && brandId.length > 0) {
      const currentBrand = this.props.carBrands.filter((carBrand) => {
        if (carBrand.id === brandId) {
          return true;
        }
        return false;
      });
      console.log("======", currentBrand);
      carMarks =
        currentBrand.length > 0 && currentBrand[0].models
          ? currentBrand[0].models.map((model) => {
              return (
                <option value={model.id} key={model.id}>
                  {model.name}
                </option>
              );
            })
          : null;
    }

    const drivers = this.props.driversList.map((driver) => {
      return (
        <option value={driver.id} key={driver.id}>
          {driver.firstName} {driver.lastName}
        </option>
      );
    });

    return (
      <div className="col-12">
        <div className="row">
          <ul className="tabs-menu">
            <li
              className={this.state.activeTab === 0 ? "active" : null}
              onClick={() => this.chgTab(0)}
            >
              {t('information')}
            </li>
            <li
              className={this.state.activeTab === 1 ? "active" : null}
              onClick={() => this.chgTab(1)}
            >
              {t('list_of_inspections')}
            </li>
          </ul>
        </div>
        {this.state.activeTab === 1 ? (
          <CarInfoTO />
        ) : (
          <>
            <div className="row form-row">
              <div className="col-12">{t('driver')}</div>
              <div className="col-12">
                <select
                  name="driverId"
                  value={driverId}
                  className="form-control"
                  onChange={this.props.chgCarInfo}
                >
                  <option value=""> </option>
                  {drivers}
                </select>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('car_mark')}</div>
              <div className="col-12">
                <select
                  name="brandId"
                  value={brandId}
                  className="form-control"
                  onChange={this.handleBrandChange}
                >
                  <option value=""> </option>
                  {carBrand}
                </select>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('car_model')}</div>
              <div className="col-12">
                <select
                  name="modelId"
                  value={modelId}
                  className="form-control"
                  onChange={this.props.chgCarInfo}
                >
                  <option value=""> </option>
                  {carMarks}
                </select>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('car_class')}</div>
              <div className="col-12">
                <select
                  name="classId"
                  value={classId}
                  className="form-control"
                  onChange={this.props.chgCarInfo}
                >
                  <option value=""> </option>
                  {carClass}
                </select>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('color')}</div>
              <div className="col-12">
                <select
                  name="colorId"
                  value={colorId}
                  className="form-control"
                  onChange={this.props.chgCarInfo}
                >
                  <option value=""> </option>
                  {colors}
                </select>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('number_plate')}</div>
              <div className="col-12">
                <input
                  type="text"
                  value={govNumber}
                  name="govNumber"
                  className="form-control"
                  onChange={this.props.chgCarInfo}
                  minLength={3}
                  maxLength={12}
                />
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('year_of_manufacture')}</div>
              <div className="col-12">
                <input
                  type="text"
                  value={manufactureYear}
                  name="manufactureYear"
                  className="form-control"
                  onChange={this.props.chgCarInfo}
                  maxLength={4}
                />
              </div>
            </div>
            {this.state.error ? (
              <div className="row form-row">
                <div className="col-12 alert alert-danger">
                  Ошибка! Проверьте правильность введенных данных.
                </div>
              </div>
            ) : null}
            <div className="row form-row">
              <div className="col-12">
                <button
                  className="btn btn-danger"
                  onClick={() => {this.setState({isModalConfirmDelete : true})}}
                  style={{ marginRight: "10px" }}
                >
                  {t('delete_car')}
                </button>
                <button
                  className="btn btn-success"
                  onClick={this.updateCurrentCar}
                >
                  {t('update_car_data')}
                </button>
              </div>
            </div>
          </>
        )}
        <ModalConfirmDelete onYes={this.delCar} onClose={() => this.setState({isModalConfirmDelete : false})} isOpen={this.state.isModalConfirmDelete} text={t('confirm_delete')} />
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getCar: stores.CarsStore.getCar,
  updateCar: stores.CarsStore.updateCar,
  activeCar: stores.CarsStore.activeCar,
  chgCarInfo: stores.CarsStore.chgCarInfo,
  getCarBrandsData: stores.CarsMarkStore.getCarBrandsData,
  carBrands: stores.CarsMarkStore.carBrands,
  getColorData: stores.CarsColorStore.getColorData,
  colorList: stores.CarsColorStore.colorList,
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
  driversList: stores.DriversStore.driversList,
  getDriversData: stores.DriversStore.getDriversData,
  delCar: stores.CarsStore.delCar,
}));

export default withData(CarInfo);
