import React, { useState } from "react";
import { t } from "i18next";
import { Select } from "../../../CustomSelect";
import PhotoModal from "../../../PhotoModal";

let i = 0;
export const CarInfo = ({ car, classes, colors, brandsWithModels, photos }) => {
  const [carClass, setCarClass] = useState(car.carClass);
  const [color, setColor] = useState(car.color);
  const [brend, setBrend] = useState(car.brend);
  const [models, setModels] = useState(car.brend.models);
  const [model, setModel] = useState(car.model);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  const changeBrend = (newBrend) => {
    if (newBrend.id === brend.id) return;
    car.brandId = newBrend.id;
    car.model = newBrend.models[0];
    car.brend = newBrend;
    car.changes = true;
    setModels(newBrend.models);
    setModel(newBrend.models[0]);
    setBrend(newBrend);
  };

  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const select = [
    {
      data: brend,
      datas: brandsWithModels.items,
      change: changeBrend,
      text: "car_mark",
    },
    { data: model, datas: models, change: setModel, text: "car_model" },
    { data: carClass, datas: classes, change: setCarClass, text: "car_class" },
  ];
  return (
    <div>
      {car.govNumber && <p className="drivers_data_title">{t("car")}</p>}
      {car.govNumber && (
        <div className="drivers_info_block pointer-events-block">
          <div className="drivers_info_grounds">
            <div className="drivers_info_ground">
              {select.map((element, index) => (
                <Select {...element} key={`${element.text}_${index}`} />
              ))}
            </div>
            <div className="drivers_info_ground">
              <Select
                data={color}
                datas={colors}
                change={setColor}
                text={"color"}
              />
              <div className="driver_input">
                <p>
                  <span>*</span> {t("year_of_manufacture")}
                </p>
                <input value={car.manufactureYear} />
              </div>
              <div className="driver_input">
                <p>
                  <span>*</span> {t("number_plate")}
                </p>
                <input value={car.govNumber} />
              </div>
            </div>
          </div>
        </div>
      )}
      {photos && photos.length !== 0 && (
        <p className="drivers_data_title block_with_padding_bottom">
          {t("car_photo")}
        </p>
      )}
      {photos && photos.length !== 0 && (
        <div className="driver_car_block">
          {photos.map((photo, index) => (
            <div
              className="driver_car_single_item"
              key={index}
              onClick={() => handlePhotoClick(index)}
            >
              <img src={photo.file} alt="" />
            </div>
          ))}
        </div>
      )}
      {isModalOpen && (
        <PhotoModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          photos={photos}
        />
      )}
    </div>
  );
};
