import { observable} from 'mobx'
import axios from 'axios'
import { createNotification } from "../Notifications";

import { apiIdentity } from '../../config'

class SettingsStore {
  @observable clientBonusCoefficient = "";
  @observable driverBonusCoefficient = "";
  @observable id = "";

  getSettings = () => {
    const accessToken = localStorage.getItem('accessToken')
    axios.get(`${apiIdentity}configuration`, { headers: { "Authorization": `Bearer ${accessToken}` } })
    .then( response => {
      if(response.data.success === false){
        createNotification(
          "error",
          "Ошибка",
          "Возникла ошибка при запросе к серверу",
          3000
        );
      } else {         
        this.clientBonusCoefficient = response.data.data.clientBonusCoefficient
        this.driverBonusCoefficient = response.data.data.driverBonusCoefficient
        this.id = response.data.data.id
      }
    })
    .catch( err => {
      console.log(err)
      createNotification(
        "error",
        "Ошибка",
        "Возникла ошибка при запросе к серверу",
        3000
      );
      this.isError = true
    })
  }

  updSettings = () => {
    const accessToken = localStorage.getItem('accessToken')
    axios.post(`${apiIdentity}configuration`, {id: this.id, clientBonusCoefficient: this.clientBonusCoefficient, driverBonusCoefficient: this.driverBonusCoefficient}, { headers: { "Authorization": `Bearer ${accessToken}` } })
    .then( response => {
      if(response.data.success === false){
        createNotification(
          "error",
          "Ошибка",
          JSON.parse(response.data),
          3000
        );
      } else {         
        this.getSettings()
        this.isError = false
      }
    })
    .catch( err => {
      console.log(err)
      createNotification(
        "error",
        "Ошибка",
        "Возникла ошибка при запросе к серверу",
        3000
      );
    })
  }

  chgSettings = evt => {
    if(evt.target.name === 'clientBonusCoefficient'){
      this.clientBonusCoefficient = evt.target.value
    }

    if(evt.target.name === 'driverBonusCoefficient'){
      this.driverBonusCoefficient = evt.target.value
    }
  }
}

export default new SettingsStore();