import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { MdDeleteForever } from "react-icons/md";
import { t } from "i18next";
import ModalConfirmDelete from "../../ModalConfirmDelete"

import { Table } from "antd";

@observer
class Fares extends Component {
  state = {
    tname: "",
    rateVal: "",
    classID: "",
    carDeliveryFee: "",
    orderPendingPerMinuteFee: "",
    editId: false,
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  };

  carColumns = [
    {
      title: t('tariff_name'),
      dataIndex: "name",
    },
    {
      title: t('class'),
      dataIndex: "className",
    },
    {
      title: t('tariff_with_parameters'),
      dataIndex: "fare",
    },
    {
      title: t('submission_fee'),
      dataIndex: "carDeliveryFee",
    },
    {
      title: t('parking_with_parameters'),
      dataIndex: "orderPendingPerMinuteFee",
    },
    {
      title: t('actions'),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <MdDeleteForever onClick={() => this.delBtn(record)} />
      ),
    },
  ];

  delBtn = (record) => {
    const recordId = record.id;
    this.setState({
      modalSettings: {
        onYes: () => this.props.delFare(recordId),
        onClose: () => this.setState({ modalSettings: { isOpen: false } }),
        isOpen: true,
        text: t('confirm_delete')
      }
    });
  };

  componentDidMount() {
    this.props.getCityData();
    this.props.getCarClassData();
  }

  chooseCity = (evt) => {
    console.log(evt.target.value);
    this.props.chooseCity(evt.target.value);
  };

  chgInput = (evt) => {
    const regExp = /^[0-9.,]*$/;
    if (
      evt.target.name == "rateVal" ||
      evt.target.name == "carDeliveryFee" ||
      evt.target.name == "orderPendingPerMinuteFee"
    ) {
      if (
        regExp.test(evt.target.value) ||
        evt.target.value[evt.target.value.length - 1] == "."
      ) {
        this.setState({
          [evt.target.name]: evt.target.value,
        });
      } else {
        this.setState({
          [evt.target.name]: this.state[evt.target.name],
        });
      }
    } else {
      this.setState({
        [evt.target.name]: evt.target.value,
      });
    }
  };

  addFare = () => {
    this.setState({
      rateVal: "",
      classID: "",
      carDeliveryFee: "",
      tname: "",
      orderPendingPerMinuteFee: "",
    });
    this.props.addFare(
      this.state.rateVal,
      this.state.classID,
      this.state.carDeliveryFee,
      this.state.tname,
      this.state.orderPendingPerMinuteFee
    );
  };

  editFare = (record) => {
    if (record) {
      this.setState({
        rateVal: record.fare,
        classID: record.classId,
        carDeliveryFee: record.carDeliveryFee,
        tname: record.name,
        orderPendingPerMinuteFee: record.orderPendingPerMinuteFee,
        editId: record.id,
      });
    } else {
      this.setState({
        rateVal: "",
        classID: "",
        carDeliveryFee: "",
        tname: "",
        orderPendingPerMinuteFee: "",
        editId: false,
      });
    }
  };

  pushEditFare = () => {
    this.setState({
      rateVal: "",
      classID: "",
      carDeliveryFee: "",
      tname: "",
      orderPendingPerMinuteFee: "",
      editId: false,
    });
    this.props.updateFare({
      value: this.state.rateVal,
      carClassId: this.state.classID,
      carDeliveryFee: this.state.carDeliveryFee,
      name: this.state.tname,
      orderPendingPerMinuteFee: this.state.orderPendingPerMinuteFee,
      id: this.state.editId,
    });
  };

  render() {
    const ratesList = this.props.faresList;
    const classList = this.props.classList;

    const tabelData = ratesList.map((rate) => {
      return {
        id: rate.id,
        name: rate.name,
        className: rate.className,
        fare: rate.fare,
        carDeliveryFee: rate.carDeliveryFee,
        orderPendingPerMinuteFee: rate.orderPendingPerMinuteFee,
        classId: rate.classId,
      };
    });

    const classWithoutFares = classList.filter((item) => {
      const classIdList = ratesList.map((classInfo) => classInfo.classId);
      return !classIdList.includes(item.id);
    });
    const classListOptions = classWithoutFares.map((item) => {
      return <option value={item.id}>{item.name}</option>;
    });

    const cityListOptions = this.props.cityList.map((item) => {
      return <option value={item.id}>{item.name}</option>;
    });

    return (
      <>
        <div className="row">
          <div className="col-12">
            {this.state.editId ? t('edit_tariff') : t('add_tariff')}
          </div>
        </div>
        <div className="row" style={{ paddingBottom: "20px" }}>
          <div className="col-12">
            <select
              value={this.props.cityid}
              name="city"
              className="form-control"
              onChange={this.chooseCity}
            >
              <option value="">-- {t('select_city')} --</option>
              {cityListOptions}
            </select>
          </div>
        </div>
        <div className="row" style={{ paddingBottom: "20px" }}>
          <div className="col-2">
            <input
              type="text"
              placeholder={t('name_simple')} 
              name="tname"
              value={this.state.tname}
              onChange={this.chgInput}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              placeholder={t('tariff')} 
              name="rateVal"
              value={this.state.rateVal}
              onChange={this.chgInput}
              className="form-control"
            />
          </div>
          {!this.state.editId && (
            <div className="col-2">
              <select
                value={this.state.classID}
                name="classID"
                className="form-control"
                onChange={this.chgInput}
              >
                <option value="">-- {t('tariff')} --</option>
                {classListOptions}
              </select>
            </div>
          )}
          <div className="col-2">
            <input
              type="text"
              placeholder={t('submission_fee')}
              name="carDeliveryFee"
              value={this.state.carDeliveryFee}
              onChange={this.chgInput}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              placeholder={t('parking')}
              name="orderPendingPerMinuteFee"
              value={this.state.orderPendingPerMinuteFee}
              onChange={this.chgInput}
              className="form-control"
            />
          </div>
          {this.state.editId ? (
            <>
              <div className="col-2">
                <button
                  onClick={() => this.pushEditFare()}
                  className="btn btn-success"
                  style={{ "margin-right": "10px" }}
                >
                  {t('change')}
                </button>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => this.editFare(false)}
                >
                  {t('cancel')}
                </button>
              </div>
            </>
          ) : (
            <div className="col-2">
              <button
                onClick={() => this.addFare()}
                className="btn btn-success"
              >
                {t('add')}
              </button>
            </div>
          )}
        </div>
        <div>
          <Table
            columns={this.carColumns}
            dataSource={tabelData}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                this.editFare(record);
              },
            })}
          />
        </div>
        <ModalConfirmDelete {...this.state.modalSettings} />
      </>
    );
  }
}

const withData = inject((stores) => ({
  faresList: stores.FaresStore.faresList,
  cityid: stores.FaresStore.cityid,
  chooseCity: stores.FaresStore.chooseCity,
  addFare: stores.FaresStore.addFare,
  updateFare: stores.FaresStore.updateFare,
  delFare: stores.FaresStore.delFare,
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
  cityList: stores.CityStore.cityList,
  getCityData: stores.CityStore.getCityData,
}));

export default withData(Fares);
