import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { t } from "i18next";
import { checkCarNumber, checkYear, CAR_PRODUCE_MINIMUM_YEAR } from "./carsConfig";
import { takePageElements } from 'shared/constants'

@observer
class AddCar extends Component {
  state = {
    brandId: "",
    modelId: "",
    classId: "",
    colorId: "",
    driverId: "",
    govNumber: "",
    manufactureYear: "",
    error: false,
  };

  chgInp = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  createNewCar = () => {
    const carData = { ...this.state };
    if (
      this.state.brandId.length > 0 &&
      this.state.modelId.length > 0 &&
      this.state.classId.length > 0 &&
      this.state.colorId.length > 0 &&
      this.state.driverId.length > 0 &&
      checkCarNumber.test(this.state.govNumber) &&
      (checkYear.test(this.state.manufactureYear) && 
      this.state.manufactureYear >= CAR_PRODUCE_MINIMUM_YEAR &&
      this.state.manufactureYear <= new Date().getFullYear()
      )
    ) {
      this.props.addNewCar(carData);
      this.setState({
        error: false,
      });
      this.props.close();
    } else {
      this.setState({
        error: true,
      });
    }
  };

  componentDidMount() {
    this.props.getDriversData({ skip: 0, take: takePageElements });
    this.props.getCarBrandsData();
    this.props.getColorData();
    this.props.getCarClassData();
  }

  render() {
    const colors = this.props.colorList.map((color) => {
      return (
        <option value={color.id} key={color.id}>
          {color.name}
        </option>
      );
    });

    const carClass = this.props.classList.map((carClass) => {
      return (
        <option value={carClass.id} key={carClass.id}>
          {carClass.name}
        </option>
      );
    });

    const carBrand = this.props.carBrands.map((carBrand) => {
      return (
        <option value={carBrand.id} key={carBrand.id}>
          {carBrand.name}
        </option>
      );
    });

    let carMarks = null;
    if (this.state.brandId.length > 0) {
      const currentBrand = this.props.carBrands.filter((carBrand) => {
        if (carBrand.id === this.state.brandId) {
          return true;
        }
        return false;
      });
      carMarks = currentBrand[0].models.map((model) => {
        return (
          <option value={model.id} key={model.id}>
            {model.name}
          </option>
        );
      });
    }

    const drivers = this.props.driversList.map((driver) => {
      return (
        <option value={driver.id} key={driver.id}>
          {driver.firstName} {driver.lastName}
        </option>
      );
    });

    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-12">{t('driver')}</div>
          <div className="col-12">
            <select
              name="driverId"
              value={this.state.driverId}
              className="form-control"
              onChange={this.chgInp}
            >
              <option value=""> </option>
              {drivers}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('car_brand')}</div>
          <div className="col-12">
            <select
              name="brandId"
              value={this.state.brandId}
              className="form-control"
              onChange={this.chgInp}
            >
              <option value=""> </option>
              {carBrand}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('car_model')}</div>
          <div className="col-12">
            <select
              name="modelId"
              value={this.state.modelId}
              className="form-control"
              onChange={this.chgInp}
            >
              <option value=""> </option>
              {carMarks}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('car_class')}</div>
          <div className="col-12">
            <select
              name="classId"
              value={this.state.classId}
              className="form-control"
              onChange={this.chgInp}
            >
              <option value=""> </option>
              {carClass}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('color')}</div>
          <div className="col-12">
            <select
              name="colorId"
              value={this.state.colorId}
              className="form-control"
              onChange={this.chgInp}
            >
              <option value=""> </option>
              {colors}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('number_plate')}</div>
          <div className="col-12">
            <input
              type="text"
              value={this.state.govNumber}
              name="govNumber"
              className="form-control"
              onChange={this.chgInp}
              minLength={3}
              maxLength={12}
              required
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('year_of_manufacture')}</div>
          <div className="col-12">
            <input
              type="text"
              value={this.state.manufactureYear}
              name="manufactureYear"
              className="form-control"
              onChange={this.chgInp}
              maxLength={4}
            />
          </div>
        </div>
        {this.state.error ? (
          <div className="row form-row">
            <div className="col-12 alert alert-danger">
            {t('all_fields_must_be_filled_in')}
            </div>
          </div>
        ) : null}
        <div className="row form-row">
          <div className="col-12">
            <button className="btn btn-success" onClick={this.createNewCar}>
              {t('add_car')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  addNewCar: stores.CarsStore.addNewCar,
  getCarBrandsData: stores.CarsMarkStore.getCarBrandsData,
  carBrands: stores.CarsMarkStore.carBrands,
  getColorData: stores.CarsColorStore.getColorData,
  colorList: stores.CarsColorStore.colorList,
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
  driversList: stores.DriversStore.driversList,
  getDriversData: stores.DriversStore.getDriversData,
}));

export default withData(AddCar);
