import React, { Component } from "react";
import { t } from "i18next";

export default class EditUser extends Component {
  state = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    error: false,
    newPass: "",
    newPassRepeat: "",
    passError: false,
    passChgStatus: false,
  };

  componentDidMount() {
    this.setState({
      email: this.props.user.email,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      error: false,
    });
  }

  onChg = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  updUser = () => {
    const { email, firstName, lastName, phone } = this.state;
    if (email.length > 5 && firstName && lastName) {
      const user = {
        id: this.props.user.id,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      };
      this.props.updUser(user);
      this.props.close();
    } else {
      this.setState({
        error: true,
      });
    }
  };

  chgPwd = () => {
    if (this.state.newPass !== this.state.newPassRepeat) {
      this.setState({
        passError: t('password_mismatch'),
      });
    } else {
      this.props.chgPwd(this.props.user.id, this.state.newPass);
      this.setState({
        passError: false,
        passChgStatus: true,
      });
    }
  };

  changePhone = (e) => {
    let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    }
    e.target.value = sanitizedValue;
    this.onChg(e)
  }

  render() {
    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-4">{t('email')}</div>
          <div className="col-8">
            <input
              type="text"
              onChange={this.onChg}
              value={this.state.email}
              name="email"
              className="form-control"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('name')}</div>
          <div className="col-8">
            <input
              type="text"
              onChange={this.onChg}
              value={this.state.firstName}
              name="firstName"
              className="form-control"
              placeholder={t('name')}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('last_name')}</div>
          <div className="col-8">
            <input
              type="text"
              onChange={this.onChg}
              value={this.state.lastName}
              name="lastName"
              className="form-control"
              placeholder="Фамилия"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('mobile_number')}</div>
          <div className="col-8">
            <input
              type="text"
              onChange={this.changePhone}
              value={this.state.phone}
              maxLength={16}
              name="phone"
              className="form-control"
              placeholder={t('mobile_number')}
            />
          </div>
        </div>
        {this.state.error ? (
          <div className="row form-row">
            <div className="col-12 text-center alert alert-danger">
              {t('form_incorrectly')}
            </div>
          </div>
        ) : null}
        <div className="row form-row">
          <div className="col-12 text-center">
            <button className="btn btn-success" onClick={this.updUser}>
              {t('save')}
            </button>
          </div>
        </div>
        {this.state.passError ? (
          <div className="row form-row">
            <div className="col-12 text-center alert alert-danger">
              {this.state.passError}
            </div>
          </div>
        ) : null}
        {this.state.passChgStatus ? (
          <div className="row form-row">
            <div className="col-12 text-center alert alert-success">
              {t('password_changed_successfully')}
            </div>
          </div>
        ) : null}
        <div className="row form-row">
          <div className="col-4">{t('new_password')}</div>
          <div className="col-8">
            <input
              type="password"
              onChange={this.onChg}
              value={this.state.newPass}
              name="newPass"
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('repeat')}</div>
          <div className="col-8">
            <input
              type="password"
              onChange={this.onChg}
              value={this.state.newPassRepeat}
              name="newPassRepeat"
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12 text-center">
            <button className="btn btn-success" onClick={this.chgPwd}>
              {t('change_password')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
