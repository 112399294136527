export default {
    driver: {
        keys: [
            'id',
            'createdAt',
            'firstName',
            'lastName',
            'isActivated',
            'phone',
            'callSign',
            'country',
            'city',
            'currency',
            'language',
            'balance',
            'commission',
            'rating'
        ],
        showKeys: {
            'id': false,
            'createdAt': true,
            'firstName': true,
            'lastName': true,
            'isActivated': true,
            'phone': true,
            'callSign': true,
            'country': true,
            'city': true,
            'currency': false,
            'language': false,
            'balance': false,
            'commission': true,
            'rating': false
        },
    },
    inspection: {
        keys: [
            'id',
            'driverId',
            'createdAt',
            'brand',
            'model',
            'status',
            'class',
            'driverName',
            'callSign',
            'country',
            'currency',
            'language'
        ],
        showKeys: {
            'id': false,
            'driverId': false,
            'createdAt': true,
            'brand': true,
            'model': true,
            'status': true,
            'class': true,
            'driverName': true,
            'callSign': true,
            'country': true,
            'currency': true,
            'language': false
        },
    }
}