import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { MdDeleteForever } from 'react-icons/md'

import { Table } from 'antd';
import { t } from "i18next";


@observer
class OrderSource extends Component {
  state = {
    cityName: '',
    updOrderSourceID: null,
    updOrderSource: '',
  }

  carColumns = [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('order_source'),
      dataIndex: 'name',
    },
    // {
    //   title: 'Действия',
    //   key: 'operation',
    //   fixed: 'right',
    //   width: 100,
    //   render: (text, record) => <MdDeleteForever onClick={() => this.delBtn(record)}/>,
    // },
  ];

  
  componentDidMount(){
    this.props.getOrderSourceData()
  }
  
  delBtn = record => {
    const recordId = record.id
    this.props.delOrderSource(recordId)
  }

  chgInput = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  editSource = city => {
    this.setState({
      updOrderSourceID: city.id,
      updOrderSource: city.name,
    })
  }

  updSourceName = (id, cityname) => {
    this.setState({
      updOrderSourceID: null,
      updOrderSource: '',
    })
    this.props.updSourceName(id, cityname)
  }

  cancelEdit = () => {
    this.setState({
      updOrderSourceID: null,
      updOrderSource: '',
    })
  }

  render() {
    const orderSourceList = this.props.orderSourceList

    return (<>
      <div className="row">
        <div className="col-12">
        {this.state.updOrderSourceID ? <>{t('edit_order_source')}</> : <>{t('add_order_source')}</>}          
        </div>
      </div>
      {
        this.state.updOrderSourceID ?
        <div className="row" style={{paddingBottom: "20px"}}>
          <div className="col-4">
            <input type="text" name="updOrderSource" value={this.state.updOrderSource} onChange={this.chgInput} className="form-control" />
          </div>
          <div className="col-4">
            <button onClick={() => this.updSourceName(this.state.updOrderSourceID, this.state.updOrderSource)} className="btn btn-success" style={{marginRight: "20px"}}>{t('edit')}</button>
            <button onClick={this.cancelEdit} className="btn btn-warning">{t('cancel')}</button>
          </div>
        </div>
        :
        <div className="row" style={{paddingBottom: "20px"}}>
          <div className="col-4">
            <input type="text" name="cityName" value={this.state.cityName} onChange={this.chgInput} className="form-control" />
          </div>
          <div className="col-4">
            <button onClick={() => this.props.addOrderSource(this.state.cityName)} className="btn btn-success">{t('add')}</button>
          </div>
        </div>
      }
      <div>
        {/* <Table columns={this.carColumns} dataSource={orderSourceList} onRow={ (record, rowIndex) => ({
          onDoubleClick: () => {
            this.editSource(record)
          }
        })} /> */}
        <Table columns={this.carColumns} dataSource={orderSourceList} />
      </div></>
    )
  }
}

const withData = inject(stores => ({
  orderSourceList: stores.OrderSourceStore.orderSourceList,
  getOrderSourceData: stores.OrderSourceStore.getOrderSourceData,
  addOrderSource: stores.OrderSourceStore.addOrderSource,
  delOrderSource: stores.OrderSourceStore.delOrderSource,
  updSourceName: stores.OrderSourceStore.updSourceName,
}))

export default withData(OrderSource)
