import React, { useState } from 'react';
import { ReactComponent as ScrollArrowLeft } from '../../assets/ScrollArrowLeft.svg';
import { ReactComponent as ScrollArrowRight } from '../../assets/ScrollArrowRight.svg';
import { ReactComponent as ClearIcon } from '../../assets/ClearIcon.svg';
import styles from './styles.module.scss'

const PhotoModal = ({ isOpen, onClose, photos }) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setSelectedPhotoIndex(index);
  };

  const handlePrevPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex === 0 ? photos.length - 1 : prevIndex - 1));
  };

  const handleNextPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex === photos.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={`${styles.photoModal} ${isOpen ? styles.photoModalOpen : ''}`}>
      <div className={styles.modalContentImage}>
        <div className={styles.thumbnailGallery}>
          {photos.map((photo, index) => (
            <div
              key={index}
              className={`${styles.thumbnail} ${selectedPhotoIndex === index ? styles.selected : ''}`}
              onClick={() => handleThumbnailClick(index)}
            >
              <img src={photo.file} alt="" />
            </div>
          ))}
        </div>
        <div className={styles.photoViewer}>
          <div className={styles.photoCarousel}>
            <ScrollArrowLeft className={styles.scrollLeft} onClick={handlePrevPhoto} />
            <img src={photos[selectedPhotoIndex].file} alt="" />
            <ScrollArrowRight className={styles.scrollRight} onClick={handleNextPhoto} />
          </div>
          <div className={styles.countNumberPhoto}>
            <p>{selectedPhotoIndex + 1}/{photos.length}</p>
          </div>
        </div>
        <div className={styles.closeBlock}>
          <button onClick={onClose}>
            <ClearIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhotoModal;