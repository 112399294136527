export const delBtn = (driver, delDriver, history, setModalConfirmAction, t) => {
    const onDelete = () => {
        delDriver(driver.id)
        history.goBack();
    };
    setModalConfirmAction({
        onAction: onDelete,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_delete'),
    });
};

export const blockBtn = (driver, blockDriver, setIsBlock, setModalNotification, setModalConfirmAction, t) => {
    const onBlock = () => {
        blockDriver(driver.id)
            .then(res => {
                if (res.status === 204) {
                    setIsBlock(true);
                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('block_success'),
                    });
                }
            })
            .catch(error => {
                setIsBlock(false);
                setModalNotification({
                    success: false,
                    onClose: () => setModalNotification({ isOpen: false }),
                    isOpen: true,
                    text: t('block_not_success'),
                });
            });
    };
    setModalConfirmAction({
        onAction: onBlock,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_block'),
    });
};

export const unblockBtn = (driver, unblockDriver, setIsBlock, setModalNotification, setModalConfirmAction, t) => {
    const onUnblock = () => {
        unblockDriver(driver.id)
            .then(res => {
                if (res.status === 204) {
                    setIsBlock(false);
                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('unblock_success'),
                    });
                }
            })
            .catch(error => {
                setIsBlock(true);
                setModalNotification({
                    success: false,
                    onClose: () => setModalNotification({ isOpen: false }),
                    isOpen: true,
                    text: t('unblock_not_success'),
                });
            });
    };
    setModalConfirmAction({
        onAction: onUnblock,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_unblock'),
    });
};

export const approveBtn = (driver, activateDriver, setIsActivated, setModalNotification, setModalConfirmAction, t) => {
    const onApprove = () => {
        activateDriver(driver.id)
            .then(res => {
                if (res.status === 204) {
                    setIsActivated(true);
                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('approve_success'),
                    });
                }
            })
            .catch(error => {
                setIsActivated(false);
                setModalNotification({
                    success: false,
                    onClose: () => setModalNotification({ isOpen: false }),
                    isOpen: true,
                    text: t('approve_not_success'),
                });
            });
    };
    setModalConfirmAction({
        onAction: onApprove,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_approve'),
    });
};

export const saveBtn = (driver, firstName, lastName, callSign, phoneNumber, emailPost, oldUser, updateDriver, setModalNotification, setModalConfirmAction, setPhoneNumber, setCallSign, t) => {
    const onSave = () => {
        const driverInfo = {
            ...driver,
            firstName: firstName,
            lastName: lastName,
            callSign: callSign,
            phone: phoneNumber,
            email: emailPost
        }
        updateDriver(driver.id, driverInfo)
            .then((success) => {
                if (success) {

                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('changes_success_save'),
                    });
                } else {
                    setCallSign(oldUser.callSign)
                    setPhoneNumber(oldUser.phone)
                }
            })
            .catch((error) => {
                if (error.response.data.Data === "driver_call_sign_already_exists") {
                    setModalNotification({
                        success: false,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('call_sign_error'),
                    });
                }
                if (error.response.data.Message.includes("Phone should be defined")) {
                    setModalNotification({
                        success: false,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('phone_number_error'),
                    });
                }
            });
    };
    setModalConfirmAction({
        onAction: onSave,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_save_changes'),
    });
};