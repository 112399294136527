import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { MdRestore } from "react-icons/md";

import { Table, Tooltip } from "antd";

import ModalSimple from "../ModalSimple";
import PassengersInfo from "./PassengersInfo";
import AddClient from "./AddClient";
import { t } from "i18next";
import { takePageElements } from "shared/constants";

@observer
class PassengersBlacklist extends Component {
  state = {
    showModal: false,
    showCreateClient: false,
    activeClientID: 0,
  };

  close = () => {
    this.setState({
      showModal: false,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeClientID: id,
    });
  };

  columns = [
    {
      title: t('name'),
      dataIndex: "firstName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: t('last_name'),
      dataIndex: "lastName",
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        return (
          <Tooltip title={t('unblock')}>
            <MdRestore
              onClick={() => {
                this.close();
                this.props.restore(record.id);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getPassengersData({
      skip: 0,
      take: takePageElements,
    });
  }

  closeCreateClient = () => {
    this.setState({
      showCreateClient: false,
    });
  };

  openCreateClient = () => {
    this.setState({
      showCreateClient: true,
    });
  };

  render() {
    const { passengersList } = this.props;
    return (
      <div>
        <Table
          columns={this.columns}
          dataSource={passengersList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <PassengersInfo
              activeClientID={this.state.activeClientID}
              blackList={true}
              close={this.close}
            />
          </ModalSimple>
        ) : null}
        {this.state.showCreateClient ? (
          <ModalSimple close={this.closeCreateClient}>
            <AddClient close={this.closeCreateClient} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  restore: stores.PassengersStore.restore,
  passengersList: stores.PassengersStore.passengersBlackList,
  getPassengersData: stores.PassengersStore.getPassengersBlackListData,
}));

export default withData(PassengersBlacklist);
