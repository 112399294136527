import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../../config";

class FeesReasonsStore {
  @observable isError = false;
  @observable feesList = [];
  @observable isAddError = false;
  @observable isDeleteError = false;
  @observable isEditError = false;

  getFeesData = async () => {
    this.isError = false;
    const accessToken = localStorage.getItem("accessToken");
    try {
      const result = await axios.get(`${apiIdentity}catalog/fine-reasons`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      this.feesList = result.data.data;
    } catch (e) {
      this.isError = true;
    }
  };

  addFee = async (description, defaultFee) => {
    this.isAddError = false;
    const accessToken = localStorage.getItem("accessToken");
    try {
      const result = await axios.post(
        `${apiIdentity}catalog/fine-reasons`,
        { description, defaultFee },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.getFeesData();
    } catch (e) {
      this.isAddError = true;
    }
  };

  editFee = async (id, description, defaultFee) => {
    this.isEditError = false;
    const accessToken = localStorage.getItem("accessToken");
    try {
      const result = await axios.put(
        `${apiIdentity}catalog/fine-reasons/${id}`,
        { id, description, defaultFee },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.getFeesData();
    } catch (e) {
      this.isEditError = true;
    }
  };

  deleteFee = async (id) => {
    this.isDeleteError = false;
    const accessToken = localStorage.getItem("accessToken");
    try {
      const result = await axios.delete(
        `${apiIdentity}catalog/fine-reasons/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.getFeesData();
    } catch (e) {
      this.isDeleteError = true;
    }
  };
}

export default new FeesReasonsStore();
