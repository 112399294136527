/* eslint-disable default-case */
import { observable } from "mobx";
import { apiIdentity } from "../../config";
import axios from "axios";
import { TechReviewInfo } from "./types";
import { takePageElements } from "shared/constants";



class InfoPageStore {
  @observable loading = true
  @observable headerName = ''
  @observable data = {};


  @observable apiInfo = {
    id: '',
    special: '',
    callSign : ''
  };

  getSwitchInfo = async () => {
    this.loading = true;
    switch (this.apiInfo.special) {
      case TechReviewInfo.DRIVER_INFO: return this.getInfoPage();
      case TechReviewInfo.INSPECTION_INFO: return this.getInspectionsInfo();
    }
  }

  getInfoPage = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const urlDriver = apiIdentity + 'v2/drivers/' + this.apiInfo.id;
    const urlCarInfo = apiIdentity + 'cars/list'

    const data = {}
    try {
      const driver = await axios(urlDriver, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      const carInfo = await axios.post(urlCarInfo,
        {
          driverId: this.apiInfo.id,
          skip: 0,
          take: 100
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      if (driver.status === 200) {
        data.driver = driver.data;
        
      } else {
        console.log('error ')
      }

      if (carInfo.data.success) {
        const cars = carInfo.data.data.content;
        data.carInfo = cars;
        const allInfo = await axios.all(cars.map(e => axios.get(apiIdentity + 'cars/' + e.id, { headers: { Authorization: `Bearer ${accessToken}` } })))
        const infoCarsId = allInfo.map((e) => e.data.success ? e.data.data : null).filter(e => e)
        data.infoCarsId = infoCarsId;

        const response_thexoglads = await axios.all(infoCarsId.map(el => axios.post(
          apiIdentity + 'inspection',
          {
            carId: el.id,
            skip: 0,
            take: takePageElements
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )))
        const thexoglads = response_thexoglads.map(tex => {
          if (tex.data.success) return tex.data.data[0]
          return null
        }).filter(a => a)
        data.thexoglads = thexoglads;

        const photos_texoglads = await axios.all(thexoglads.map(tex => axios.get(apiIdentity + 'inspection/' + tex.id, { headers: { Authorization: `Bearer ${accessToken}` } })))
        photos_texoglads.forEach(e => {
          if (e.status === 200) {
            console.log(e)
            const data = e.data.data;
            const tex = thexoglads.find(t => t.id === data.id);
            if (!tex) return;
            const car = cars.find(car => car.govNumber === tex.govNumber);
            car.files = data.files
          }
        })
        data.carInfo = cars;
      } else {
        console.log('error ')
      }

      console.log(data)
      this.data = data;

    } catch (e) {

    }

    this.loading = false;

  }

  getInspectionsInfo = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const urlInspection = apiIdentity + 'inspection';
  
    const data = {};
    try {
      const inspection = await axios.get(urlInspection + '/' + this.apiInfo.inspectionId, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      
      if (inspection.data.success === false) return 'error';
  
      this.data = inspection.data.data;
    } catch (e) {
    }
    this.loading = false;
  }

  openInfoPage = (id, special, headerName, data) => {
    this.apiInfo = { id, special, inspectionId : data.id }
    this.headerName = headerName;
    this.getSwitchInfo()
  }
}

export default new InfoPageStore();
