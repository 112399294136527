import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { t } from "i18next";

@observer
class InspectionInfo extends Component {
  componentDidMount() {
    this.props.getInsp(this.props.inspectionID);
  }

  chgInspectionStatus = (inspectionID, status, statusComment) => {
    this.props.chgInspectionStatus(inspectionID, status, statusComment);
    this.props.close();
  };

  render() {
    const {
      driverName,
      carBrandAndModel,
      createdAt,
      comment,
      statusComment,
      country,
      status,
      files,
    } = this.props.currentInspection;

    const inspectionImgs = files.map((item) => {
      return (
        <div className="col-6">
          <img src={item.file} className="img-fluid" alt={item.id} />
        </div>
      );
    });

    const stillUtc = moment.utc(createdAt).toDate();
    const local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    const countryValue = country === null ? 'UA' : country;

    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={driverName}
              name="driverName"
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={carBrandAndModel}
              name="carBrandAndModel"
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={local}
              name="createdAt"
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={countryValue}
              name="createdAt"
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={comment}
              name="comment"
              className="form-control"
              placeholder={t('comment')}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <select
              name="status"
              value={status}
              className="form-control"
              onChange={(evt) =>
                this.props.chgInspectionInfo("status", evt.target.value)
              }
            >
              <option value="0">{t('new')}</option>
              <option value="1">{t('approved_first')}</option>
              <option value="2">{t('renounced')}</option>
            </select>
          </div>
        </div>
        <div className="row form-row">{inspectionImgs}</div>
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={statusComment}
              name="statusComment"
              className="form-control"
              onChange={(evt) =>
                this.props.chgInspectionInfo("statusComment", evt.target.value)
              }
              placeholder={t('status_comment')}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <button
              className="btn btn-success"
              onClick={() =>
                this.chgInspectionStatus(
                  this.props.inspectionID,
                  status,
                  statusComment
                )
              }
            >
              {t('save_changes')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  currentInspection: stores.CarInspectionsStore.currentInspection,
  getInsp: stores.CarInspectionsStore.getInsp,
  chgInspectionStatus: stores.CarInspectionsStore.chgInspectionStatus,
  chgInspectionInfo: stores.CarInspectionsStore.chgInspectionInfo,
}));

export default withData(InspectionInfo);
