import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../config";
import { takePageElements } from "shared/constants";

class PassengersStore {
  @observable passengersList = [];
  @observable passengersBlackList = [];
  @observable activeClient = {};
  @observable ratingList = [];

  getPassengersData = (filters) => {
    console.log("getPassengersData filters", filters);

    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}clients/list`, filters, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.passengersList = [...response.data.data.content];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getClientRating = (id, fromDate, toDate) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}clients/rating`,
        { id, fromDate, toDate, skip: 0, take: takePageElements },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.ratingList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPassengersBlackListData = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}clients/blacklist?skip=0&take=${takePageElements}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.passengersBlackList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  restore = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}clients/${id}/blacklist`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getPassengersData({
            skip: 0,
            take: takePageElements,
          });
          this.getPassengersBlackListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  block = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}clients/${id}/blacklist`,
        { id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getPassengersData({
            skip: 0,
            take: takePageElements,
          });
          this.getPassengersBlackListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addPassengers = (passengerData) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}clients`, passengerData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.Success === false) {
          this.isError = true;
        } else {
          this.getPassengersData({
            skip: 0,
            take: takePageElements,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getClient = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}clients/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
          this.activeClient = {};
        } else {
          this.activeClient = { ...response.data.data };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateClient = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .put(
        `${apiIdentity}clients/${this.activeClient.id}`,
        { ...this.activeClient },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getPassengersData({
            skip: 0,
            take: takePageElements,
          });
          this.getPassengersBlackListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addBonus = (bonusCount) => {
    const accessToken = localStorage.getItem("accessToken");
    const userIds = [this.activeClient.id];
    const bonusAmount = Number(bonusCount);
    axios
      .post(
        `${apiIdentity}clients/promotion`,
        { userIds, bonusAmount },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        this.getClient(this.activeClient.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addGroupeBonus = (bonusCount, selectedUsers) => {
    const accessToken = localStorage.getItem("accessToken");
    const bonusAmount = Number(bonusCount);
    axios
      .post(
        `${apiIdentity}clients/promotion`,
        { userIds: selectedUsers, bonusAmount },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  chgClientInfo = (evt) => {
    this.activeClient[evt.target.name] = evt.target.value;
  };

  delClient = async (blackList) => {
    const accessToken = localStorage.getItem("accessToken");
    const uid = this.activeClient.id;
    try {
      await axios.delete(`${apiIdentity}clients/${uid}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (blackList) {
        this.getPassengersBlackListData();
      } else {
        this.getPassengersData({
          skip: 0,
          take: takePageElements,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  blockClient = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}clients/${this.activeClient.id}/block`,
        { id: this.activeClient.id, reason: "Заблокирован администратором" },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        this.getClient(this.activeClient.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  unblockClient = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}clients/${this.activeClient.id}/unblock`,
        { id: this.activeClient.id, reason: "Разблокирован администратором" },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        this.getClient(this.activeClient.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new PassengersStore();
