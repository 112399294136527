export const getNameHeader = (key) => {
  switch (key) {
    case "createdAt": return "date_of_creation"
    case "firstName": return "name"
    case "lastName": return "last_name"
    case "phone": return "mobile_number"
    case "callSign": return "call_sign"
    case "carBrandAndModel": return "model"
    case "model": return "car_model"
    case "govNumber": return "number_plate"
    case "carClass": return "car_class"
    case "brand": return "car_brand"
    case "isActivated": return "status"
    default: return key
  }
}