import { observable} from 'mobx';
import axios from 'axios';

import { apiIdentity } from '../../../config'

class OrderOptionsStore {
  @observable isError = false
  @observable optionsList = []

  getOptionsData = () => {
    const accessToken = localStorage.getItem('accessToken')
    axios.get(`${apiIdentity}catalog/order-preferences`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        console.log(response.data)
        if(response.data.success === false){
          this.isError = true
        } else {         
          this.optionsList = [...response.data.data]
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  addOption = (optionName, price) => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.post(`${apiIdentity}catalog/order-preferences`, {name: optionName, price: price}, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getOptionsData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  updOption = (optionName, price, id) => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.put(`${apiIdentity}catalog/order-preferences/${id}`, {name: optionName, price: price, id}, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getOptionsData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  delOption = id => {
    const accessToken = localStorage.getItem('accessToken')
    axios.delete(`${apiIdentity}catalog/order-preferences/${id}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getOptionsData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }
}

export default new OrderOptionsStore();