import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../config";

class UsersStore {
  @observable usersList = [];
  @observable isError = false;

  getUsersData = (accessTokenOld, refreshToken, expires) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}users/list`,
        { skip: 0, take: 50 },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.usersList = [...response.data.data.content];
          console.log(this.usersList);
        }
      })
      .catch((err) => {
        console.log(err);
        this.isError = true;
      });
  };

  createUser = (data) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}users`,
        { ...data },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getUsersData(accessToken);
          this.isError = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isError = true;
      });
  };

  updUser = (data) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .put(
        `${apiIdentity}users/${data.id}`,
        { ...data },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getUsersData(accessToken);
          this.isError = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isError = true;
      });
  };

  restore = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}users/${id}/unblock`,
        { id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getUsersData(accessToken);
          this.isError = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isError = true;
      });
  };

  block = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}users/${id}/block`,
        { id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getUsersData(accessToken);
          this.isError = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isError = true;
      });
  };

  chgUserPassword = (userID, newPassword) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .put(
        `${apiIdentity}users/${userID}/change-pass`,
        { id: userID, password: newPassword },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.isError = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isError = true;
      });
  };
}

export default new UsersStore();
