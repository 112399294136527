import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { t } from "i18next";

import { MdRestore, MdBlock } from "react-icons/md";

import { Table, Tooltip, DatePicker } from "antd";

import ModalSimple from "../ModalSimple";
import { takePageElements } from 'shared/constants'

const { RangePicker } = DatePicker;

@observer
class CashOut extends Component {
  state = {
    showModal: false,
    activeDriverID: 0,
    method: "ByCashInHand",
    filterApproved: "",
    filterDriverName: "",
    filterCashOutMethod: "",
    filterAmountFrom: "",
    filterAmountTo: "",
    skip: 0,
    take: takePageElements,
    filterDateFrom: moment().startOf("day"),
    filterDateTo: moment().endOf("day"),
  };

  applyFilters = () => {
    const filterOptions = {
      skip: this.state.skip,
      take: this.state.take,
      driverName: this.state.filterDriverName,
      amountFrom: this.state.filterAmountFrom
        ? Number(this.state.filterAmountFrom)
        : "",
      amountTo: this.state.filterAmountTo
        ? Number(this.state.filterAmountTo)
        : "",

      dateFrom: this.state.filterDateFrom,
      dateTo: this.state.filterDateTo,
    };
    if (this.state.filterApproved) {
      filterOptions.approved = Boolean(this.state.filterApproved);
    }
    if (this.state.filterCashOutMethod) {
      filterOptions.cashOutMethod = this.state.filterCashOutMethod;
    }
    this.props.getCashOutList(filterOptions);
  };

  cancelFilters = () => {
    this.setState({
      filterApproved: "",
      filterDriverName: "",
      filterCashOutMethod: "",
      filterAmountFrom: "",
      filterAmountTo: "",
      filterDateFrom: moment().startOf("day"),
      filterDateTo: moment().endOf("day"),
    });
    this.props.getCashOutList({
      skip: this.state.skip,
      take: this.state.take,
      dateFrom: moment().startOf("day"),
      dateTo: moment().endOf("day"),
    });
  };

  close = () => {
    this.setState({
      showModal: false,
      activeDriverID: 0,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeDriverID: id,
    });
  };

  columns = [
    {
      title: t('id'),
      dataIndex: "id",
    },
    {
      title: t('first_name_last_name'),
      dataIndex: "driverFullName",
    },
    {
      title: t('rating'),
      dataIndex: "driverRating",
    },
    {
      title: t('amount'),
      dataIndex: "amount",
    },
    {
      title: t('status'),
      dataIndex: "approved",
      render: (text, record) => {
        let resultText = "";
        if (record.approved) {
          resultText = t('approved_second');
        }
        if (!record.approved) {
          resultText = t('rejected_second');
        }
        if (record.approved == null) {
          resultText = t('not_reviewed');
        }
        return <>{resultText}</>;
      },
    },
    {
      title: t('output_path'),
      dataIndex: "cashOutMethod",
      render: (text, record) => {
        let resultText = "";
        if (record.cashOutMethod === 0) {
          resultText = t('to_card');
        }
        if (record.cashOutMethod === 1) {
          resultText = t('by_terminal');
        }
        if (record.cashOutMethod === 2) {
          resultText = t('by_cash');
        }
        return <>{resultText}</>;
      },
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        return (
          <>
            {record.approved ? (
              ""
            ) : (
              <Tooltip title={t('confirm')}>
                <MdRestore
                  onClick={() => {
                    this.close();
                    this.openModal(record.id);
                  }}
                />
              </Tooltip>
            )}
            {/* Allow cash out */}
            {/* <Tooltip title={t('rejected_third')}>
              <MdBlock
                onClick={() => {
                  this.close();
                  this.props.declineCashOut(record.id);
                }}
              />
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getCashOutList({
      skip: this.state.skip,
      take: this.state.take,
      dateFrom: moment().startOf("day"),
      dateTo: moment().endOf("day"),
    });
  }

  chgSelect = (evt) => {
    this.setState({
      method: evt.target.value,
    });
  };

  chgInp = (evt) => {
    if (
      evt.target.name === "filterAmountFrom" ||
      evt.target.name === "filterAmountTo"
    ) {
      const numberPattern = new RegExp(/^-?\d*\.?\d*$/);
      const regexpResult = numberPattern.test(evt.target.value);

      if (!regexpResult) {
        return;
      }
    }
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  chgDateRange = (date) => {
    if (date.length === 2) {
      this.setState({
        filterDateFrom: date[0].startOf("day"),
        filterDateTo: date[1].endOf("day"),
      });
    }
    console.log(date);
  };

  render() {
    const { cashOutList } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterApproved"
              value={this.state.filterApproved}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('status')}
            >
              <option value="">{t('status')}</option>
              <option value="true">{t('approved_second')}</option>
              <option value="false">{t('rejected_second')}</option>
              <option value="false">{t('not_reviewed')}</option>
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterDriverName"
              value={this.state.filterDriverName}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('first_name_last_name')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterCashOutMethod"
              value={this.state.filterCashOutMethod}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('output_path')}
            >
              <option value="">{t('output_path')}</option>
              <option value="ByOnlineTransfer">{t('online')}</option>
              <option value="ByTerminalTransfer">{t('terminal')}</option>
              <option value="ByCashInHand">{t('by_cash')}</option>
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterAmountFrom"
              value={this.state.filterAmountFrom}
              className="form-control"
              onChange={this.chgInp}
              style={{ maxWidth: "130px", float: "left" }}
              placeholder={t('amount_from')}
            />
            <input
              name="filterAmountTo"
              value={this.state.filterAmountTo}
              className="form-control"
              onChange={this.chgInp}
              style={{ maxWidth: "130px", float: "left", marginLeft: "10px" }}
              placeholder={t('amount_to')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <RangePicker
              placeholder={[t('create_date_from'), t('create_date_to')]}
              className="form-control"
              format="YYYY/MM/DD"
              value={[this.state.filterDateFrom, this.state.filterDateTo]}
              onChange={this.chgDateRange}
              allowClear={false}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <button
              className="btn btn-warning"
              onClick={this.applyFilters}
              style={{ marginRight: "10px" }}
            >
              {t('filter')}
            </button>
            <button className="btn btn-secondary" onClick={this.cancelFilters}>
              {t('discard')}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={cashOutList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
            },
          })}
        />

        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <div className="col-12">
              <div className="row form-row">
                <div className="col-12">
                  <select
                    name="method"
                    className="form-control"
                    value={this.state.method}
                    onChange={this.chgSelect}
                  >
                    <option value="ByOnlineTransfer">
                      {t('online_transfer_to_the_card')}
                    </option>
                    <option value="ByTerminalTransfer">
                      {t('Вывод через терминал')}
                    </option>
                    <option value="ByCashInHand">{t('by_cash')}</option>
                  </select>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      this.props.approveCashOut(
                        this.state.activeDriverID,
                        this.state.method
                      )
                    }
                  >
                    {t('confirm')}
                  </button>
                </div>
              </div>
            </div>
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  cashOutList: stores.CashOutStoreStore.cashOutList,
  getCashOutList: stores.CashOutStoreStore.getCashOutList,
  approveCashOut: stores.CashOutStoreStore.approveCashOut,
  declineCashOut: stores.CashOutStoreStore.declineCashOut,
}));

export default withData(CashOut);
