import React, { useEffect } from "react";
import { observer } from "mobx-react";

import NavMenu from "../../components/NavMenu";
import { t } from "i18next";
import ManualLocationStore from "./store";
import { toJS } from "mobx";

import ManualLocationForm from "./ManualLocationForm";
import { branchIds } from "shared/variables";
import TopHeader from "components/TopHeader";

const ManualLocationPage = () => {
  useEffect(() => {
    ManualLocationStore.getGpsRegions({ branchId: branchIds.carma });
  }, []);

  const pageTitle = "gps_problem"

  return (
    <div className="row">
      <div className="col-1">
        <NavMenu />
      </div>
      <div className="col-11">
      <TopHeader pageTitle={pageTitle} />
        <ManualLocationForm
          gpsRegions={toJS(ManualLocationStore.gpsRegions)}
          getRegions={({ branchId }) =>
            ManualLocationStore.getGpsRegions({ branchId })
          }
          submitButtonText={t("save_changes")}
          setGpsRegion={ManualLocationStore.setGpsRegion}
        />
      </div>
    </div>
  );
};

export default observer(ManualLocationPage);
