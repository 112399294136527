import React, { Suspense, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { inject, observer } from "mobx-react";
import LoginPage from './pages/LoginPage'
import CarsPage from './pages/CarsPage'
import OrdersPage from './pages/OrdersPage'
import PassengersPage from './pages/PassengersPage'
import DriversPage from './pages/DriversPage'
import CatalogPage from './pages/CatalogPage'
import PaymentsPage from './pages/PaymentsPage'
import UsersPage from './pages/UsersPage'
import StatisticsPage from './pages/StatisticsPage'
import NewsPage from './pages/NewsPage'
import FeedbackPage from './pages/FeedbackPage'
import FAQPage from './pages/FAQPage'
import GateHistoryPage from './pages/GateHistoryPage'
import CashOutPage from './pages/CashOutPage'
import SettingsPage from './pages/SettingsPage'
import InfoPage from './pages/InfoPage'
import TechnicalInspectionsPage from './pages/TechnicalInspectionsPage';
import ManualLocationPage from "./pages/ManualLocationPage";
import './App.css'
import './styles/global.scss'

function App({getAllStore}) {

  useEffect(() => {
    getAllStore()
  }, [])
  

  return (
    <div className="container-fluid" style={{ paddingLeft: "0" }}>
      <Suspense fallback="">
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/drivers" component={DriversPage} />
          <Route exact path="/technical-inspections" component={TechnicalInspectionsPage} />
          <Route exact path="/cars" component={CarsPage} />
          <Route exact path="/orders" component={OrdersPage} />
          <Route exact path="/passengers" component={PassengersPage} />
          <Route exact path="/catalog" component={CatalogPage} />
          <Route exact path="/payments" component={PaymentsPage} />
          <Route exact path="/users" component={UsersPage} />
          <Route exact path="/gate-history" component={GateHistoryPage} />
          <Route exact path="/statistics" component={StatisticsPage} />
          <Route exact path="/news" component={NewsPage} />
          <Route exact path="/feedback" component={FeedbackPage} />
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/cash-out" component={CashOutPage} />
          <Route exact path="/settings" component={SettingsPage} />
          <Route exact path="/info-page" component={InfoPage} />
          <Route exact path="/gps-problem" component={ManualLocationPage} />
        </Switch>
      </Suspense>
    </div>
  );
}

const withData = inject((stores) => ({
  getAllStore: stores.CarStore.getAllStore,
}));

export default observer(withData(App));