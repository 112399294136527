import { observable, action} from 'mobx';
import axios from 'axios'

import { apiIdentity } from '../../config'

class LoginFromStore {
  @observable isLoggedIn;
  @observable isError = false
  @observable accessToken = false
  @observable refreshToken = false
  @observable expires = false
  @observable id = false

  constructor() {
    this.isLoggedIn = false;
  }

  refreshWindow = () => {
    this.isLoggedIn = true
  }

  @action
  tryAuth = (login, password) => {
    // this.isLoggedIn = true
    axios.post(`${apiIdentity}auth/sign-in`, {email: login, password: password})
      .then( response => {
        console.log(response.data)
        if(response.data.data.Success === false){
          this.isError = true
        } else {     
          this.accessToken = response.data.data.accessToken
          this.refreshToken = response.data.data.refreshToken
          this.expires = response.data.data.expires
          this.id = response.data.data.id
          
          localStorage.setItem('accessToken', this.accessToken)
          localStorage.setItem('refreshToken', this.refreshToken)
          localStorage.setItem('expires', this.expires)
          localStorage.setItem('adminId', response.data.data.id)
          localStorage.setItem('login', login)

          this.isError = false
          this.isLoggedIn = true
        }
      })
      .catch( err => {
        console.log(err)
        this.isError = true
      })
  }

  logout = () => {
    localStorage.clear()
    this.isLoggedIn = false
  }
}

export default new LoginFromStore();