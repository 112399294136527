import { observable } from "mobx";
import axios from "axios";
import moment from "moment";

import { apiIdentity } from "../../config";

class PaymentsStore {
  @observable paymentsList = [];

  getPaymentsData = (filters) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}payments`, filters, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const fullDataInHuman = response.data.data.map((item) => {
          const current = { ...item };
          current.paymentTypeName =
            Number(current.paymentTypeId) === 0 ? "Cash" : "CreditCard";
          current.operationTypeName =
            Number(current.operationTypeId) === 0 ? "OrderPayment" : "CashOut";

          const stillUtc = moment.utc(current.date).toDate();
          current.date = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
          return current;
        });
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.paymentsList = [...fullDataInHuman];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new PaymentsStore();
