import { observable} from 'mobx';

class CatalogStore {
  @observable activeTab = 1



  chgActiveTab = (id) => {
    this.activeTab = id
  }
}

export default new CatalogStore();