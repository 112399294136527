import { observable } from "mobx";
import { axiosInstanceWithToken } from "../../shared/api/axiosInstance";
import { branchIds, gpsStatuses } from "shared/variables";

class ManualLocationStore {
  @observable gpsRegions = [];

  getGpsRegions = async ({ branchId = branchIds.carma }) => {
    this.gpsRegions = [];
    try {
      const { data } = await axiosInstanceWithToken.get(
        `/gps-regions/${branchId}`
      );
      this.gpsRegions = data || [];
    } catch (error) {
      console.log("error in getGpsRegions:", error);
    }
  };

  setGpsRegion = async ({
    status = gpsStatuses.unblock,
    branchId,
    regionCode,
  }) => {
    const requestData = {
      branchId,
      regionCode,
    };
    try {
      const { data } = await axiosInstanceWithToken.post(
        `/gps-regions/${status}`,
        requestData
      );
      this.gpsRegions = data || [];
    } catch (error) {
      console.log("error in getRegions:", error);
    }
  };
}

export default new ManualLocationStore();
