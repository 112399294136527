import React, { Component } from "react";
import { Table, DatePicker } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { t } from "i18next";

import OrderInfo from "../Orders/OrderInfo";
import ModalSimple from "../ModalSimple";

const { RangePicker } = DatePicker;

@observer
class DriverRating extends Component {
  state = {
    fromDate: moment(new Date()),
    toDate: moment(new Date()),
    activeOrder: 0,
    showModal: false,
  };

  columns = [
    {
      title: t('revocation_date'),
      dataIndex: "createdAt",
      render: (text, record) => {
        const stillUtc = moment.utc(record.createdAt).toDate();
        const local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
        return <span>{local}</span>;
      },
    },
    {
      title: t('date_of_order'),
      dataIndex: "orderCreatedAt",
      render: (text, record) => {
        const stillUtc = moment.utc(record.orderCreatedAt).toDate();
        const local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
        return (
          <span
            style={{ color: "#007bff", cursor: "pointer" }}
            onClick={() => this.openModal(record.orderId)}
          >
            {local}
          </span>
        );
      },
    },
    {
      title: t('client'),
      dataIndex: "clientFullName",
    },
    {
      title: t('comment'),
      dataIndex: "comment",
    },
    {
      title: t('grade'),
      dataIndex: "rating",
    },
  ];

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeOrder: id,
    });
  };

  close = () => {
    this.setState({
      showModal: false,
      activeOrder: 0,
    });
  };

  chgDate = (dates) => {
    console.log(dates);
    this.setState({
      fromTime: dates[0],
      tillTime: dates[1],
    });
  };

  filterDate = () => {
    this.props.getDriverRating(
      this.props.driverId,
      moment.utc(this.state.fromTime),
      moment.utc(this.state.tillTime)
    );
  };

  render() {
    const fromTime = this.state.fromTime
      ? moment(this.state.fromTime, "YYYY-MM-DD")
      : null;
    const tillTime = this.state.tillTime
      ? moment(this.state.tillTime, "YYYY-MM-DD")
      : null;

    return (
      <div>
        <div className="row">
          <div
            className="col-12"
            style={{ paddingTop: "10px", paddingBottom: "20px" }}
          >
            <RangePicker
              placeholder={[t('create_date_from'), t('create_date_to')]}
              value={[fromTime, tillTime]}
              onChange={this.chgDate}
              disabledDate={(current) => {
                return current && current > moment().add(1, "day");
              }}
              allowClear={false}
            />
            <button
              className="btn btn-warning"
              onClick={this.filterDate}
              style={{ margin: "10px" }}
            >
              {t('apply')}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={this.props.ratingList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.orderId);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <OrderInfo activeOrder={this.state.activeOrder} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getDriverRating: stores.DriversStore.getDriverRating,
  ratingList: stores.DriversStore.ratingList,
}));

export default withData(DriverRating);
