import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { t } from "i18next";

import { Table } from "antd";

import ModalSimple from "../../ModalSimple";
import CarMarkInfo from "./CarMarkInfo";
@observer
class CarsMark extends Component {
  state = {
    brandName: "",
    brandNameMark: "",
    markName: "",
    activeBrand: 0,
    showBrandInfo: false,
  };

  close = () => {
    this.setState({
      activeBrand: 0,
      showBrandInfo: false,
    });
  };

  editBrand = (id) => {
    this.setState({
      activeBrand: id,
      showBrandInfo: true,
    });
  };

  carColumns = [
    {
      title: t('marks'),
      dataIndex: "carBrand",
    },
    {
      title: t('models'),
      dataIndex: "carModels",
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        return (
          <span
            onClick={() => this.editBrand(record.id)}
            className="btn btn-warning"
          >
            {t('edit')}
          </span>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getCarBrandsData();
    // CatalogStore.getCarColorsData()
    // CatalogStore.getCarClassData()
    // CatalogStore.getCarRateData()
  }

  chgInput = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  render() {
    const carBrands = toJS([...this.props.carBrands]);

    let tblData = carBrands.map((car) => {
      const models = car.models
        ? car.models.map((model) => {
            return model.name;
          })
        : [];

      return {
        id: car.id,
        carBrand: car.name,
        carModels: models.join(", "),
      };
    });
    const brandNames = carBrands.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    /*
    <div className="col-12">
              <button className="btn btn-warning" onClick={this.props.importFromAutoRia}>Импортировать</button>
            </div>
    */
    return (
      <>
        <div className="row" style={{ paddingBottom: "20px" }}>
          <div className="col-12" style={{ display: "none" }}>
            <button
              className="btn btn-warning"
              onClick={this.props.importFromAutoRia}
            >
              {t('import')}
            </button>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-12" style={{ paddingBottom: "20px" }}>
              {t('add_brand')}
              </div>
              <div className="col-12" style={{ paddingBottom: "20px" }}>
                <input
                  type="text"
                  placeholder={t('brand_name')}
                  name="brandName"
                  value={this.state.brandName}
                  onChange={this.chgInput}
                  className="form-control"
                />
              </div>
              <div className="col-12" style={{ paddingBottom: "20px" }}>
                <button
                  onClick={() => {
                    if (this.state.brandName.length > 0) {
                      this.props.addCarBrand(this.state.brandName);
                      this.setState({
                        brandName: "",
                      });
                    } else {
                      alert(t('not_all_data_entered'));
                    }
                  }}
                  className="btn btn-success"
                >
                  {t('add')}
                </button>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-12" style={{ paddingBottom: "20px" }}>
                {t('add_model')}
              </div>
              <div className="col-12" style={{ paddingBottom: "20px" }}>
                <select
                  name="brandNameMark"
                  value={this.state.brandNameMark}
                  className="form-control"
                  onChange={this.chgInput}
                >
                  <option value="">-- {t('select')} --</option>
                  {brandNames}
                </select>
              </div>
              <div className="col-12" style={{ paddingBottom: "20px" }}>
                <input
                  type="text"
                  placeholder={t('model_name')}
                  name="markName"
                  value={this.state.markName}
                  onChange={this.chgInput}
                  className="form-control"
                />
              </div>
              <div className="col-12" style={{ paddingBottom: "20px" }}>
                <button
                  onClick={() => {
                    if (
                      this.state.brandNameMark.length > 0 &&
                      this.state.markName.length > 0
                    ) {
                      this.props.addCarMark(this.state.brandNameMark, this.state.markName);
                      this.setState({
                        brandNameMark: "",
                        markName: "",
                      });
                    } else {
                      alert(t('not_all_data_entered'));
                    }
                  }}
                  className="btn btn-success"
                >
                  {t('add')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Table columns={this.carColumns} dataSource={tblData} />
        </div>
        {this.state.showBrandInfo ? (
          <ModalSimple close={this.close}>
            <CarMarkInfo brandID={this.state.activeBrand} close={this.close} />
          </ModalSimple>
        ) : null}
      </>
    );
  }
}

const withData = inject((stores) => ({
  addCarBrand: stores.CarsMarkStore.addCarBrand,
  getCarBrandsData: stores.CarsMarkStore.getCarBrandsData,
  carBrands: stores.CarsMarkStore.carBrands,
  addCarMark: stores.CarsMarkStore.addCarMark,
  importFromAutoRia: stores.CarsMarkStore.importFromAutoRia,
}));

export default withData(CarsMark);
