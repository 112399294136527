import React, { Component } from 'react'
import { Table } from 'antd';
import { inject, observer } from 'mobx-react'
import ModalSimple from '../ModalSimple'
import CarInfo from '../Cars/CarInfo'
import { t } from 'i18next'

@observer
class DriverHistory extends Component {
  state = {
    showModal: false,
    activeCar: 0,
  }
  
  componentDidMount(){
    this.props.getCarClassData()
    this.props.getDriverCarsData(this.props.driverId)
  }

  columns = [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('car'),
      dataIndex: 'fullModelName',
      sorter: (a, b) => a.fullModelName - b.fullModelName,
    },
    {
      title: t('number_plate'),
      dataIndex: 'govNumber',
      sorter: (a, b) => a.govNumber - b.govNumber,
    }
  ];

  openModal = id => {
    this.setState({
      showModal: true,
      activeCar: id,
    })
  }

  close = () => {
    this.setState({
      showModal: false,
      activeCar: 0,
    })
  }

  render() {
    
    return (
      <div>
        <Table columns={this.columns} dataSource={this.props.carsList} onRow={ (record, rowIndex) => ({
          onDoubleClick: () => {
            this.openModal(record.id)
          }
        })}/>
        {this.state.showModal ? <ModalSimple close={this.close}><CarInfo carID={this.state.activeCar} /></ModalSimple> : null}
      </div>
    )
  }
}

const withData = inject(stores => ({
  getDriverCarsData: stores.CarsStore.getDriverCarsData,
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
  carsList: stores.CarsStore.carsList,
}))

export default withData(DriverHistory)