import { t } from "i18next";
import * as Yup from "yup";

export const NewsSchema = Yup.object().shape({
  newsExtendeds: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("error_title"),
        content: Yup.string().required("error_text"),
      })
    )
    .required("Required"),
  regionCode: Yup.array().min(1, "error_region").required(),
  target: Yup.string().required("empty target value"),
});
