import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'

@observer
class BonusAdd extends Component {
  state = {
    addBonus: 0,
  }

  chgBonus = evt => {
    this.setState({
      addBonus: evt.target.value,
    })
  }

  addBonus = bonusCount => {
    this.props.addGroupeBonus(bonusCount, this.props.selectedUsers)
    this.props.close()
  }

  render() {
    return (
      <>
        <div className="row form-row">
          <div className="col-12">
            {t('impose_bonuses')}:
          </div>
          <div className="col-8">                
            <input type="text" name="addBonus" value={this.state.addBonus} className="form-control" onChange={this.chgBonus} />
          </div>
          <div className="col-4">                
            <button className="btn btn-success" onClick={ () => this.addBonus(this.state.addBonus)}>{t('replenish')}</button>
          </div>
        </div>
      </>
    )
  }
}

const withData = inject(stores => ({
  addGroupeBonus: stores.PassengersStore.addGroupeBonus,
}))

export default withData(BonusAdd)