import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PassengersHistory from "./PassengersHistory";
import PassengersRating from "./PassengersRating";
import { t } from "i18next";
import ModalConfirmDelete from "../ModalConfirmDelete";

@observer
class PassengersInfo extends Component {
  state = {
    saved: false,
    addBonus: "",
    activeTab: 0,
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
    isModalConfirmDelete: false
  };

  componentDidMount() {
    this.props.getClient(this.props.activeClientID);
  }

  updClient = () => {
    this.setState({
      saved: true,
    });
    this.props.updateClient();
  };

  chgBonus = (evt) => {
    this.setState({
      addBonus: evt.target.value.replace(/\D/, ""),
    });
  };

  delClient = () => {
    this.props.close();
    if (this.props.blackList) {
      this.props.delClient(true);
    } else {
      this.props.delClient();
    }
  };

  chgTab = (id) => {
    this.setState({
      activeTab: id,
    });
  };

  changePhone = (e) => {
    let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    }
    e.target.value = sanitizedValue;
    this.props.chgClientInfo(e);
  }

  render() {
    const {
      firstName,
      lastName,
      phone,
      comment,
      balance,
      bonuses,
      country,
      photo,
      isBlocked,
    } = this.props.activeClient;
    return (
      <div className="col-12">
        <ModalConfirmDelete {...this.state.modalSettings} />
        <div className="row">
          <ul className="tabs-menu">
            <li
              className={this.state.activeTab === 0 ? "active" : null}
              onClick={() => this.chgTab(0)}
            >
              {t('information')}
            </li>
            <li
              className={this.state.activeTab === 1 ? "active" : null}
              onClick={() => this.chgTab(1)}
            >
              {t('trip')}
            </li>
            <li
              className={this.state.activeTab === 2 ? "active" : null}
              onClick={() => this.chgTab(2)}
            >
              {t('rating')}
            </li>
          </ul>
        </div>
        {this.state.activeTab === 1 ? (
          <PassengersHistory clientId={this.props.activeClientID} />
        ) : this.state.activeTab === 2 ? (
          <PassengersRating clientId={this.props.activeClientID} />
        ) : (
          <div className="row form-row">
            <div className="col-4">
              <img
                src={
                  photo
                    ? photo
                    : "https://www.infoniac.ru/upload/iblock/a57/a57928a9ff118f9567bc14c16f2b48fc.jpg"
                }
                className="img-fluid"
                alt="Клиент"
              />
              <button
                className="btn btn-danger btn-block"
                onClick={() => {
                  this.setState({ modalSettings : {
                    onYes : this.delClient,
                    onClose : () => this.setState({modalSettings : {isOpen : false}}),
                    isOpen : true,
                    text : t('confirm_delete')
                  }})
                }}
                style={{ marginTop: "20px" }}
              >
                {t('delete_client')}
              </button>
              {isBlocked ? (
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    this.setState({ modalSettings : {
                      onYes : this.props.unblockClient,
                      onClose : () => this.setState({modalSettings : {isOpen : false}}),
                      isOpen : true,
                      text : t('confirm_unblock')
                    }})
                  }}
                  style={{ marginTop: "20px" }}
                >
                  {t('unblock')}
                </button>
              ) : (
                <button
                  className="btn btn-danger btn-block"
                  onClick={() => {
                    this.setState({ modalSettings : {
                      onYes : this.props.blockClient,
                      onClose : () => this.setState({modalSettings : {isOpen : false}}),
                      isOpen : true,
                      text : t('confirm_block')
                    }})
                  }}
                  style={{ marginTop: "20px" }}
                >
                  {t('block')}
                </button>
              )}
            </div>
            <div className="col-8">
              <div className="row form-row">
                <div className="col-12">{t('name')}</div>
                <div className="col-12">
                  <input
                    type="text"
                    name="firstName"
                    value={firstName ? firstName : ""}
                    className="form-control"
                    onChange={this.props.chgClientInfo}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">{t('last_name')}</div>
                <div className="col-12">
                  <input
                    type="text"
                    name="lastName"
                    value={lastName ? lastName : ""}
                    className="form-control"
                    onChange={this.props.chgClientInfo}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">{t('country')}</div>
                <div className="col-12">
                  <input
                    type="text"
                    name="country"
                    value={country === null ? 'UA' : country}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">{t('mobile_number')}</div>
                <div className="col-12">
                  <input
                    type="text"
                    name="phone"
                    maxLength={16}
                    value={phone ? phone : ""}
                    className="form-control"
                    onChange={this.changePhone}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">{t('comment')}</div>
                <div className="col-12">
                  <input
                    type="text"
                    name="comment"
                    value={comment ? comment : ""}
                    className="form-control"
                    onChange={this.props.chgClientInfo}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">{t('balance')}</div>
                <div className="col-12">
                  <input
                    type="text"
                    name="balance"
                    value={balance ? balance : 0}
                    className="form-control"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">{t('bonuses')}</div>
                <div className="col-12">
                  <input
                    type="text"
                    name="bonuses"
                    value={bonuses ? bonuses : 0}
                    className="form-control"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">{t('replenish_bonuses')}</div>
                <div className="col-8">
                  <input
                    type="text"
                    name="addBonus"
                    value={this.state.addBonus}
                    className="form-control"
                    onChange={this.chgBonus}
                  />
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-success"
                    onClick={() => this.props.addBonus(this.state.addBonus)}
                  >
                    {t('replenish')}
                  </button>
                </div>
              </div>
              {this.state.saved ? (
                <div className="row form-row">
                  <div className="col-12 alert alert-success">
                    {t('changes_saved')}
                  </div>
                </div>
              ) : null}
              <div className="row form-row">
                <div className="col-12">
                  <button className="btn btn-success" onClick={this.updClient}>
                    {t('save_changes')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getClient: stores.PassengersStore.getClient,
  updateClient: stores.PassengersStore.updateClient,
  activeClient: stores.PassengersStore.activeClient,
  chgClientInfo: stores.PassengersStore.chgClientInfo,
  delClient: stores.PassengersStore.delClient,
  addBonus: stores.PassengersStore.addBonus,
  blockClient: stores.PassengersStore.blockClient,
  unblockClient: stores.PassengersStore.unblockClient,
}));

export default withData(PassengersInfo);
