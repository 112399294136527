import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { t } from "i18next";

@observer
class InfoFAQ extends Component {
  componentDidMount() {
    this.props.getFAQInfo(this.props.faqID);
  }

  updData = () => {
    this.props.updFAQInfo();
    this.props.close();
  };

  render() {
    const { title, content, target } = this.props.activeFAQ;

    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-4">{t('headers')}</div>
          <div className="col-8">
            <input
              type="text"
              value={title}
              name="title"
              onChange={this.props.chgFAQInfo}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('message')}</div>
          <div className="col-8">
            <textarea
              name="content"
              value={content}
              onChange={this.props.chgFAQInfo}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('audience')}</div>
          <div className="col-8">
            <select
              name="target"
              className="form-control"
              value={target}
              onChange={this.props.chgFAQInfo}
              disabled
            >
              <option value={2}>{t('all')}</option>
              <option value={0}>{t('drivers')}</option>
              <option value={1}>{t('clients')}</option>
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12 text-center">
            <button onClick={this.updData} className="btn btn-success">
              {t('save_changes')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getFAQInfo: stores.FAQStore.getFAQInfo,
  chgFAQInfo: stores.FAQStore.chgFAQInfo,
  updFAQInfo: stores.FAQStore.updFAQInfo,
  activeFAQ: stores.FAQStore.activeFAQ,
}));

export default withData(InfoFAQ);
