import { NotificationManager } from "react-notifications";

/**
 * NotificationManager API
 * NotificationManager.info(message, title, timeOut, callback, priority);
 * NotificationManager.success(message, title, timeOut, callback, priority);
 * NotificationManager.warning(message, title, timeOut, callback, priority);
 * NotificationManager.error(message, title, timeOut, callback, priority);
 * message:string	-> The message string
 * title:string	-> The title string
 * timeOut:number	-> The popup timeout in milliseconds
 * callback:Function	-> A function that gets fired when the popup is clicked
 * priority:boolean	-> If true, the message gets inserted at the top
 */

/**
 * Создает оповещение.
 * @param  {'info'|'success'|'warning'|'error'} type // Тип сообщение, влияет на цвет
 * @param  {string} message
 * @param  {string} title
 * @param  {number} timeout // Время в мс
 * @param  {Function} callback
 * @param  {boolean} priority // Если true оповещение выше других
 */
export const createNotification = (
  type,
  message,
  title,
  timeout,
  callback,
  priority
) => {
  return NotificationManager[type](message, title, timeout, callback, priority);
};
