import React, { Component } from "react";
import NavMenu from "../../components/NavMenu";
import Passengers from "../../components/Passengers";
import { t } from "i18next";

export default class PassengersPage extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-1">
          <NavMenu />
        </div>
        <div className="col-11">
          <div className="row">
            <div className="col">
              <h3>{t('clients')}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Passengers />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
