import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../../config";
class CarsClassStore {
  @observable classList = [];

  getCarClassData = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}catalog/classes`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.classList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addCarClass = (carClass, priority) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .post(
        `${apiIdentity}catalog/classes`,
        { name: carClass, priority },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCarClassData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delClass = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}catalog/classes/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCarClassData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updClass = (id, name, priority) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .put(
        `${apiIdentity}catalog/classes/${id}`,
        { id, name, priority },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCarClassData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new CarsClassStore();
