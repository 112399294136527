import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { MdDeleteForever } from "react-icons/md";
import { t } from "i18next";
import ModalConfirmDelete from "../../ModalConfirmDelete"

import { Table } from "antd";

@observer
class City extends Component {
  state = {
    cityName: "",
    cityLon: "",
    cityLat: "",
    cityWait: "",
    updCityID: null,
    updCity: "",
    updCityLon: "",
    updCityLat: "",
    updCityWait: "",
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  };

  carColumns = [
    {
      title: t('id'),
      dataIndex: "id",
    },
    {
      title: t('city'),
      dataIndex: "name",
    },
    {
      title: t('longitude'),
      dataIndex: "longitude",
    },
    {
      title: t('latitude'),
      dataIndex: "latitude",
    },
    {
      title: t('order_free_wait'),
      dataIndex: "orderFreeWait",
    },
    {
      title: t('actions'),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <MdDeleteForever onClick={() => this.delBtn(record)} />
      ),
    },
  ];

  componentDidMount() {
    this.props.getCityData();
  }

  delBtn = (record) => {
    const recordId = record.id;
    this.setState({
      modalSettings: {
        onYes: () => this.props.delCity(recordId),
        onClose: () => this.setState({ modalSettings: { isOpen: false } }),
        isOpen: true,
        text: t('confirm_delete')
      }
    });
  };

  chgInput = (evt) => {
    if (
      evt.target.name === "cityLon" ||
      evt.target.name === "cityLat" ||
      evt.target.name === "updCityLon" ||
      evt.target.name === "updCityLat"
    ) {
      const numberPattern = new RegExp(/^-?\d*\.?\d*$/);
      const regexpResult = numberPattern.test(evt.target.value);

      if (!regexpResult) {
        return;
      }
    }
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  editCity = (city) => {
    this.setState({
      updCityID: city.id,
      updCity: city.name,
      updCityLon: city.longitude,
      updCityLat: city.latitude,
      // orderFreeWait: cityWait
    });
  };

  updCityName = (id, cityname, latitude, longitude, orderFreeWait) => {
    this.setState({
      updCityID: null,
      updCity: "",
      updCityLon: "",
      updCityLat: "",
      updCityWait: "",
    });
    this.props.updCityName(id, cityname, latitude, longitude, orderFreeWait);
  };

  cancelEdit = () => {
    this.setState({
      updCityID: null,
      updCity: "",
    });
  };

  render() {
    const cityList = this.props.cityList;

    return (
      <>
        <div className="row">
          <div className="col-12">
            {this.state.updCityID ? (
              <>{t('edit_city')}</>
            ) : (
              <>{t('add_city')}</>
            )}
          </div>
        </div>
        {this.state.updCityID ? (
          <div className="row" style={{ paddingBottom: "20px" }}>
            <div className="col-3">
              <input
                type="text"
                name="updCity"
                value={this.state.updCity}
                onChange={this.chgInput}
                className="form-control"
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                name="updCityLon"
                value={this.state.updCityLon}
                onChange={this.chgInput}
                className="form-control"
                placeholder={t('lon')}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                name="updCityLat"
                value={this.state.updCityLat}
                onChange={this.chgInput}
                className="form-control"
                placeholder={t('lat')}
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                name="updCityWait"
                value={this.state.updCityWait}
                onChange={this.chgInput}
                className="form-control"
                placeholder={t('order_free_wait_without_seconds')}
              />
            </div>
            <div className="col-3">
              <button
                onClick={() =>
                  this.updCityName(
                    this.state.updCityID,
                    this.state.updCity,
                    this.state.updCityLat,
                    this.state.updCityLon,
                    this.state.updCityWait
                  )
                }
                className="btn btn-success"
                style={{ marginRight: "20px" }}
              >
                {t('edit')}
              </button>
              <button onClick={this.cancelEdit} className="btn btn-warning">
                {t('cancel')}
              </button>
            </div>
          </div>
        ) : (
          <div className="row" style={{ paddingBottom: "20px" }}>
            <div className="col-3">
              <input
                type="text"
                name="cityName"
                value={this.state.cityName}
                onChange={this.chgInput}
                className="form-control"
                placeholder={t('city_name')}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                name="cityLon"
                value={this.state.cityLon}
                onChange={this.chgInput}
                className="form-control"
                placeholder={t('lon')}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                name="cityLat"
                value={this.state.cityLat}
                onChange={this.chgInput}
                className="form-control"
                placeholder={t('lat')}
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                name="cityWait"
                value={this.state.cityWait}
                onChange={this.chgInput}
                className="form-control"
                placeholder={t('order_free_wait')}
              />
            </div>
            <div className="col-3">
              <button
                onClick={() =>
                  this.props.addCity(
                    this.state.cityName,
                    this.state.cityLon,
                    this.state.cityLat,
                    this.state.cityWait
                  )
                }
                className="btn btn-success"
              >
                {t('add')}
              </button>
            </div>
          </div>
        )}
        <div>
          <Table
            columns={this.carColumns}
            dataSource={cityList}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                this.editCity(record);
              },
            })}
          />
          <ModalConfirmDelete {...this.state.modalSettings} />
        </div>
      </>
    );
  }
}

const withData = inject((stores) => ({
  cityList: stores.CityStore.cityList,
  getCityData: stores.CityStore.getCityData,
  addCity: stores.CityStore.addCity,
  delCity: stores.CityStore.delCity,
  updCityName: stores.CityStore.updCityName,
}));

export default withData(City);
