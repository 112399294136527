import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import { toJS } from "mobx";
import { t } from 'i18next'

import { MdBlock } from "react-icons/md";

import { Table, Input, Button, Icon, Tooltip } from "antd";

import ModalSimple from "../ModalSimple";
import PassengersInfo from "./PassengersInfo";

import PassengersBlacklist from "./PassengersBlacklist";
import AddClient from "./AddClient";
import BonusAdd from "./BonusAdd";
import ExportToCsv from "../ExportToCsv";
import { takePageElements } from "shared/constants";

@observer
class Passengers extends Component {
  state = {
    showModal: false,
    showBonusModal: false,
    activeClientID: 0,
    showBlackList: false,
    showCreateClient: false,
    searchText: "",
    searchedColumn: "",
    selectedUsers: [],
    csvData: [],
    filterFirstName: "",
    filterLastName: "",
    filterPhoneNumber: "",
    filterBalanceFrom: "",
    filterBalanceTo: "",
    skip: 0,
    take: takePageElements,
  };

  applyFilters = () => {
    const filterOptions = {
      skip: this.state.skip,
      take: this.state.take,
      firstName: this.state.filterFirstName,
      lastName: this.state.filterLastName,
      phoneNumber: this.state.filterPhoneNumber,
      balanceFrom: this.state.filterBalanceFrom
        ? Number(this.state.filterBalanceFrom)
        : "",
      balanceTo: this.state.filterBalanceTo
        ? Number(this.state.filterBalanceTo)
        : "",
    };

    this.props.getPassengersData(filterOptions);
  };

  cancelFilters = () => {
    this.setState({
      filterFirstName: "",
      filterLastName: "",
      filterPhoneNumber: "",
      filterBalanceFrom: "",
      filterBalanceTo: "",
    });
    this.props.getPassengersData({
      skip: this.state.skip,
      take: this.state.take,
    });
  };

  csvHeaders = [
    { label: t('id'), key: "id" },
    { label: t('mobile_number'), key: "phone" },
    { label: t('name'), key: "firstName" },
    { label: t('last_name'), key: "lastName" },
  ];

  close = () => {
    this.setState({
      showModal: false,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeClientID: id,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Поиск
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t('clear')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  columns = [
    {
      title: t('id'),
      dataIndex: "id",
      ...this.getColumnSearchProps("id"),
    },
    {
      title: t('mobile_number'),
      dataIndex: "phone",
    },
    {
      title: t('name'),
      dataIndex: "firstName",
    },
    {
      title: t('last_name'),
      dataIndex: "lastName",
    },
    {
      title: t('country'),
      dataIndex: "country",
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        return (
          <Tooltip title={t('move_to_blacklist')}>
            <MdBlock
              onClick={() => {
                this.close();
                this.props.block(record.id);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getPassengersData({
      skip: this.state.skip,
      take: this.state.take,
    });
  }

  chgInp = (evt) => {
    if (
      evt.target.name === "filterBalanceFrom" ||
      evt.target.name === "filterBalanceTo"
    ) {
      const numberPattern = new RegExp(/^-?\d*\.?\d*$/);
      const regexpResult = numberPattern.test(evt.target.value);

      if (!regexpResult) {
        return;
      }
    }
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  chgBlacklistStatus = () => {
    this.setState({
      showBlackList: !this.state.showBlackList,
    });
  };

  closeCreateClient = () => {
    this.setState({
      showCreateClient: false,
    });
  };

  openCreateClient = () => {
    this.setState({
      showCreateClient: true,
    });
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const usersIds = selectedRows.map((item) => item.id);
      this.setState({
        selectedUsers: usersIds,
      });
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  setBonus = () => {
    this.setState({
      showBonusModal: true,
    });
    console.log(this.state.selectedUsers);
  };

  closeBonus = () => {
    this.setState({
      showBonusModal: false,
    });
  };

  render() {
    const { passengersList } = this.props;
    const csvData = toJS(passengersList);

    return (
      <div>
        <div className="row">
          <div className="col-8" style={{ paddingBottom: "20px" }}>
            {this.state.showBlackList ? (
              <button
                className="btn btn-primary"
                onClick={this.chgBlacklistStatus}
              >
                {t('all_users')}
              </button>
            ) : (
              <button
                className="btn btn-warning"
                onClick={this.chgBlacklistStatus}
              >
                {t('users_blacklist')}
              </button>
            )}
            <button
              className="btn btn-primary"
              onClick={this.openCreateClient}
              style={{ marginLeft: "20px" }}
            >
              {t('create_client')}
            </button>
            {this.state.selectedUsers.length > 0 ? (
              <button
                className="btn btn-secondary"
                onClick={this.setBonus}
                style={{ marginLeft: "20px" }}
              >
                {t('impose_bonuses_without_object')}
              </button>
            ) : null}
          </div>
          <div className="col-4 text-right">
            {passengersList.length > 0 ? (
              <ExportToCsv
                csvHeaders={this.csvHeaders}
                csvData={
                  this.state.csvData.length > 0
                    ? this.state.csvData
                    : passengersList
                }
                csvFileName="clients.csv"
              />
            ) : null}
          </div>
        </div>
        {!this.state.showBlackList ? (
          <div className="row">
            <div className="col-3" style={{ paddingBottom: "20px" }}>
              <input
                name="filterFirstName"
                value={this.state.filterFirstName}
                className="form-control"
                onChange={this.chgInp}
                placeholder={t('name')}
              />
            </div>
            <div className="col-3" style={{ paddingBottom: "20px" }}>
              <input
                name="filterLastName"
                value={this.state.filterLastName}
                className="form-control"
                onChange={this.chgInp}
                placeholder={t('last_name')}
              />
            </div>
            <div className="col-3" style={{ paddingBottom: "20px" }}>
              <input
                name="filterPhoneNumber"
                value={this.state.filterPhoneNumber}
                className="form-control"
                onChange={this.chgInp}
                placeholder={t('mobile_number')}
              />
            </div>
            <div className="col-3" style={{ paddingBottom: "20px" }}>
              <input
                name="filterBalanceFrom"
                value={this.state.filterBalanceFrom}
                className="form-control"
                onChange={this.chgInp}
                style={{ maxWidth: "130px", float: "left" }}
                placeholder={t('balance_from')}
              />
              <input
                name="filterBalanceTo"
                value={this.state.filterBalanceTo}
                className="form-control"
                onChange={this.chgInp}
                style={{ maxWidth: "130px", float: "left", marginLeft: "10px" }}
                placeholder={t('balance_to')}
              />
            </div>
            <div className="col-3" style={{ paddingBottom: "20px" }}>
              <button
                className="btn btn-warning"
                onClick={this.applyFilters}
                style={{ marginRight: "10px" }}
              >
                {t('filter')}
              </button>
              <button
                className="btn btn-secondary"
                onClick={this.cancelFilters}
              >
                {t('clear')}
              </button>
            </div>
          </div>
        ) : null}
        {this.state.showBlackList ? (
          <PassengersBlacklist />
        ) : (
          <Table
            rowSelection={this.rowSelection}
            columns={this.columns}
            dataSource={passengersList}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                this.openModal(record.id);
              },
            })}
            onChange={(pagination, filters, sorter, extra) => {
              const filteredData = extra["currentDataSource"].map((item) => {
                return toJS(item);
              });
              this.setState({ csvData: filteredData });
            }}
          />
        )}

        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <PassengersInfo
              activeClientID={this.state.activeClientID}
              close={this.close}
            />
          </ModalSimple>
        ) : null}
        {this.state.showBonusModal ? (
          <ModalSimple close={this.closeBonus}>
            <BonusAdd
              selectedUsers={this.state.selectedUsers}
              close={this.closeBonus}
            />
          </ModalSimple>
        ) : null}
        {this.state.showCreateClient ? (
          <ModalSimple close={this.closeCreateClient}>
            <AddClient close={this.closeCreateClient} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  passengersList: stores.PassengersStore.passengersList.map((elem) => { if(!elem.country) {elem.country = 'UA'} ;return elem}),
  block: stores.PassengersStore.block,
  getPassengersData: stores.PassengersStore.getPassengersData,
}));

export default withData(Passengers);
