import React, { Component } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router";
import { ReactComponent as GpsProblemMenuIconWhite } from "../../assets/GpsProblemMenuIconWhite.svg";
import { ReactComponent as GpsProblemMenuIconBlack } from "../../assets/GpsProblemMenuIconBlack.svg";
import { ReactComponent as NewsMenuIcon } from "../../assets/NewsMenuIcon.svg";
import { ReactComponent as NewsMenuBlackIcon } from "../../assets/NewsMenuBlackIcon.svg";
import { ReactComponent as DriversMenuIcon } from "../../assets/DriversMenuIcon.svg";
import { ReactComponent as InspectionsMenuIcon } from "../../assets/InspectionsMenuIcon.svg";
import { ReactComponent as PassagersMenuIcon } from "../../assets/PassagersMenuIcon.svg";
import { ReactComponent as PassagersMenuBlackIcon } from "../../assets/PassagersMenuBlackIcon.svg";
import { ReactComponent as OrdersListMenuIcon } from "../../assets/OrdersListMenuIcon.svg";
import { ReactComponent as PaymentsMenuIcon } from "../../assets/PaymentsMenuIcon.svg";
import { ReactComponent as PaymentsMenuBlackIcon } from "../../assets/PaymentsMenuBlackIcon.svg";
import { ReactComponent as CarsMenuIcon } from "../../assets/CarsMenuIcon.svg";
import { ReactComponent as CarsMenuBlackIcon } from "../../assets/CarsMenuBlackIcon.svg";
import { ReactComponent as DirectoryMenuIcon } from "../../assets/DirectoryMenuIcon.svg";
import { ReactComponent as DirectoryMenuBlackIcon } from "../../assets/DirectoryMenuBlackIcon.svg";
import { ReactComponent as WithdrawalMenuBlackIcon } from "../../assets/WithdrawalMenuBlackIcon.svg";
import { ReactComponent as WithdrawalMenuIcon } from "../../assets/WithdrawalMenuIcon.svg";
import { ReactComponent as SupportMenuBlackIcon } from "../../assets/SupportMenuBlackIcon.svg";
import { ReactComponent as SupportMenuIcon } from "../../assets/SupportMenuIcon.svg";
import { ReactComponent as UsersMenuBlackIcon } from "../../assets/UsersMenuBlackIcon.svg";
import { ReactComponent as UsersMenuIcon } from "../../assets/UsersMenuIcon.svg";
import { ReactComponent as SettingsMenuBlackIcon } from "../../assets/SettingsMenuBlackIcon.svg";
import { ReactComponent as SettingsMenuIcon } from "../../assets/SettingsMenuIcon.svg";
import { ReactComponent as FAQMenuBlackIcon } from "../../assets/FAQMenuBlackIcon.svg";
import { ReactComponent as FAQMenuIcon } from "../../assets/FAQMenuIcon.svg";
import { ReactComponent as StatisticsMenuBlackIcon } from "../../assets/StatisticsMenuBlackIcon.svg";
import { ReactComponent as StatisticsMenuIcon } from "../../assets/StatisticsMenuIcon.svg";
import { TechReviewInfo } from "pages/InfoPage/types";
import { t } from "i18next";
import "./NavMenu.scss";

@observer
class NavMenu extends Component {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const expires = localStorage.getItem("expires");
    if (
      expires > Date.now() &&
      this.props.LoginFromStore.isLoggedIn === false
    ) {
      this.props.LoginFromStore.refreshWindow();
    } else {
      const redirect = !this.props.LoginFromStore.isLoggedIn ? (
        <Redirect to="/" />
      ) : (
        false
      );
      if (redirect) {
        return redirect;
      }
    }

    const currentPath = window.location.pathname;

    return (
      <div
        className={`nav-menu${this.state.isHovered ? ' expanded' : ''}`}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <ul>
          <Link to="/drivers" className={currentPath === '/drivers' || (currentPath === '/info-page' && this.props.apiInfo.special === TechReviewInfo.DRIVER_INFO) ? 'active' : ''}>
            <li>
              <div className="menu-icon">
                <DriversMenuIcon />
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('drivers')}
                </div>
              }
            </li>
          </Link>
          <Link to="/technical-inspections" className={currentPath === '/technical-inspections' || (currentPath === '/info-page' && this.props.apiInfo.special === TechReviewInfo.INSPECTION_INFO) ? 'active' : ''}>
            <li>
              <div className="menu-icon">
                <InspectionsMenuIcon />
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('technical_inspections')}
                </div>
              }
            </li>
          </Link>
          <Link to="/passengers" className={currentPath === '/passengers' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon">
                {currentPath === '/passengers'
                  ?
                  <PassagersMenuBlackIcon />
                  :
                  <PassagersMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('clients')}
                </div>
              }
            </li>
          </Link>
          <Link to="/orders" className={currentPath === '/orders' ? 'active-fill' : ''}>
            <li>
              <div className="menu-icon">
                <OrdersListMenuIcon />
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('orders')}
                </div>
              }
            </li>
          </Link>
          <Link to="/payments" className={currentPath === '/payments' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon">
                {currentPath === '/payments'
                  ?
                  <PaymentsMenuBlackIcon />
                  :
                  <PaymentsMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('payments_title')}
                </div>
              }
            </li>
          </Link>
          <Link to="/news" className={currentPath === '/news' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon">
                {currentPath === '/news'
                  ?
                  <NewsMenuBlackIcon />
                  :
                  <NewsMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('news')}
                </div>
              }
            </li>
          </Link>
          <Link to="/gps-problem" className={currentPath === '/gps-problem' ? 'active-stroke' : ''}>
            <li>
              <div className="menu-icon">
                {currentPath === '/gps-problem'
                  ?
                  <GpsProblemMenuIconBlack />
                  :
                  <GpsProblemMenuIconWhite />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('gps_problem')}
                </div>
              }
            </li>
          </Link>
          <Link to="/cars" className={currentPath === '/cars' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/cars'
                  ?
                  <CarsMenuBlackIcon />
                  :
                  <CarsMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('cars')}
                </div>
              }
            </li>
          </Link>
          <Link to="/catalog" className={currentPath === '/catalog' ? 'active-fill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/catalog'
                  ?
                  <DirectoryMenuBlackIcon />
                  :
                  <DirectoryMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('directory')}
                </div>
              }
            </li>
          </Link>
          <Link to="/cash-out" className={currentPath === '/cash-out' ? 'active-fill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/cash-out'
                  ?
                  <WithdrawalMenuBlackIcon />
                  :
                  <WithdrawalMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('withdrawal_requests_title')}
                </div>
              }
            </li>
          </Link>
          <Link to="/feedback" className={currentPath === '/feedback' ? 'active-fill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/feedback'
                  ?
                  <SupportMenuBlackIcon />
                  :
                  <SupportMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('support')}
                </div>
              }
            </li>
          </Link>
          <Link to="/users" className={currentPath === '/users' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/users'
                  ?
                  <UsersMenuBlackIcon />
                  :
                  <UsersMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('users')}
                </div>
              }
            </li>
          </Link>
          <Link to="/faq" className={currentPath === '/faq' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/faq'
                  ?
                  <FAQMenuBlackIcon />
                  :
                  <FAQMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('faq')}
                </div>
              }
            </li>
          </Link>
          <Link to="/statistics" className={currentPath === '/statistics' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/statistics'
                  ?
                  <StatisticsMenuBlackIcon />
                  :
                  <StatisticsMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('statistics')}
                </div>
              }
            </li>
          </Link>
          <Link to="/settings" className={currentPath === '/settings' ? 'active-nonfill' : ''}>
            <li>
              <div className="menu-icon-big">
                {currentPath === '/settings'
                  ?
                  <SettingsMenuBlackIcon />
                  :
                  <SettingsMenuIcon />
                }
              </div>
              {this.state.isHovered &&
                <div className="menu-text">
                  {t('settings')}
                </div>
              }
            </li>
          </Link>
        </ul>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  LoginFromStore: stores.LoginFromStore,
  apiInfo: stores.InfoPageStore.apiInfo,
}));

export default withData(NavMenu);