import React, { useState, useEffect } from "react";
import { Table, DatePicker } from "antd";
import { inject, observer } from "mobx-react";
import { t } from "i18next";

import ExportToCsv from "../ExportToCsv";

import "./statistics.scss";

const { RangePicker } = DatePicker;

const Statistics = observer(
  ({
    cityList,
    getCityData,
    clientsData,
    getClientsData,
    driversData,
    getDriversData,
    paymentsData,
    getPaymentsData,
    ordersData,
    getOrdersData,
    csvData,
  }) => {
    const [cityId, setCityId] = useState("");
    const [cityName, setCityName] = useState("");
    const [filterDateFrom, setFilterDateFrom] = useState(null);
    const [filterDateTo, setFilterDateTo] = useState(null);

    useEffect(() => {
      getCityData();
    }, [getCityData]);

    const csvHeaders = [
      { label: cityName ? cityName : "", key: "title" },
      {
        label: filterDateFrom
          ? `${filterDateFrom.format("YYYY-MM-DD")} - ${filterDateTo.format(
              "YYYY-MM-DD"
            )}`
          : "",
        key: "value",
      },
    ];

    const cityListOptions = cityList.map((item) => {
      return (
        <option value={item.id} key={item.id} cityname={item.name}>
          {item.name}
        </option>
      );
    });

    const driversColum = [
      {
        title: t('new_drivers'),
        dataIndex: "newDrivers",
      },
      {
        title: t('took_order_for_week'),
        dataIndex: "regularDrivers",
      },
      {
        title: t('car_with_parameters'),
        dataIndex: "carsNew",
      },
      {
        title: t('drivers_passed_inspections'),
        dataIndex: "carsTo",
      },
    ];

    const clientsColum = [
      {
        title: t('new_clients'),
        dataIndex: "newClient",
      },
      {
        title: t('regular_passengers'),
        dataIndex: "regularClient",
      },
    ];

    const ordersColum = [
      {
        title: t('orders_created'),
        dataIndex: "all",
      },
      {
        title: t('orders_accepted'),
        dataIndex: "accepted",
      },
      {
        title: t('orders_paid'),
        dataIndex: "paid",
      },
    ];

    const transactionsColum = [
      {
        title: t('paid_by_customers'),
        dataIndex: "incomeFromClients",
      },
      {
        title: t('commission'),
        dataIndex: "fees",
      },
      {
        title: t('fines_with_parameters'),
        dataIndex: "penalties",
      },
      {
        title: t('payments_to_drivers'),
        dataIndex: "outcomeToDrivers",
      },
    ];

    const chgCity = (evt) => {
      setCityId(evt.target.value);
      setCityName(
        evt.target.children[evt.target.selectedIndex].getAttribute("cityname")
      );
    };

    const chgDateRange = (date) => {
      if (date.length === 2) {
        setFilterDateFrom(date[0].startOf("day"));
        setFilterDateTo(date[1].endOf("day"));
      }
    };

    const getStatisticsInfo = () => {
      getClientsData(cityId, filterDateFrom, filterDateTo);
      getDriversData(cityId, filterDateFrom, filterDateTo);
      getOrdersData(cityId, filterDateFrom, filterDateTo);
      getPaymentsData(cityId, filterDateFrom, filterDateTo);
    };

    return (
      <>
        <div className="row">
          <div className="col-3">
            <select
              name="filterCity"
              className="form-control"
              value={cityId}
              placeholder={t('city')}
              onChange={chgCity}
            >
              <option value="" key="city0">
                - {t('city')} -
              </option>
              {cityListOptions}
            </select>
          </div>
          <div className="col-3">
            <RangePicker
              placeholder={[t('create_date_from'), t('create_date_to')]}
              className="form-control"
              format="YYYY/MM/DD"
              value={[filterDateFrom, filterDateTo]}
              onChange={chgDateRange}
              allowClear={false}
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                getStatisticsInfo();
              }}
              style={{ marginRight: "10px", color: "#fefefe" }}
            >
              {t('apply')}
            </button>
          </div>
          <div className="col-3 text-right">
            {paymentsData.length > 0 &&
            driversData.length > 0 &&
            clientsData.length > 0 &&
            ordersData.length > 0 ? (
              <ExportToCsv
                csvData={csvData}
                csvHeaders={csvHeaders}
                csvFileName="statistics.csv"
              />
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>{t('drivers')}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table columns={driversColum} dataSource={driversData} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>{t('clients')}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table columns={clientsColum} dataSource={clientsData} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>{t('orders')}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table columns={ordersColum} dataSource={ordersData} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>{t('transactions')}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table columns={transactionsColum} dataSource={paymentsData} />
          </div>
        </div>
      </>
    );
  }
);

const withData = inject((stores) => ({
  getCityData: stores.CityStore.getCityData,
  cityList: stores.CityStore.cityList,
  clientsData: stores.StatisticsStore.clientsData,
  getClientsData: stores.StatisticsStore.getClientsData,
  driversData: stores.StatisticsStore.driversData,
  getDriversData: stores.StatisticsStore.getDriversData,
  paymentsData: stores.StatisticsStore.paymentsData,
  getPaymentsData: stores.StatisticsStore.getPaymentsData,
  ordersData: stores.StatisticsStore.ordersData,
  getOrdersData: stores.StatisticsStore.getOrdersData,
  csvData: stores.StatisticsStore.csvData,
}));

export default withData(Statistics);
