import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../config";
import { takePageElements } from "shared/constants";

class FeedbackStore {
  @observable feedbackList = [];
  @observable activeFeedback = [];
  @observable isError = false;

  getFeedbackData = (who) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}${who}/support`,
        { take: takePageElements, skip: 0 },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.feedbackList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFeedbackInfo = (id, who) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}${who}/${id}/support/chat`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.activeFeedback = [...response.data.data].reverse();
          const myDiv = document.getElementById('scroll-chat');
          myDiv.scrollIntoView()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  sendFeedbackMsg = (who, userId, content) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(
        `${apiIdentity}${who}/${userId}/support/chat`,
        {
          userId,
          topic: " ",
          senderId: localStorage.getItem("adminId"),
          content,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          const currentInfo = [...this.activeFeedback];
          currentInfo.push(response.data.data);
          this.activeFeedback = [...currentInfo];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  markedAsSee = (who) => {
    const accessToken = localStorage.getItem("accessToken");
    this.activeFeedback.map((item) => {
      if (item.isNew && !item.adminUserId) {
        axios
          .put(
            `${apiIdentity}${who}/${item.userId}/support/chat/${item.id}`,
            { id: item.userId, messageId: item.id },
            { headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => {})
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    });
  };
}

export default new FeedbackStore();
