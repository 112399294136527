import React from 'react'
import { t } from 'i18next';
import { ReactComponent as NoneInfoIcon } from '../../assets/NoneInfoIcon.svg'
import { ReactComponent as TrashIcon } from '../../assets/TrashIcon.svg'
import { ReactComponent as CheckLineIcon } from '../../assets/CheckLineIcon.svg'
import { ReactComponent as ClearIcon } from '../../assets/ClearIcon.svg'
import { TechReviewInfo } from 'pages/InfoPage/types';

export const ActionTableModal = ({ elem, delBtn, unblockBtn, blockBtn, special, inactiveBtn, activeBtn }) => {
    switch (special) {
        case TechReviewInfo.INSPECTION_INFO:
            return (
                <div className='modal_options_actions modal_top'>
                    {elem.status === "Схвалений" || elem.status === "Одобрен"
                        ?
                        <div className='single_element black_stroke' onClick={inactiveBtn}>
                            <ClearIcon />
                            <p>{t('rejected_third')}</p>
                        </div>
                        :
                        <div className='single_element black_stroke' onClick={activeBtn}>
                            <CheckLineIcon />
                            <p>{t('aprove')}</p>
                        </div>
                    }
                </div>
            )
        case TechReviewInfo.DRIVER_INFO:
            return (
                <div className='modal_options_actions'>
                    <div className='single_element' onClick={delBtn}>
                        <TrashIcon />
                        <p>{t('delete')}</p>
                    </div>
                    {elem.isBlocked ?
                        <div className='single_element' onClick={unblockBtn}>
                            <NoneInfoIcon />
                            <p>{t('unblock')}</p>
                        </div>
                        :
                        <div className='single_element' onClick={blockBtn}>
                            <NoneInfoIcon />
                            <p>{t('add_block')}</p>
                        </div>
                    }
                </div>
            )
        default:
            return null
    }
}