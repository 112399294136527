import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import { inject, observer } from "mobx-react";
import { t } from "i18next";
import styles from "./styles.module.scss";
import { useFormik } from "formik";
import { CheckOutlined } from "@ant-design/icons";
import { NewsSchema } from "./validation";
import NewsStore from "../../mobx";
import { toJS } from "mobx";

const NewsForm = (props) => {
  const {
    newsListData,
    initialValues,
    onSubmitForm,
    locationList,
    getRegions,
    submitButtonText,
  } = props;
  const [validation, setValidation] = useState(false);
  const formik = useFormik({
    validationSchema: NewsSchema,
    validateOnChange: validation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmitForm(values);
    },
  });


  const setRegion = (value) => {
    const regionsValue = formik.values.regionCode;

    if (regionsValue.includes(value)) {
      return formik.setFieldValue(
        "regionCode",
        regionsValue.filter((item) => item !== value)
      );
    }
    formik.setFieldValue("regionCode", [...regionsValue, value]);
  };
  const updateRegion = (branchId, audience) => {
    formik.setFieldValue("regionCode", []);
    getRegions(branchId, audience);
  };

  const audienceSelect = (
    <Radio.Group
      name="target"
      onChange={(event) => {
        updateRegion(event.target.value, formik.values.branchId);
        formik.handleChange(event);
      }}
      value={formik.values.target}
    >
      <Radio value={0}>{t("drivers")}</Radio>
      <Radio value={1}>{t("clients")}</Radio>
    </Radio.Group>
  );

  const branchIdRadio = (
    <Radio.Group
      name="branchId"
      onChange={(event) => {
        formik.setFieldValue("regionCode", []);
        NewsStore.getRegions(formik.values.target, event.target.value);
        formik.handleChange(event);
      }}
      value={formik.values.branchId}
    >
      <Radio value={1}>Carma</Radio>
      <Radio value={2}>LTtaxi</Radio>
    </Radio.Group>
  );

  const setAllRegions = () => {
    if (locationList.length === formik.values.regionCode.length) {
      return formik.setFieldValue("regionCode", []);
    }

    formik.setFieldValue(
      "regionCode",
      locationList.map((item) => item.regionCode)
    );
  };

  const allRegionsCount = locationList.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    0
  );
  const regionSelect = locationList && (
    <>
      <ul className={styles.regionSelect}>
        <li onClick={setAllRegions} value={t("all")}>
          <span>
            {t("all")} ({allRegionsCount})
          </span>
          {locationList.length === formik.values.regionCode.length && (
            <CheckOutlined />
          )}
        </li>
        {locationList.map((item, index) => (
          <li
            key={`${item.regionCode}_${index}`}
            value={item.value}
            onClick={() => setRegion(item.regionCode)}
          >
            <span>
              {t(item.regionCode)} ({item.count})
            </span>
            {formik.values.regionCode.includes(item.regionCode) && (
              <CheckOutlined />
            )}
          </li>
        ))}
      </ul>
      {formik.errors?.regionCode && (
        <span style={{ color: "red" }}>{t(formik.errors?.regionCode)}</span>
      )}
    </>
  );

  const inputs = [
    { topicTitle: t("audience"), customComponent: audienceSelect },
    { topicTitle: "Програма", customComponent: branchIdRadio },
    { topicTitle: "Регіон", customComponent: regionSelect },
    // Languages
    ...newsListData,
  ];

  const newInputData = (item) => {
    const currentNewsIndex = formik.values.newsExtendeds.findIndex((news) => {
      return news.language === item.language;
    });

    return (
      <>
        {formik.values.newsExtendeds[currentNewsIndex] && (
          <input
            name={`newsExtendeds[${currentNewsIndex}].title`}
            value={formik.values.newsExtendeds[currentNewsIndex].title}
            placeholder={item.title.placeholder}
            onChange={formik.handleChange}
          />
        )}
        {formik.errors?.newsExtendeds?.[currentNewsIndex] && (
          <span style={{ color: "red" }}>
            {t(formik.errors?.newsExtendeds[currentNewsIndex]?.title)}
          </span>
        )}
        {formik.values.newsExtendeds[currentNewsIndex] && (
          <>
            <textarea
              name={`newsExtendeds[${currentNewsIndex}].content`}
              value={formik.values.newsExtendeds[currentNewsIndex].content}
              placeholder={item.content.placeholder}
              onChange={formik.handleChange}
              multiple
            />
            {formik.errors?.newsExtendeds?.[currentNewsIndex] && (
              <span style={{ color: "red" }}>
                {t(formik.errors?.newsExtendeds[currentNewsIndex]?.content)}
              </span>
            )}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    getRegions(formik.values.target, formik.values.branchId);
  }, []);

  return (
    <form
      onSubmit={(event) => {
        setValidation(true);
        formik.handleSubmit(event);
      }}
      className={styles.createNewWrap}
    >
      {inputs.map((item, index) => {
        return (
          <li className={styles.newInputWrap} key={`${item.name}_${index}`}>
            {item.topicTitle && (
              <span className={styles.topicTitle}>{item.topicTitle}</span>
            )}
            {item.customComponent ? item.customComponent : newInputData(item)}
          </li>
        );
      })}
      <button type="submit" className={styles.createButton}>
        {submitButtonText}
      </button>
    </form>
  );
};

export default NewsForm;
