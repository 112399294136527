import React, { Component } from 'react'
import NavMenu from '../../components/NavMenu'
import FAQ from '../../components/FAQ'
import { t } from 'i18next'

export default class FAQPage extends Component {
    render() {
        return (<div className="row">
            <div className="col-1">
                <NavMenu />
            </div>
            <div className="col-11">
                <div className="row">
                    <div className="col">
                        <h3>{t('faq_title')}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <FAQ />
                    </div>
                </div>
            </div>
        </div>)
    }
}
