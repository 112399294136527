import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { t } from "i18next";
import ModalConfirmDelete from "../ModalConfirmDelete";

import { Table } from "antd";

import ModalSimple from "../ModalSimple";
import InfoFAQ from "./InfoFAQ";
import AddFAQ from "./AddFAQ";

@observer
class FAQ extends Component {
  state = {
    showModal: false,
    showCreateFAQ: false,
    activeFAQID: 0,
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  };

  close = () => {
    this.setState({
      showModal: false,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeFAQID: id,
    });
  };

  columns = [
    {
      title: t('id'),
      dataIndex: "id",
    },
    {
      title: t('title'),
      dataIndex: "title",
    },
    {
      title: t('audience'),
      dataIndex: "target",
      render: (text, record) => {
        if (text === 2) {
          return <>{t('all')}</>;
        } else if (text === 0) {
          return <>{t('drivers')}</>;
        } else {
          return <>{t('clients')}</>;
        }
      },
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        return (
          <span
          onClick={() => {
            this.setState({ modalSettings : {
              onYes : () => this.props.delFAQ(record.id),
              onClose : () => this.setState({modalSettings : {isOpen : false}}),
              isOpen : true,
              text : t('confirm_delete')
            }})
          }}
            className="btn btn-warning"
          >
            {t('delete')}
          </span>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getFAQSData();
  }

  closeCreateFAQ = () => {
    this.setState({
      showCreateFAQ: false,
    });
  };

  openCreateFAQ = () => {
    this.setState({
      showCreateFAQ: true,
    });
  };

  render() {
    return (
      <div>
        <div className="row">
        <ModalConfirmDelete {...this.state.modalSettings} />
          <div className="col-12" style={{ paddingBottom: "20px" }}>
            <button className="btn btn-primary" onClick={this.openCreateFAQ}>
              {t('create')}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={this.props.faqList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <InfoFAQ faqID={this.state.activeFAQID} close={this.close} />
          </ModalSimple>
        ) : null}
        {this.state.showCreateFAQ ? (
          <ModalSimple close={this.closeCreateFAQ}>
            <AddFAQ close={this.closeCreateFAQ} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  faqList: stores.FAQStore.faqList,
  getFAQSData: stores.FAQStore.getFAQSData,
  delFAQ: stores.FAQStore.delFAQ,
}));

export default withData(FAQ);
