import { t } from "i18next";
import React from "react";

const AddMoneyDriver = (props) => {
  const { balance, changedBalance, onChangeChangedBalance, onAddBalance } =
    props;

  console.log("here");

  return (
    <div className="drivers_add_bonuses">
      <div className="add_bonus_block">
        <div className="driver_input">
          <p>
            <span>*</span> {t("total_money")} {balance}
          </p>
          <input
            placeholder={t("replenish_balance")}
            value={changedBalance}
            onChange={onChangeChangedBalance}
          />
        </div>
        <button
          className={`add_bonus_button ${
            !changedBalance ? "disabled_button" : ""
          }`}
          onClick={onAddBalance}
        >
          {t("change")}
        </button>
      </div>
    </div>
  );
};

export default AddMoneyDriver;
