import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { getNameHeader } from './store';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import "./styles.scss";
import { ReactComponent as ChevronRightIcon } from '../../assets/ChevronRightIcon.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/ChevronLeftIcon.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/ChevronDownIcon.svg';
import { ReactComponent as ChevronUpIcon } from '../../assets/ChevronUpIcon.svg';
import { ReactComponent as CheckLineIcon } from '../../assets/CheckLineIcon.svg';
import HeaderTable from './components/HeaderTable';
import ReactPaginate from 'react-paginate';
import LoadingElement from '../LoadingElement';
import { RowTable } from './components/RowTable';
import { TechReviewInfo } from 'pages/InfoPage/types';
import typesTable from './constants/typesTable';

const options = [20, 30, 40];

const Table = ({ getData, data, totalCount, special, delDriver, blockDriver, unblockDriver, getUsersNameForHeader, getLengthForHeader, openInfoPage, changeLengthForHeader, headerName, keys, showKeys, save, chgInspectionStatus, cityList, getCityData, addCity, delCity, updCityName, updateDriver, brandList, brandsWithModels, carClassList, getCarClassData }) => {

  const [settingsShow, setSettingsShow] = useState(typesTable[showKeys].showKeys)
  const [isCanEdit, setCanEdit] = useState(false)
  const [editElement, setEditElement] = useState({
    key: null,
    id: '',
    oldValue: "",
    newValue: '',
    elem: null
  })

  const [search, setSearch] = useState('');
  const [results, setResults] = useState(data)
  const [sortHeader, setSortHeader] = useState({
    searchText: '',
    "firstName": "",
    "lastName": "",
    "phoneNumber": "",
    "callSign": "",
    "balanceFrom": "",
    "balanceTo": "",
    "commissionFrom": "",
    "commissionTo": "",
    "ratingFrom": "",
    "ratingTo": "",
    "isActive": '',
    "city": ''
  })
  const [page, setPage] = useState(() => {
    const storedCountElem = sessionStorage.getItem('pageCountElem');
    return {
      page: 0,
      countElem: storedCountElem ? Number(storedCountElem) : 20,
      pageCount: 10
    };
  });
  const [selectedPageSize, setSelectedPageSize] = useState(page.countElem);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory();

  useEffect(() => {
    if (isOpen) {
      const data = JSON.parse(sessionStorage.getItem(showKeys))
      setIsOpen(false)
      if (data === null) {
        return;
      }
      setSettingsShow(data);
    } else {
      sessionStorage.setItem(showKeys, JSON.stringify(settingsShow));
    }
  }, [settingsShow])

  useEffect(() => {
    setResults(data)
  }, [data])

  useEffect(() => {
    changeLengthForHeader(totalCount)
    return () => changeLengthForHeader(null)
  }, [totalCount])

  useEffect(() => {
    const pageCount = Math.ceil(totalCount / page.countElem)
    setPage({ ...page, pageCount })
  }, [totalCount, page.countElem])

  useEffect(() => {
    const skip = page.page * page.countElem;
    getData({ skip, take: page.countElem, ...sortHeader })
  }, [page.countElem, page.page, sortHeader])

  useEffect(() => {
    sessionStorage.setItem('pageCountElem', page.countElem.toString());
  }, [page.countElem]);

  const handlePageSizeChange = (size) => {
    setSelectedPageSize(size);
    setPage({ ...page, countElem: size, page: 0 });
    setIsDropdownOpen(false);
  };

  const selectedText = (t) => {
    const text = '' + t;
    if (search.trim() === '') return text;
    const startSearch = text.indexOf(search);
    if (startSearch === -1) return text;
    const endSearch = startSearch + search.length;

    const startText = text.substring(0, startSearch);
    const selectedText = text.substring(startSearch, endSearch)
    const endText = text.substring(endSearch);

    return <>{startText}<span className="selectedText" style={{ background: '#FFE688' }}>{selectedText}</span>{endText}</>
  }

  const searchDatas = (e) => {
  }

  const changePages = ({ selected }) => {
    setPage({ ...page, page: selected })

    const count = page.countElem;
    const p = selected;
    const to = count * p;
  }

  const openInfo = useCallback((id, e, data) => {
    if (isCanEdit) return;
    if (e.target.closest('.dontOpenInfo')) return;
    openInfoPage(id, special, headerName, data)
    switch (special) {
      case TechReviewInfo.DRIVER_INFO:
        getUsersNameForHeader(data.callSign);
        break;
      case TechReviewInfo.INSPECTION_INFO:
        getUsersNameForHeader(data.carBrandAndModel);
        break;
      default:
        break;
    }
    history.push('/info-page');

  }, [isCanEdit, special, headerName])

  const memoHeader = useMemo(() => {
    const headers = keys.map((e, i) => settingsShow[e] && <div className='table_header_item' key={e}><p>{t(getNameHeader(e))}</p></div>)
    return <>
      {headers}
      <div className='table_header_item dontOpenInfo'></div>
    </>
  }, [keys, settingsShow]);

  const memoData = useMemo(() => results.map((elem, i) => {
    return <RowTable elem={elem} keys={keys} settingsShow={settingsShow} key={elem.id} openInfo={openInfo} editElement={editElement} selectedText={selectedText} isCanEdit={isCanEdit} setEditElement={setEditElement} delDriver={delDriver} getData={getData} page={page} sortHeader={sortHeader} blockDriver={blockDriver} unblockDriver={unblockDriver} special={special} chgInspectionStatus={chgInspectionStatus} />
  }), [results, keys, settingsShow, editElement, isCanEdit, special]);

  if (isLoading) return <LoadingElement />

  return (
    <div>
      <HeaderTable save={save} searchText={search} setSearchText={setSearch} special={special} isCanEdit={isCanEdit} sortHeader={sortHeader} setSortHeader={setSortHeader} setCanEdit={setCanEdit} settingsShow={settingsShow} editElement={editElement} setEditElement={setEditElement} keys={keys} results={results} setSettingsShow={setSettingsShow} search={search} setSearch={searchDatas} getNameHeader={getNameHeader} cityList={cityList} getCityData={getCityData} addCity={addCity} delCity={delCity} updCityName={updCityName} updateDriver={updateDriver} brandList={brandList} brandsWithModels={brandsWithModels} carClassList={carClassList} getCarClassData={getCarClassData} getData={getData} page={page} />
      {results.length > 0 ? (
        <>
          <div className='table_container'>
            <div className='table_header'>
              {memoHeader}
            </div>
            <div className='main_datas'>
              {memoData}
            </div>
          </div>
          <div className='pagination-panel'>
            <ReactPaginate
              forcePage={page.page}
              nextLabel={<ChevronRightIcon />}
              onPageChange={changePages}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={page.pageCount}
              previousLabel={<ChevronLeftIcon />}
              pageClassName="pagination-page-item"
              pageLinkClassName="pagination-page-item"
              previousClassName={`pagination-arrow-item ${page.page === 0 ? 'pagination-arrow-item-disabled' : ''}`}
              previousLinkClassName={`pagination-arrow-item ${page.page === 0 ? 'pagination-arrow-item-disabled' : ''}`}
              nextClassName={`pagination-arrow-item ${page.page === page.pageCount - 1 ? 'pagination-arrow-item-disabled' : ''}`}
              nextLinkClassName={`pagination-arrow-item ${page.page === page.pageCount - 1 ? 'pagination-arrow-item-disabled' : ''}`}
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="break-link-class"
              containerClassName="pagination"
              activeClassName="pagination-page-item-active"
              activeLinkClassName="pagination-page-item-active"
              renderOnZeroPageCount={null}
            />
            <div className='select-pages-count'>
              <p className='select-pages-count-title'>{t('show')}</p>
              <div className='change-page-size-block'>
                <div className='change-page-selected-option' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <p>{selectedPageSize}</p>
                  {isDropdownOpen ?
                    <ChevronUpIcon /> :
                    <ChevronDownIcon />
                  }
                </div>
                {isDropdownOpen && (
                  <div className='change-page-options'>
                    {options.map((c) => (
                      <div
                        key={c}
                        className={`change-page-single-option ${selectedPageSize === c ? 'change-page-single-option-active' : ''}`}
                        onClick={() => handlePageSizeChange(c)}
                      >
                        <p>{c}</p>
                        {selectedPageSize === c && <CheckLineIcon />}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='no-results-message'>
          <p>{t('no_data_results')}</p>
        </div>
      )}
    </div>
  );
};

export default Table;
