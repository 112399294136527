import React, { useState } from 'react'
import { ElemTable } from '../ElemTable';
import { ReactComponent as DotsIcon } from '../../../../assets/DotsIcon.svg';
import { t } from 'i18next';
import ConfirmActionModal from 'components/ConfirmActionModal';
import ModalChangesNotification from 'components/ModalChangesNotification';
import { ActionTableModal } from 'components/ActionTableModal';

const addLeadingZero = (value) => {
  const stringValue = value.toString();
  return stringValue.length === 1 ? `0${stringValue}` : stringValue;
};

const formatDateTime = (dateTimeString) => {
  if (dateTimeString) {
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString('en-US', options);

    const [datePart, timePart] = formattedDate.split(', ');
    const [month, day, year] = datePart.split('/');
    const [hour, minute] = timePart.split(':');

    const formattedDay = addLeadingZero(day);
    const formattedMonth = addLeadingZero(month);

    return `${formattedDay}.${formattedMonth}.${year}; ${hour}:${minute}`;
  } else {
    return '';
  }
};

export const RowTable = ({ elem, keys, settingsShow, openInfo, editElement, selectedText, isCanEdit, setEditElement, delDriver, unblockDriver, blockDriver, getData, page, sortHeader, special, chgInspectionStatus }) => {

  const [open, setModal] = useState(false)

  const [modalConfirmAction, setModalConfirmAction] = useState({
    onAction: () => { },
    onClose: () => setModalConfirmAction({ isOpen: false }),
    isOpen: false,
    text: '',
  });

  const [modalNotification, setModalNotification] = useState({
    success: false,
    onClose: () => setModalNotification({ isOpen: false }),
    isOpen: false,
    text: '',
  });

  const delBtn = () => {
    const onDelete = () => {
      delDriver(elem.id)
        .then(res => {
          if (res.status === 204) {
            setModalNotification({
              success: true,
              onClose: () => setModalNotification({ isOpen: false }),
              isOpen: true,
              text: t('driver_delete_success'),
            });
            const skip = page.page * page.countElem;
            getData({ skip, take: page.countElem, ...sortHeader });
          }
        })
        .catch(error => {
          setModalNotification({
            success: false,
            onClose: () => setModalNotification({ isOpen: false }),
            isOpen: true,
            text: t('driver_delete_not_success'),
          });
        });
    };
    setModalConfirmAction({
      onAction: onDelete,
      onClose: () => setModalConfirmAction({ isOpen: false }),
      isOpen: true,
      text: t('confirm_delete'),
    });
  };

  const blockBtn = () => {
    const onBlock = () => {
      blockDriver(elem.id)
        .then(res => {
          if (res.status === 204) {
            setModalNotification({
              success: true,
              onClose: () => setModalNotification({ isOpen: false }),
              isOpen: true,
              text: t('block_success'),
            });
            const skip = page.page * page.countElem;
            getData({ skip, take: page.countElem, ...sortHeader });
          }
        })
        .catch(error => {
          setModalNotification({
            success: false,
            onClose: () => setModalNotification({ isOpen: false }),
            isOpen: true,
            text: t('block_not_success'),
          });
        });
    };
    setModalConfirmAction({
      onAction: onBlock,
      onClose: () => setModalConfirmAction({ isOpen: false }),
      isOpen: true,
      text: t('confirm_block'),
    });
  };

  const unblockBtn = () => {
    const onBlock = () => {
      unblockDriver(elem.id)
        .then(res => {
          if (res.status === 204) {
            setModalNotification({
              success: true,
              onClose: () => setModalNotification({ isOpen: false }),
              isOpen: true,
              text: t('unblock_success'),
            });
            const skip = page.page * page.countElem;
            getData({ skip, take: page.countElem, ...sortHeader });
          }
        })
        .catch(error => {
          setModalNotification({
            success: false,
            onClose: () => setModalNotification({ isOpen: false }),
            isOpen: true,
            text: t('unblock_not_success'),
          });
        });
    };
    setModalConfirmAction({
      onAction: onBlock,
      onClose: () => setModalConfirmAction({ isOpen: false }),
      isOpen: true,
      text: t('confirm_unblock'),
    });
  };

  const activeBtn = () => {
    const onAclive = () => {
      const inspectionInfo = {
        id: elem.id,
        status: 1,
        statusComment: "",
      }
      chgInspectionStatus(inspectionInfo.id, inspectionInfo.status, inspectionInfo.statusComment)
      .then((success) => {
        if (success) {
          setModalNotification({
            success: true,
            onClose: () => setModalNotification({ isOpen: false }),
            isOpen: true,
            text: t('status_change'),
          });
        }
      })
      .catch((error) => {
        console.log(error)
        setModalNotification({
          success: false,
          onClose: () => setModalNotification({ isOpen: false }),
          isOpen: true,
          text: t('status_not_change'),
        });
      });
    };
    setModalConfirmAction({
      onAction: onAclive,
      onClose: () => setModalConfirmAction({ isOpen: false }),
      isOpen: true,
      text: t('changes_status'),
    });
  };

  const inactiveBtn = () => {
    const onInactive = () => {
      const inspectionInfo = {
        id: elem.id,
        status: 2,
        statusComment: "",
      }
      chgInspectionStatus(inspectionInfo.id, inspectionInfo.status, inspectionInfo.statusComment)
      .then((success) => {
        if (success) {
          setModalNotification({
            success: true,
            onClose: () => setModalNotification({ isOpen: false }),
            isOpen: true,
            text: t('status_change'),
          });
        }
      })
      .catch((error) => {
        console.log(error)
        setModalNotification({
          success: false,
          onClose: () => setModalNotification({ isOpen: false }),
          isOpen: true,
          text: t('status_not_change'),
        });
      });
    };
    setModalConfirmAction({
      onAction: onInactive,
      onClose: () => setModalConfirmAction({ isOpen: false }),
      isOpen: true,
      text: t('changes_status'),
    });
  };

  return (
    <>
      <div className='table_colums' key={elem.id} onClick={(e) => openInfo(elem.id, e, elem)}>
        {keys.map(key => {
          let text;
          if (key === 'createdAt') {
            text = formatDateTime(elem[key]);
          } else if (key === 'isActivated') {
            text = elem[key] ? t('active') : t('not_active');
          } else {
            text = elem[key];
          }

          const finalText = typeof text === 'string' && text.includes("SmartTaxi.MicroServices") ? "ОШИБКА БЭКА" : text;

          return settingsShow[key] && <ElemTable text={finalText} isCanEdit={isCanEdit} selectedText={selectedText} key={elem.id + key} elem={elem} keyElem={key} editElement={editElement} setEditElement={setEditElement} />;
        })}
        <div className={'table_colums-item dontOpenInfo ' + (editElement.id === elem.id ? 'active-edit' : '')} onClick={() => setModal(prevModal => !prevModal)}>
          <DotsIcon />
          {open && <ActionTableModal elem={elem} delBtn={delBtn} unblockBtn={unblockBtn} blockBtn={blockBtn} special={special} activeBtn={activeBtn} inactiveBtn={inactiveBtn} />}
        </div>
      </div>
      {modalConfirmAction.isOpen && <ConfirmActionModal {...modalConfirmAction} />}
      {modalNotification.isOpen && <ModalChangesNotification {...modalNotification} />}
    </>
  )
}
