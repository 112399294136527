import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { MdDeleteForever } from "react-icons/md";
import ModalConfirmDelete from "../../ModalConfirmDelete"

import { Table } from "antd";
import AddEditCarClass from "./AddEditCarClass";
import { t } from "i18next";

@observer
class CarClass extends Component {
  state = {
    editId: null,
    editClassName: null,
    editPriority: null,
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  };

  carColumns = [
    {
      title: t('class'),
      dataIndex: "name",
    },
    {
      title: t('priority'),
      dataIndex: "priority",
    },
    {
      title: t('actions'),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <MdDeleteForever onClick={() => this.delBtn(record)} />
      ),
    },
  ];

  componentDidMount() {
    this.props.getCarClassData();
  }

  delBtn = (record) => {
    const recordId = record.id;
    this.setState({
      modalSettings: {
        onYes: () => this.props.delClass(recordId),
        onClose: () => this.setState({ modalSettings: { isOpen: false } }),
        isOpen: true,
        text: t('confirm_delete')
      }
    });
  };

  editClass = (record) => {
    this.setState({
      editId: record.id,
      editClassName: record.name,
      editPriority: record.priority,
    });
  };

  clearForm = () => {
    this.setState({
      editId: null,
      editClassName: null,
      editPriority: null,
    });
  };

  render() {
    const classList = this.props.classList;
    const { editId, editClassName, editPriority } = this.state;
    return (
      <>
        <AddEditCarClass
          id={editId}
          carClassName={editClassName}
          priority={editPriority}
          clearForm={this.clearForm}
        />

        <div>
          <Table
            columns={this.carColumns}
            dataSource={classList}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                this.editClass(record);
              },
            })}
          />
        </div>
        <ModalConfirmDelete {...this.state.modalSettings} />
      </>
    );
  }
}

const withData = inject((stores) => ({
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
  addCarClass: stores.CarsClassStore.addCarClass,
  delClass: stores.CarsClassStore.delClass,
  updClass: stores.CarsClassStore.updClass,
}));

export default withData(CarClass);
