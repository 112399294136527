import React, { Component } from 'react'
import LoginForm from '../../components/LoginForm'

export default class LoginPage extends Component {
    render() {
        return (            
        <div className="row">
            <div className="col-12">
                <LoginForm />
            </div>
        </div>
        )
    }
}
