import { observable, onReactionError } from "mobx";
import axios from "axios";

import { paymentGate } from "../../config";

class GateHistoryStore {
  @observable historyRecords = [];
  @observable isError = false;
  @observable isLoading = false;
  @observable currentTransaction = "";

  getHistory = (dateBegin, dateEnd) => {
    this.isLoading = true;
    console.log("===", dateBegin.format("X"), dateEnd.format("X"));

    axios
      .post(`${paymentGate}v1/gatehistory`, {
        dateBegin: Number(dateBegin.format("X")),
        dateEnd: Number(dateEnd.format("X")),
      })
      .then((response) => {
        if (
          response.data.status === "Success" &&
          response.data.result.reason &&
          response.data.result.reason === "Ok"
        ) {
          this.historyRecords = response.data.result.transactionList;
          this.isError = false;
          this.isLoading = false;
        } else {
          this.historyRecords = [];
          this.isError = response.data.result.reason;
          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.isError = err.toString();
        this.isLoading = false;
      });
  };

  refound = (orderReference, amount) => {
    axios
      .post(`${paymentGate}v1/refund`, { orderReference, amount })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "Success") {
          this.isError = false;
        } else {
          this.isError = JSON.stringify(response.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
        this.isError = err.toString();
      });
  };

  getTransactionInfo = (orderReference) => {
    this.currentTransaction = "";
    this.historyRecords.filter((item) => {
      if (item.orderReference === orderReference) {
        this.currentTransaction = JSON.stringify(item);
        return true;
      } else {
        return false;
      }
    });
    console.log(this.currentTransaction);
  };
}

export default new GateHistoryStore();
