import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { t } from "i18next";
import { toJS } from "mobx";

import { Table } from "antd";

import ModalSimple from "../ModalSimple";
import ModalConfirmDelete from "../ModalConfirmDelete";
import NewsForm from "./components/NewsForm";
import NewsStore from "./mobx";

const createNewsInitialData = {
  target: 0,
  regionCode: [],
  branchId: 1,
  newsExtendeds: [
    { title: "", content: "", language: "uk" },
    { title: "", content: "", language: "ru" },
    { title: "", content: "", language: "en" },
    { title: "", content: "", language: "sk" },
    { title: "", content: "", language: "de" },
  ],
};

class News extends Component {
  state = {
    showModal: false,
    activeNewsID: 0,
    showCreateNews: false,
    modalSettings: {
      onYes: () => {},
      onClose: () => {},
      isOpen: false,
      text: null,
    },
  };

  close = () => {
    this.setState({
      showModal: false,
      activeNewsID: 0,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeNewsID: id,
    });
  };

  columns = [
    {
      title: t("id"),
      dataIndex: "id",
    },
    {
      title: t("header"),
      dataIndex: "title",
    },
    {
      title: t("audience"),
      dataIndex: "target",
      render: (text, record) => {
        if (text === 2) {
          return <>{t("all")}</>;
        } else if (text === 0) {
          return <>{t("drivers")}</>;
        } else {
          return <>{t("clients")}</>;
        }
      },
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        return (
          <span
            onClick={() => {
              this.setState({
                modalSettings: {
                  onYes: () => NewsStore.delNews(record.id),
                  onClose: () =>
                    this.setState({ modalSettings: { isOpen: false } }),
                  isOpen: true,
                  text: t("confirm_delete"),
                },
              });
            }}
            className="btn btn-warning"
          >
            {t("delete")}
          </span>
        );
      },
    },
  ];

  componentDidMount() {
    NewsStore.getNewsData();
  }

  closeCreateNews = () => {
    this.setState({
      showCreateNews: false,
    });
  };

  openCreateNews = () => {
    this.setState({
      showCreateNews: true,
    });
  };

  render() {
    const { newsList, activeNews, locationList } = NewsStore;
    const createNewsListData = [
      {
        id: "0",
        language: "uk",
        topicTitle: t("uk_news"),
        setValue: "",
        title: { value: "", placeholder: t("header") },
        content: { value: "", placeholder: t("message") },
      },
      {
        id: "1",
        language: "ru",
        topicTitle: t("ru_news"),
        value: "",
        setValue: "",
        title: { value: "", placeholder: t("header") },
        content: { value: "", placeholder: t("message") },
      },
      {
        id: "2",
        language: "en",
        topicTitle: t("en_news"),
        value: "",
        setValue: "",
        title: { value: "", placeholder: t("header") },
        content: { value: "", placeholder: t("message") },
      },
      {
        id: "3",
        language: "de",
        topicTitle: t("de_news"),
        value: "",
        setValue: "",
        title: { value: "", placeholder: t("header") },
        content: { value: "", placeholder: t("message") },
      },
      {
        id: "4",
        language: "sk",
        topicTitle: t("sk_news"),
        value: "",
        setValue: "",
        title: { value: "", placeholder: t("header") },
        content: { value: "", placeholder: t("message") },
      },
    ];


    return (
      <div>
        <div className="row">
          <ModalConfirmDelete {...this.state.modalSettings} />
          <div className="col-12" style={{ paddingBottom: "20px" }}>
            <button
              className="btn btn-primary"
              onClick={this.openCreateNews}
              style={{ marginLeft: "20px" }}
            >
              {t("create_news")}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={newsList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
              NewsStore.getNewsInfo(record.id);
            },
          })}
        />
        {/* Update news */}
        {this.state.showModal && activeNews ? (
          <ModalSimple close={this.close}>
            <NewsForm
              onSubmitForm={(data) => {
                NewsStore.updNewsInfo(data, this.state.activeNewsID);
                this.close();
              }}
              getRegions={(branchId, audience) =>
                NewsStore.getRegions(branchId, audience)
              }
              newsListData={createNewsListData}
              initialValues={activeNews}
              locationList={locationList}
              submitButtonText={t("save_changes")}
            />
          </ModalSimple>
        ) : null}

        {/* Create news */}
        {this.state.showCreateNews ? (
          <ModalSimple close={this.closeCreateNews}>
            <NewsForm
              onSubmitForm={(data) => {
                NewsStore.addNews(data);
                this.closeCreateNews();
              }}
              getRegions={(branchId, audience) =>
                NewsStore.getRegions(branchId, audience)
              }
              close={this.closeCreateNews}
              newsListData={createNewsListData}
              initialValues={toJS(createNewsInitialData)}
              locationList={locationList}
              submitButtonText={t("create")}
            />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

export default observer(News);
