import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../../config";

class FaresStore {
  @observable faresList = [];
  @observable cityId = "";

  chooseCity = (cityid) => {
    this.cityId = cityid;
    setTimeout(this.getFaresData, 500);
  };

  getFaresData = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}catalog/cities/${this.cityId}/tariffs`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.faresList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addFare = (value, carClassId, carDeliveryFee, name, orderPendingPerMinuteFee) => {
    const accessToken = localStorage.getItem("accessToken");
    const sendedData = {
      name,
      classId: carClassId,
      cityId: this.cityid,
      fare: value,
      carDeliveryFee: carDeliveryFee,
      orderPendingPerMinuteFee: orderPendingPerMinuteFee
    };

    axios
      .post(`${apiIdentity}catalog/cities/${this.cityId}/tariffs`, sendedData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getFaresData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delFare = (id) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .delete(`${apiIdentity}catalog/cities/${this.cityId}/tariffs/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getFaresData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateFare = (data) => {
    const accessToken = localStorage.getItem("accessToken");
    const { value, carClassId, carDeliveryFee, name, orderPendingPerMinuteFee, id } = data
    const sendedData = {
      name,
      classId: carClassId,
      cityId: this.cityid,
      fare: value,
      carDeliveryFee: carDeliveryFee,
      orderPendingPerMinuteFee: orderPendingPerMinuteFee
    };

    axios
      .put(`${apiIdentity}catalog/cities/${this.cityId}/tariffs/${id}`, sendedData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getFaresData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export default new FaresStore();
