import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { MdDeleteForever } from "react-icons/md";
import ModalConfirmDelete from "../../ModalConfirmDelete"

import { Table } from "antd";
import { t } from "i18next";

@observer
class OrderOptions extends Component {
  state = {
    optionName: "",
    price: "",
    optionID: false,
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  };

  carColumns = [
    {
      title: t('id'),
      dataIndex: "id",
    },
    {
      title: t('order_source'),
      dataIndex: "name",
    },
    {
      title: t('price'),
      dataIndex: "price",
    },
    {
      title: t('actions'),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <MdDeleteForever onClick={() => this.delBtn(record)} />
      ),
    },
  ];

  componentDidMount() {
    this.props.getOptionsData();
  }

  delBtn = (record) => {
    const recordId = record.id;
    this.setState({
      modalSettings: {
        onYes: () =>   this.props.delOption(recordId),
        onClose: () => this.setState({ modalSettings: { isOpen: false } }),
        isOpen: true,
        text: t('confirm_delete')
      }
    });
  };

  chgInput = (evt) => {
    this.setState({
      [evt.target.name]:
        evt.target.name === "price"
          ? evt.target.value.replace(/\D/, "")
          : evt.target.value,
    });
  };

  cancelEdit = () => {
    this.setState({
      optionID: false,
      optionName: "",
      price: "",
    });
  };

  editOrderOption = (orderOption) => {
    this.setState({
      optionID: orderOption.id,
      optionName: orderOption.name,
      price: orderOption.price,
    });
  };

  render() {
    const optionsList = this.props.optionsList;

    return (
      <>
        <div className="row">
          <div className="col-12">{t('add_option')}</div>
        </div>
        <div className="row" style={{ paddingBottom: "20px" }}>
          <div className="col-4">
            <input
              type="text"
              value={this.state.optionName}
              name="optionName"
              onChange={this.chgInput}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              value={this.state.price}
              name="price"
              onChange={this.chgInput}
              className="form-control"
            />
          </div>
          {this.state.optionID ? (
            <div className="col-4">
              <button
                onClick={() =>
                  this.props.updOption(
                    this.state.optionName,
                    this.state.price,
                    this.state.optionID
                  )
                }
                className="btn btn-success"
                style={{ marginRight: "20px" }}
              >
                {t('change')}
              </button>
              <button onClick={this.cancelEdit} className="btn btn-warning">
                {t('cancel')}
              </button>
            </div>
          ) : (
            <div className="col-4">
              <button
                onClick={() =>
                  this.props.addOption(this.state.optionName, this.state.price)
                }
                className="btn btn-success"
              >
                {t('add')}
              </button>
            </div>
          )}
        </div>
        <div>
          <Table
            columns={this.carColumns}
            dataSource={optionsList}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                this.editOrderOption(record);
              },
            })}
          />
        </div>
        <ModalConfirmDelete {...this.state.modalSettings} />
      </>
    );
  }
}

const withData = inject((stores) => ({
  optionsList: stores.OrderOptionsStore.optionsList,
  getOptionsData: stores.OrderOptionsStore.getOptionsData,
  addOption: stores.OrderOptionsStore.addOption,
  delOption: stores.OrderOptionsStore.delOption,
  updOption: stores.OrderOptionsStore.updOption,
}));

export default withData(OrderOptions);
