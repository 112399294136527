import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../config";
import { t } from "i18next";
import { axiosInstanceWithToken } from "../../shared/api/axiosInstance";

const targets = {
  0: "drivers",
  1: "clients",
};

class NewsStore {
  @observable activeNews = null;
  @observable newsForPost = {};
  @observable locationList = [];
  @observable newsList = [];

  getNewsData = async () => {
    await axiosInstanceWithToken
      .post(`news/list`, {})
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.newsList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getNewsInfo = async (id) => {
    try {
      const { data } = await axiosInstanceWithToken.get(
        `${apiIdentity}news/${id}`
      );
      this.activeNews = data.data;
    } catch (error) {
      console.log("error getNewsInfo: ", error);
    }
  };

  chgNewsInfo = (evt) => {
    const newsInfo = { ...this.activeNews };
    if (evt.target.name === "target") {
      newsInfo[evt.target.name] = Number(evt.target.value);
    } else {
      newsInfo[evt.target.name] = evt.target.value;
    }
    this.activeNews = newsInfo;
  };

  updNewsInfo = async (data, activeNewsID) => {
    try {
      const { createdAt, id, updatedAt, ...filteredData } = data;
      await axiosInstanceWithToken.put(`news/${activeNewsID}`, filteredData);
      this.getNewsData();
    } catch (error) {
      console.log("error in updNewsInfo:", error);
    }
  };

  addNews = async (news) => {
    await axiosInstanceWithToken
      .post(`${apiIdentity}news`, { ...news })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getNewsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delNews = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}news/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getNewsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getRegions = async (audience, branchId = 1) => {
    this.locationList = [];
    try {
      const { data } = await axiosInstanceWithToken.get(
        `${targets[audience]}/regionlocations/${branchId}`
      );
      this.locationList = data.data || [];
    } catch (error) {
      console.log("error in getRegions:", error);
    }
  };
}

export default new NewsStore();
