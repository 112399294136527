import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
// import LoginFromStore from './mobx'
import i18next, { changeLanguage, t } from 'i18next'

import './LoginForm.scss'

@observer
class Login extends Component {
    state = {
        email: '',
        pwd: '',
    }

    chgInput = event => {
        this.setState({
            [event.target.name]: event.target.value,
            render : 1
        })
    }

    render() {
        const { isLoggedIn, tryAuth, isError } = this.props.LoginFromStore
        if (isLoggedIn) {
            return <Redirect to="/drivers" />
        }
        return (
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card card-signin my-5">
                    <div className="card-body">
                        {isError ? <div className="alert alert-danger">{t('wrong_login_or_password')}</div> : null}
                        <form className="form-signin">
                            <div className="form-label-group">
                                <input name="email" value={this.state.email} onChange={this.chgInput} type="email" className="form-control" placeholder="Email" required autoFocus />
                            </div>
                            <div className="form-label-group">
                                <input name="pwd" value={this.state.pwd} onChange={this.chgInput} type="password" className="form-control" placeholder={t('password')} required />
                            </div>
                            <button className="btn btn-lg btn-primary btn-block" type="button" onClick={() => tryAuth(this.state.email, this.state.pwd)}>{t('sign_in')}</button>
                            <div className='form-label-group'>
                                <select className='language-form' onChange={(e) => {
                                    const lang = e.target.value;
                                    localStorage.setItem('language_name', lang)
                                    changeLanguage(lang)
                                    this.setState({render : this.state.render + 1})
                                    }}>
                                    <option value="ua">UA</option>
                                    <option value="ru">RU</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const withData = inject(stores => ({
    LoginFromStore: stores.LoginFromStore
}))

export default withData(Login);