import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { t } from "i18next";

import { Table, DatePicker } from "antd";
import { MdRefresh } from "react-icons/md";

import ModalSimple from "../ModalSimple";

const { RangePicker } = DatePicker;
@observer
class GateHistory extends Component {
  state = {
    showModal: false,
    dateEnd: 0,
    dateBegin: 0,
  };

  close = () => {
    this.setState({
      showModal: false,
    });
  };

  openModal = (orderReference) => {
    this.props.getTransactionInfo(orderReference);
    this.setState({
      showModal: true,
    });
  };

  columns = [
    {
      title: t('order_id'),
      dataIndex: "orderReference",
    },
    {
      title: t('transaction_type'),
      dataIndex: "transactionType",
    },
    {
      title: t('amount'),
      dataIndex: "amount",
    },
    {
      title: t('status'),
      dataIndex: "transactionStatus",
    },
    {
      title: t('comment'),
      dataIndex: "reason",
    },
    {
      title: t('date_of_creation'),
      dataIndex: "createdDate",
      render: (text, record) => {
        const stillUtc = moment.unix(text).toDate();
        const local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
        return <>{local}</>;
      },
    },
    {
      title: t('processing_date'),
      dataIndex: "processingDate",
      render: (text, record) => {
        const stillUtc = moment.unix(text).toDate();
        const local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
        return <>{local}</>;
      },
    },
    {
      title: "",
      dataIndex: "control",
      render: (text, record) => {
        if (record.transactionStatus === "Approved") {
          return (
            <>
              <MdRefresh
                onClick={() =>
                  this.props.refound(record.orderReference, record.amount)
                }
                style={{ cursor: "pointer" }}
              />
            </>
          );
        }
      },
    },
  ];

  componentDidMount() {
    this.setState(
      {
        dateBegin: moment().startOf("day"),
        dateEnd: moment().endOf("day"),
      },
      () => {
        this.props.getHistory(this.state.dateBegin, this.state.dateEnd);
      }
    );
  }

  getHistoryByDate = () => {
    this.props.getHistory(this.state.dateBegin, this.state.dateEnd);
  };

  chgDateRange = (date) => {
    if (date.length === 2) {
      this.setState({
        dateBegin: date[0].startOf("day"),
        dateEnd: date[1].endOf("day"),
      });
    }
    console.log(date);
  };

  render() {
    const {
      historyRecords,
      isError,
      isLoading,
      currentTransaction,
    } = this.props;
    return (
      <div>
        {isError ? <div className="alert alert-danger">{isError}</div> : null}
        <div style={{ paddingBottom: "20px" }}>
          <RangePicker
            placeholder={[t('create_date_from'), t('create_date_to')]}
            format="YYYY/MM/DD"
            value={[this.state.dateBegin, this.state.dateEnd, "YYYY-MM-DD"]}
            onChange={this.chgDateRange}
            allowClear={false}
          />
          <button
            className="btn btn-success"
            onClick={this.getHistoryByDate}
            style={{ marginLeft: "20px" }}
          >
            {t('get_list_of_transactions')}
          </button>
        </div>
        <Table
          columns={this.columns}
          dataSource={historyRecords}
          loading={isLoading}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.orderReference);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <textarea
              className="form-control"
              style={{ height: "400px" }}
              value={currentTransaction}
              disabled
            ></textarea>
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  historyRecords: stores.GateHistoryStore.historyRecords,
  isError: stores.GateHistoryStore.isError,
  getHistory: stores.GateHistoryStore.getHistory,
  refound: stores.GateHistoryStore.refound,
  getTransactionInfo: stores.GateHistoryStore.getTransactionInfo,
  currentTransaction: stores.GateHistoryStore.currentTransaction,
  isLoading: stores.GateHistoryStore.isLoading,
}));

export default withData(GateHistory);
