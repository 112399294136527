import { observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";

import { apiIdentity } from "../../config";
import { takePageElements } from "shared/constants";

class StatisticsStore {
  @observable clientsData = [];
  @observable driversData = [];
  @observable paymentsData = [];
  @observable ordersData = [];

  getOrdersData = async (cityId, dateFrom, dateTo) => {
    const accessToken = localStorage.getItem("accessToken");
    if (cityId && dateFrom && dateTo) {
      try {
        const from = dateFrom.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        const to = dateTo.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        console.log("dateFrom", dateFrom, "from", from);

        const allOrders = await axios.get(
          `${apiIdentity}orders/all?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const acceptedOrders = await axios.get(
          `${apiIdentity}orders/accepted?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const paidOrders = await axios.get(
          `${apiIdentity}orders/paid?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        this.ordersData = [
          {
            all: allOrders.data.data,
            accepted: acceptedOrders.data.data,
            paid: paidOrders.data.data,
          },
        ];
      } catch (e) {
        console.log("ERROR!", e);
      }
    }
  };

  getClientsData = async (cityId, dateFrom, dateTo) => {
    const accessToken = localStorage.getItem("accessToken");
    if (cityId && dateFrom && dateTo) {
      try {
        const from = dateFrom.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        const to = dateTo.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        console.log("dateFrom", dateFrom, "from", from);

        const newClient = await axios.get(
          `${apiIdentity}clients/new?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const regularClient = await axios.get(
          `${apiIdentity}clients/regular?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        this.clientsData = [
          {
            newClient: newClient.data.data,
            regularClient: regularClient.data.data,
          },
        ];
      } catch (e) {
        console.log("ERROR!", e);
      }
    }
  };

  getDriversData = async (cityId, dateFrom, dateTo) => {
    const accessToken = localStorage.getItem("accessToken");
    if (cityId && dateFrom && dateTo) {
      try {
        const from = dateFrom.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        const to = dateTo.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        console.log("dateFrom", dateFrom, "from", from);

        const newDrivers = await axios.get(
          `${apiIdentity}v2/drivers/new?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const regularDrivers = await axios.get(
          `${apiIdentity}v2/drivers/regular?cityId=${cityId}&from=${from}&to=${to}&pageSize=${takePageElements}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        const newCars = await axios.get(
          `${apiIdentity}cars/new?cityId=${cityId}&from=${from}&to=${to}&pageSize=${takePageElements}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        const inspectedCars = await axios.get(
          `${apiIdentity}cars/inspected?cityId=${cityId}&from=${from}&to=${to}&pageSize=${takePageElements}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        this.driversData = [
          {
            newDrivers: newDrivers.data.data,
            regularDrivers: regularDrivers.data.data.items.length,
            carsNew: `${newCars.data.data.totalCarsCount} / ${newCars.data.data.inspectedCarsCount}`,
            carsTo: inspectedCars.data.data,
          },
        ];

   
      } catch (e) {
        console.log("ERROR!", e);
      }
    }
  };

  getPaymentsData = async (cityId, dateFrom, dateTo) => {
    const accessToken = localStorage.getItem("accessToken");
    if (cityId && dateFrom && dateTo) {
      try {
        const from = dateFrom.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        const to = dateTo.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        console.log("dateFrom", dateFrom, "from", from);

        const paymentsFees = await axios.get(
          `${apiIdentity}payments/fees?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const paymentsPenalties = await axios.get(
          `${apiIdentity}payments/penalties?cityId=${cityId}&from=${from}&to=${to}&pageSize=${takePageElements}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const paymentsFlow = await axios.get(
          `${apiIdentity}payments/flow?cityId=${cityId}&from=${from}&to=${to}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        const penalties = `${paymentsPenalties.data.data.total} (${paymentsPenalties.data.data.paid} / ${paymentsPenalties.data.data.notPaid})`;

        this.paymentsData = [
          {
            incomeFromClients: paymentsFlow.data.data.incomeFromClients,
            fees: paymentsFees.data.data,
            penalties,
            outcomeToDrivers: paymentsFlow.data.data.outcomeToDrivers,
          },
        ];
      } catch (e) {
        console.log("ERROR!", e);
      }
    }
  };

  @computed get csvData() {
    let csvData = [];
    if (
      this.paymentsData.length > 0 &&
      this.driversData.length > 0 &&
      this.clientsData.length > 0 &&
      this.ordersData.length > 0
    ) {
      csvData = [
        {
          title: "Водители",
          value: "",
        },
        {
          title: "Новые водители",
          value: this.driversData[0].newDrivers,
        },
        {
          title: "Брали заказ за 7 дней",
          value: this.driversData[0].regularDrivers,
        },
        {
          title: "Авто (зарегистрировались / прошли ТО)",
          value: this.driversData[0].carsNew,
        },
        {
          title: "Водителей прошло ТО",
          value: this.driversData[0].carsTo,
        },
        {
          title: "",
          value: "",
        },

        {
          title: "Клиенты",
          value: "",
        },
        {
          title: "Новые пассажиры",
          value: this.clientsData[0].newClient,
        },
        {
          title: "Постоянные пассажиры (совершили более 4 заказов за 7 дней)",
          value: this.clientsData[0].regularClient,
        },
        {
          title: "",
          value: "",
        },

        {
          title: "Заказы",
          value: "",
        },
        {
          title: "Создано заказов",
          value: this.ordersData[0].all,
        },
        {
          title: "Принято заказов",
          value: this.ordersData[0].accepted,
        },
        {
          title: "Оплачено заказов",
          value: this.ordersData[0].paid,
        },
        {
          title: "",
          value: "",
        },
        {
          title: "Транзакции",
          value: "",
        },
        {
          title: "Оплачено клиентами",
          value: this.paymentsData[0].incomeFromClients,
        },
        {
          title: "Комиссия",
          value: this.paymentsData[0].fees,
        },
        {
          title: "Штрафы (оплачено/не оплачено)",
          value: this.paymentsData[0].penalties,
        },
        {
          title: "Выплаты водителям",
          value: this.paymentsData[0].outcomeToDrivers,
        },
      ];
    }

    return csvData;
  }
}

export default new StatisticsStore();
