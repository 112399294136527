import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { t } from "i18next";

import { Table, Input, Button, Icon, DatePicker } from "antd";

import ModalSimple from "../ModalSimple";
import OrderInfo from "./OrderInfo";

import PassengersInfo from "../Passengers/PassengersInfo";
import CreateOrder from "./CreateOrder";
import { takePageElements } from "shared/constants";

const { RangePicker } = DatePicker;

@observer
class Orders extends Component {
  state = {
    showModal: false,
    activeOrder: 0,
    showClientModal: false,
    activeClientID: 0,
    skip: 0,
    take: takePageElements,
    orderStatusId: "",
    paymentStatusId: "",
    paymentTypeId: "",
    showCreateOrderModal: false,
    filterDriverName: "",
    filterClientName: "",
    filterDriverCallSign: "",
    filterCarClassId: "",
    filterDateFrom: null,
    filterDateTo: null,
  };

  close = () => {
    this.setState({
      showModal: false,
      showClientModal: false,
      showCreateOrderModal: false,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeOrder: id,
    });
  };

  showClientModal = (id) => {
    this.setState({
      showClientModal: true,
      activeClientID: id,
    });
  };

  showCreateOrderModal = () => {
    this.setState({
      showCreateOrderModal: true,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Поиск по ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t('clear')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) => {
      console.log();
      if (dataIndex === "clientFullName") {
        return (
          <span
            onClick={() => this.showClientModal(record.clientId)}
            style={{ color: "#007bff", cursor: "pointer" }}
          >
            {text}
          </span>
        );
      } else {
        return this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        );
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  columns = [
    {
      title: t('id'),
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      ...this.getColumnSearchProps("id"),
    },
    {
      title: t('date'),
      dataIndex: "orderDate",
      render: (text, record) => {
        const formatDate = "DD.MM.YYYY HH:mm:ss";
        return moment.utc(text).local().format(formatDate);
      },
    },
    {
      title: t('status'),
      dataIndex: "orderStatus",
    },
    {
      title: t('payment'),
      dataIndex: "paymentStatus",
    },
    {
      title: t('payment_type'),
      dataIndex: "paymentType",
    },
    {
      title: t('route'),
      dataIndex: "destinationPoints",
      ...this.getColumnSearchProps("destinationPoints"),
    },
    {
      title: t('client'),
      dataIndex: "clientFullName",
      ...this.getColumnSearchProps("clientFullName"),
    },
    {
      title: t('amount'),
      dataIndex: "price",
      ...this.getColumnSearchProps("price"),
    },
    {
      title: t('driver'),
      dataIndex: "driverName",
      ...this.getColumnSearchProps("driverName"),
    },
    {
      title: t('call_sign'),
      dataIndex: "driverCallSign",
    },
    {
      title: t('car_class'),
      dataIndex: "carClass",
    },
    {
      title: t('country'),
      dataIndex: "country",
    },
    {
      title: t('order_options'),
      dataIndex: "orderPreferences",
      ...this.getColumnSearchProps("orderPreferences"),
    },
  ];

  getOrdersInterval = null;

  componentDidMount() {
    // this.props.getOrdersStatus()
    // this.props.getPaymentStatus()
    // this.props.getPaymentTypes()
    this.props.getCarClassData();
    this.props.getOrderClasses();
    this.props.getOrderPreferences();
    this.props.getOrdersData({
      skip: this.state.skip,
      take: this.state.take,
    });
    this.getOrdersInterval = setInterval(() => {
      const filterOptions = {
        skip: this.state.skip,
        take: this.state.take,
        driverName: this.state.filterDriverName,
        clientName: this.state.filterClientName,
        driverCallSign: this.state.filterDriverCallSign,
        carClassId: this.state.filterCarClass,
      };
      if (this.state.filterDateFrom) {
        const { filterDateFrom } = { ...this.state };
        filterOptions.dateFrom = moment(filterDateFrom)
          .utcOffset(0)
          .endOf("day");
      }

      if (this.state.filterDateTo) {
        const { filterDateTo } = { ...this.state };
        filterOptions.dateTo = filterDateTo.utcOffset(0).endOf("day");
      }

      if (this.state.orderStatusId.length > 1) {
        filterOptions.orderStatusId = this.state.orderStatusId;
      }
      if (this.state.paymentStatusId.length > 1) {
        filterOptions.paymentStatusId = this.state.paymentStatusId;
      }
      if (this.state.paymentTypeId.length > 1) {
        filterOptions.paymentTypeId = this.state.paymentTypeId;
      }

      this.props.getOrdersData(filterOptions);
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.getOrdersInterval);
  }

  orderStatusList = [
    {
      id: "Created",
      name: t('order_created'),
    },
    {
      id: "Accepted",
      name: t('order_accepted'),
    },
    {
      id: "Rejected",
      name: t('order_cancelled'),
    },
    {
      id: "CarArrived",
      name: t('driver_waiting_customer'),
    },
    {
      id: "ClientOnBoard",
      name: t('on_the_way'),
    },
    {
      id: "Completed",
      name: t('trip_completed'),
    },
    {
      id: "CarNotFound",
      name: t('car_not_found'),
    },
    {
      id: "Paid",
      name: t('trip_paid'),
    },
  ];

  paymentStatusList = [
    {
      id: "NotPaid",
      name: t('not_paid'),
    },
    {
      id: "Paid",
      name: t('paid'),
    },
  ];

  paymentTypeList = [
    {
      id: "Cash",
      name: t('cash'),
    },
    {
      id: "CreditCard",
      name: t('credit_card'),
    },
  ];

  chgInp = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  applyFilters = () => {
    const filterOptions = {
      skip: this.state.skip,
      take: this.state.take,
      driverName: this.state.filterDriverName,
      clientName: this.state.filterClientName,
      driverCallSign: this.state.filterDriverCallSign,
      carClassId: this.state.filterCarClass,
    };

    if (this.state.filterDateFrom) {
      const { filterDateFrom } = { ...this.state };
      filterOptions.dateFrom = moment(filterDateFrom).utcOffset(0).endOf("day");
    }

    if (this.state.filterDateTo) {
      const { filterDateTo } = { ...this.state };
      filterOptions.dateTo = filterDateTo.utcOffset(0).endOf("day");
    }
    if (this.state.orderStatusId.length > 1) {
      filterOptions.orderStatusId = this.state.orderStatusId;
    }
    if (this.state.paymentStatusId.length > 1) {
      filterOptions.paymentStatusId = this.state.paymentStatusId;
    }
    if (this.state.paymentTypeId.length > 1) {
      filterOptions.paymentTypeId = this.state.paymentTypeId;
    }
    console.log("filterDateTo", filterOptions.dateTo);

    this.props.getOrdersData(filterOptions);
  };

  cancelFilters = () => {
    this.setState({
      orderStatusId: "",
      paymentStatusId: "",
      paymentTypeId: "",
      filterDriverName: "",
      filterClientName: "",
      filterDriverCallSign: "",
      filterCarClass: "",
      filterDateFrom: null,
      filterDateTo: null,
    });
    this.props.getOrdersData({
      skip: this.state.skip,
      take: this.state.take,
    });
  };

  chgDateRange = (date) => {
    if (date.length === 2) {
      this.setState({
        filterDateFrom: date[0].startOf("day"),
        filterDateTo: date[1].endOf("day"),
      });
    }
  };

  render() {
    const { ordersList, classList } = this.props;
    const editOrderList = ordersList.map((order) => {
      const tmpOrder = {
        ...order,
        destinationPoints: [...order.destinationPoints],
        orderPreferences: [...order.orderPreferences],
      };
      tmpOrder.destinationPoints = tmpOrder.destinationPoints
        ? tmpOrder.destinationPoints.join(" -> ")
        : "";
      tmpOrder.orderPreferences = tmpOrder.orderPreferences
        ? tmpOrder.orderPreferences.join(", ")
        : "";

      return tmpOrder;
    });

    const orderStatusOptions = this.orderStatusList.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    const paymentStatusOptions = this.paymentStatusList.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    const paymentTypeOptions = this.paymentTypeList.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    const carClass = classList.map((carClass) => {
      return (
        <option value={carClass.id} key={carClass.id}>
          {carClass.name}
        </option>
      );
    });

    return (
      <div>
        <div className="row">
          <div className="col-9" style={{ paddingBottom: "20px" }}></div>
          <div className="col-3 text-right" style={{ paddingBottom: "20px" }}>
            {/* <button
              className="btn btn-primary"
              onClick={this.showCreateOrderModal}
            >
            {t('create_order')}
            </button> */}
          </div>
        </div>
        <div className="row">
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="orderStatusId"
              value={this.state.orderStatusId}
              className="form-control"
              onChange={this.chgInp}
              placeholder="Статус заказа"
            >
              <option value="">{t('order_status')}</option>
              {orderStatusOptions}
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="paymentStatusId"
              value={this.state.paymentStatusId}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('payment_status')}
            >
              <option value="">{t('payment_status')}</option>
              {paymentStatusOptions}
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="paymentTypeId"
              value={this.state.paymentTypeId}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('payment_type')}
            >
              <option value="">{t('payment_type')}</option>
              {paymentTypeOptions}
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterDriverName"
              value={this.state.filterDriverName}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('driver_name')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterDriverCallSign"
              value={this.state.filterDriverCallSign}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('call_sign')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterCarClass"
              value={this.state.filterCarClass}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('car_class')}
            >
              <option value="">{t('car_class')}</option>
              {carClass}
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterClientName"
              value={this.state.filterClientName}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('client_name')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <RangePicker
              placeholder={[t('create_date_from'), t('create_date_to')]}
              className="form-control"
              format="YYYY/MM/DD"
              value={[this.state.filterDateFrom, this.state.filterDateTo]}
              onChange={this.chgDateRange}
              allowClear={false}
            />
          </div>
          <div className="col-3 text-right" style={{ paddingBottom: "20px" }}>
            <button
              className="btn btn-warning"
              onClick={this.applyFilters}
              style={{ marginRight: "10px" }}
            >
              {t('filter')}
            </button>
            <button className="btn btn-secondary" onClick={this.cancelFilters}>
              {t('discard')}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={editOrderList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <OrderInfo activeOrder={this.state.activeOrder} />
          </ModalSimple>
        ) : null}
        {this.state.showClientModal ? (
          <ModalSimple close={this.close}>
            <PassengersInfo activeClientID={this.state.activeClientID} />
          </ModalSimple>
        ) : null}
        {this.state.showCreateOrderModal ? (
          <ModalSimple close={this.close}>
            <CreateOrder close={this.close} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getOrdersData: stores.OrdersStore.getOrdersData,
  ordersList: stores.OrdersStore.ordersList.map((elem) => { if(!elem.country) {elem.country = 'UA'} ;return elem}),
  getOrdersStatus: stores.OrdersStore.getOrdersStatus,
  getPaymentStatus: stores.OrdersStore.getPaymentStatus,
  getPaymentTypes: stores.OrdersStore.getPaymentTypes,
  getOrderClasses: stores.OrdersStore.getOrderClasses,
  getOrderPreferences: stores.OrdersStore.getOrderPreferences,
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
}));

export default withData(Orders);
