import React, { useState } from 'react';
import { t } from 'i18next';
import { ReactComponent as CheckLineIcon } from '../../assets/CheckLineIcon.svg'
import { ReactComponent as ClearIcon } from '../../assets/ClearIcon.svg'
import { ReactComponent as AvatarIcon } from '../../assets/AvatarIcon.svg';
import "./styles.scss";
import ConfirmActionModal from '../ConfirmActionModal';
import PhotoModal from 'components/PhotoModal';
import ModalChangesNotification from 'components/ModalChangesNotification';
import { saveBtn, approveBtn, rejectBtn } from './helpers';

const InspectionDetailsPage = ({ data, chgInspectionStatus }) => {

  const [comment, setComment] = useState(data.statusComment);
  const [status, setStatus] = useState(data.status);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [modalConfirmAction, setModalConfirmAction] = useState({
    onAction: () => { },
    onClose: () => setModalConfirmAction({ isOpen: false }),
    isOpen: false,
    text: '',
  });

  const [modalNotification, setModalNotification] = useState({
    success: false,
    onClose: () => setModalNotification({ isOpen: false }),
    isOpen: false,
    text: '',
  });

  const addLeadingZero = (value) => {
    const stringValue = value.toString();
    return stringValue.length === 1 ? `0${stringValue}` : stringValue;
  };

  const formatDateTime = (dateTimeString) => {
    if (dateTimeString) {
      const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      };

      const formattedDate = new Date(dateTimeString).toLocaleString('en-US', options);

      const [datePart] = formattedDate.split(', ');
      const [month, day, year] = datePart.split('/');

      const formattedDay = addLeadingZero(day);
      const formattedMonth = addLeadingZero(month);

      return `${formattedDay}.${formattedMonth}.${year}`;
    } else {
      return '';
    }
  };

  const formattedCreatedAt = formatDateTime(data.createdAt);

  const handleAddComment = (event) => {
    const inputValue = event.target.value;
    setComment(inputValue);
  };

  return (
    <div className='inspection_details_page'>
      {modalConfirmAction.isOpen && <ConfirmActionModal {...modalConfirmAction} />}
      {modalNotification.isOpen && <ModalChangesNotification {...modalNotification} />}
      <div className='main_driver_info'>
        <p className='inspections_data_title'>{t('car')} {data.carBrandAndModel}</p>
        <div className='driver_details_info'>
          <AvatarIcon />
          <div className='main_info'>
            <p className='main_text'>{t('driver')}</p>
            <p className='secondary_text'>{data.driverName}</p>
          </div>
        </div>
      </div>
      <div className='inspections_info_block'>
        <div className='inspections_info_grounds'>
          <div className='inspections_info_ground'>
            <div className='inspection_input disabled_inputs'>
              <p><span>*</span> {t('car_mark')}</p>
              <input value={data.brand} />
            </div>
            <div className='inspection_input disabled_inputs'>
              <p><span>*</span> {t('car_model')}</p>
              <input value={data.model} />
            </div>
            <div className='inspection_input disabled_inputs'>
              <p><span>*</span> {t('car_class')}</p>
              <div className='select-country'>
                <p>{data.class}</p>
              </div>
            </div>
          </div>
          <div className='inspections_info_ground'>
            <div className='inspection_input disabled_inputs'>
              <p><span>*</span> {t('application_date')}</p>
              <div className='select-country'>
                <p>{formattedCreatedAt}</p>
              </div>
            </div>
            <div className='inspection_input disabled_inputs'>
              <p><span>*</span> {t('country')}</p>
              <div className='select-country'>
                <p>{data.country}</p>
              </div>
            </div>
            <div className='inspection_input'>
              <p><span>*</span> {t('comment')}</p>
              <input value={comment} onChange={handleAddComment} />
            </div>
          </div>
        </div>
      </div>
      <p className='inspections_data_title' style={{ paddingTop: "30px" }}>{t('technical_inspection')} <span className='data_title_span'>{formattedCreatedAt}</span></p>
      <div className='inspection_car_block'>
        {data.files.map(file => (
          <div className='inspection_car_single_item' key={file.id} onClick={() => handlePhotoClick(file.id)}>
            <img src={file.file} alt='' />
          </div>
        ))}
      </div>
      <div className='info-buttons'>
        <button className='main-button' onClick={() => rejectBtn(data, comment, setStatus, chgInspectionStatus, setModalNotification, t, setModalConfirmAction)}>
          <ClearIcon />
          {t('reject')}
        </button>
        <div className='border-buttons' />
        {status === 0 || status === 2
          ?
          <button className='aprove-button' onClick={() => approveBtn(data, comment, setStatus, chgInspectionStatus, setModalNotification, t, setModalConfirmAction)}>
            <CheckLineIcon />
            {t('aprove')}
          </button>
          :
          <button className='aprove-button' onClick={() => saveBtn(data, comment, setStatus, chgInspectionStatus, setModalNotification, t, setModalConfirmAction)}>
            <CheckLineIcon />
            {t('save_changes')}
          </button>
        }
      </div>
      {isModalOpen && (
        <PhotoModal isOpen={isModalOpen} onClose={handleCloseModal} photos={data.files} />
      )}
    </div>
  );
};

export default InspectionDetailsPage;
