import React from "react";
import { t } from "i18next";

import "./ModalSimple.scss";

export default function ModalSimple(props) {
  const { children, close, styles } = { ...props };

  const closeByOverlay = (evt) => {
    if (evt.target.className === "modal-overlay") {
      close();
    }
  };
  return (
    <div className="modal-overlay" onClick={closeByOverlay}>
      <div className="modal row" style={{ ...styles }}>
        {children}
        <div
          className="col-12 text-center modal-footer"
          style={{ paddingBottom: "20px" }}
        >
          <button className="btn btn-warning" onClick={close}>
            {t('close')}
          </button>
        </div>
      </div>
    </div>
  );
}
