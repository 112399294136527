import React, { useEffect, useState } from 'react'
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/uk';
import { ReactComponent as EditIcon } from "../../../../assets/EditIcon.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/SettingsIcon.svg";
import { ReactComponent as SaveIcon } from "../../../../assets/SaveIcon.svg";
import ExportToCsv from "../../../ExportToCsv";
import ToogleSettings from "../../../ToogleSettings";
import { inject, observer } from "mobx-react";
import "../../../Drivers/styles.scss"
import { TechReviewInfo } from 'pages/InfoPage/types';
import FiltersBlock from '../FiltersBlock';
import ModalChangesNotification from 'components/ModalChangesNotification';
import { optionsCountry } from 'shared/constants';

moment.locale('uk');

let optionsStatuses = [];

const HeaderTable = ({ cityList, brandList, brandsWithModels, searchText, setSearchText, getCarClassData, carClassList, sortHeader, special, setSortHeader, getCityData, setCanEdit, isCanEdit, settingsShow, setSettingsShow, setEditElement, editElement, keys, results, updateDriver, getNameHeader, getData, page }) => {
    const [isFocusInput, setFocusInput] = useState(false);
    const [isSettings, setSettings] = useState(false);
    const [csvHeaders, setCsvHeaders] = useState([])
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState('all_statuses');
    const [isDropdownStatus, setIsDropdownStatus] = useState(false);
    const [selectedCountryFilter, setSelectedCountryFilter] = useState('all_countries');
    const [isDropdownCountry, setIsDropdownCountry] = useState(false);
    const [selectedCityFilter, setSelectedCityFilter] = useState({ name: 'all_cities', id: '' });
    const [isDropdownCities, setIsDropdownCities] = useState(false);
    const [selectedBrandFilter, setSelectedBrandFilter] = useState({ name: 'car_brand', id: '' });
    const [isDropdownBrand, setIsDropdownBrand] = useState(false);
    const [selectedModelFilter, setSelectedModelFilter] = useState({ name: 'car_model', id: '' });
    const [isDropdownModel, setIsDropdownModel] = useState(false);
    const [selectedCarClassFilter, setSelectedCarClassFilter] = useState({ name: 'car_class', id: '' });
    const [isDropdownCarClass, setIsDropdownCarClass] = useState(false);
    const [selectedTimeFilter, setSelectedTimeFilter] = useState('create_date');
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [isDropdownDate, setIsDropdownDate] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    if (special === TechReviewInfo.INSPECTION_INFO) {
        optionsStatuses = ['all_statuses', 'approved_first', 'rejected_first'];
    } else if (special === TechReviewInfo.DRIVER_INFO) {
        optionsStatuses = ['all_statuses', 'active', 'not_active'];
    }

    console.log(selectedBrandFilter)

    const [modalNotification, setModalNotification] = useState({
        success: false,
        onClose: () => setModalNotification({ isOpen: false }),
        isOpen: false,
        text: '',
    });

    const optionsCities = [{ name: 'all_cities', id: '' }, ...cityList];
    const optionsBrands = [{ name: 'car_brand', id: '' }, ...brandList];
    const models = selectedBrandFilter.models ? selectedBrandFilter.models : []
    const optionsModels = [{ name: 'car_model', id: '' }, ...models];
    const optionsCarClass = [{ name: 'car_class', id: '' }, ...carClassList];

    const changeSelectHeaader = (key, value) => setSortHeader({ ...sortHeader, [key]: value })
    const changeSelectHeaaderDate = (headerValues) => setSortHeader({ ...sortHeader, ...headerValues });

    useEffect(() => {
        const csvHeaders = keys.map((elem) => settingsShow[elem] && { label: t(getNameHeader(elem)), key: elem }).filter(e => e)
        setCsvHeaders(csvHeaders)
    }, [setSettingsShow])

    const cancelEditElem = () => {
        setEditElement({})
        setCanEdit(false)
        setSaveSuccess(false)
    }

    useEffect(() => {
        getCityData()
        getCarClassData()
    }, []);

    const saveEditElem = () => {
        editElement.elem[editElement.key] = editElement.newValue
        setEditElement({})
        setCanEdit(false)
        setSaveSuccess(true)
        const driverInfo = {
            ...editElement.elem,
            [editElement.key]: editElement.newValue
        }
        updateDriver(editElement.id, driverInfo)
            .then((success) => {
                if (success) {
                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('changes_success_save'),
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response.data.Data === "driver_call_sign_already_exists") {
                    setModalNotification({
                        success: false,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('call_sign_error'),
                    });
                }
                if (error.response.data.Message.includes("Phone should be defined")) {
                    setModalNotification({
                        success: false,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('phone_number_error'),
                    });
                }
                if (error.response.data.Data === ("general_error")) {
                    setModalNotification({
                        success: false,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('error_saving'),
                    });
                }
            });
    }

    const handleCountryOption = (countryOption) => {
        const key = "country"
        setSelectedCountryFilter(countryOption)
        setIsDropdownCountry(false);
        const selectedCountry = countryOption === "all_countries" ? "" : countryOption;
        changeSelectHeaader(key, selectedCountry)
    };

    const handleStatusOption = (statusOption, key) => {
        setSelectedStatusFilter(statusOption)
        setIsDropdownStatus(false);
        let value = '';

        if (special === TechReviewInfo.INSPECTION_INFO) {
            key = 'status';
            if (optionsStatuses[1] === statusOption) value = 1;
            else if (optionsStatuses[2] === statusOption) value = 2;
            changeSelectHeaader(key, value)
        } else {
            if (optionsStatuses[1] === statusOption) value = true;
            else if (optionsStatuses[2] === statusOption) value = false;
            changeSelectHeaader(key, value)
        }
    };

    const handleCityOption = (cityOption) => {
        const key = "city"
        setSelectedCityFilter(cityOption)
        setIsDropdownCities(false);
        const selectedCity = cityOption.name === "all_cities" ? "" : cityOption.name;
        changeSelectHeaader(key, selectedCity)
    };

    const handleBrandOption = (brandOption) => {
        const key = "brand"
        setSelectedBrandFilter(brandOption)
        setIsDropdownBrand(false);
        const selectedBrand = brandOption.name === "car_brand" ? "" : brandOption.name;
        changeSelectHeaader(key, selectedBrand)
    };

    const handleCarClassOption = (carClassOption) => {
        const key = "class"
        setSelectedCarClassFilter(carClassOption)
        setIsDropdownCarClass(false);
        const selectedCarClass = carClassOption.name === "car_class" ? "" : carClassOption.name;
        changeSelectHeaader(key, selectedCarClass)
    };

    const handleModelOption = (modelOption) => {
        const key = "model"
        setSelectedModelFilter(modelOption)
        setIsDropdownModel(false);
        const selectedModel = modelOption.name === "car_model" ? "" : modelOption.name;
        changeSelectHeaader(key, selectedModel)
    };

    const handleDateRangeChange = (dates, dateStrings) => {
        setSelectedDateRange(dates);
        if (dates.length === 2) {
            setSelectedTimeFilter(`${dateStrings[0]} - ${dateStrings[1]}`);
            changeSelectHeaaderDate({
                dateFrom: dateStrings[0],
                dateTo: dateStrings[1]
            });
        } else {
            setSelectedTimeFilter('create_date');
        }
    };

    const handleClearDate = () => {
        setSelectedTimeFilter('create_date');
        changeSelectHeaaderDate({
            dateFrom: "",
            dateTo: ""
        });
    }

    const changeSearchText = (v) => {
        const key = "searchText";
        changeSelectHeaader(key, v);
    };

    const clearSearchText = () => {
        const key = "searchText";
        if (searchText.length >= 0) {
            setSearchText("")
            changeSelectHeaader(key, "");
        }
    }

    const clearFilters = () => {
        setSortHeader("")
        switch (special) {
            case TechReviewInfo.DRIVER_INFO:
                setSelectedStatusFilter("all_statuses");
                setSelectedCityFilter({ name: 'all_cities', id: '' });
                setSelectedCountryFilter("all_countries");
                setSelectedTimeFilter('create_date');
                break;
            case TechReviewInfo.INSPECTION_INFO:
                setSelectedStatusFilter("all_statuses");
                setSelectedCountryFilter("all_countries");
                setSelectedCityFilter({ name: 'all_cities', id: '' });
                setSelectedCarClassFilter({ name: 'car_class', id: '' });
                setSelectedBrandFilter({ name: 'car_brand', id: '' });
                setSelectedModelFilter({ name: 'car_model', id: '' });
                setSelectedTimeFilter('create_date');
                break;
            default:
                break;
        }
    }

    return (
        <div className="search-panel">
            {modalNotification.isOpen && <ModalChangesNotification {...modalNotification} />}
            <FiltersBlock
                isFocusInput={isFocusInput}
                setFocusInput={setFocusInput}
                searchText={searchText}
                setSearchText={setSearchText}
                changeSearchText={changeSearchText}
                clearSearchText={clearSearchText}
                isDropdownDate={isDropdownDate}
                setIsDropdownDate={setIsDropdownDate}
                selectedTimeFilter={selectedTimeFilter}
                setSelectedTimeFilter={setSelectedTimeFilter}
                selectedDateRange={selectedDateRange}
                handleDateRangeChange={handleDateRangeChange}
                isDropdownStatus={isDropdownStatus}
                setIsDropdownStatus={setIsDropdownStatus}
                selectedStatusFilter={selectedStatusFilter}
                handleStatusOption={handleStatusOption}
                optionsStatuses={optionsStatuses}
                isDropdownCountry={isDropdownCountry}
                setIsDropdownCountry={setIsDropdownCountry}
                selectedCountryFilter={selectedCountryFilter}
                handleCountryOption={handleCountryOption}
                optionsCountry={optionsCountry}
                isDropdownCities={isDropdownCities}
                setIsDropdownCities={setIsDropdownCities}
                selectedCityFilter={selectedCityFilter}
                handleCityOption={handleCityOption}
                optionsCities={optionsCities}
                special={special}
                setSelectedCityFilter={setSelectedCityFilter}
                optionsBrands={optionsBrands}
                isDropdownBrand={isDropdownBrand}
                setIsDropdownBrand={setIsDropdownBrand}
                selectedBrandFilter={selectedBrandFilter}
                handleBrandOption={handleBrandOption}
                optionsModels={optionsModels}
                isDropdownModel={isDropdownModel}
                setIsDropdownModel={setIsDropdownModel}
                selectedModelFilter={selectedModelFilter}
                handleModelOption={handleModelOption}
                optionsCarClass={optionsCarClass}
                isDropdownCarClass={isDropdownCarClass}
                setIsDropdownCarClass={setIsDropdownCarClass}
                selectedCarClassFilter={selectedCarClassFilter}
                handleCarClassOption={handleCarClassOption}
                handleClearDate={handleClearDate}
                setIsDatePickerOpen={setIsDatePickerOpen}
                isDatePickerOpen={isDatePickerOpen}
            />
            <div className="right-search-bar">
                <div className="top-bar-buttons">
                    {isCanEdit && (special === TechReviewInfo.DRIVER_INFO) &&
                        <div className='small-edits-buttons'>
                            <button className="transparent-button" onClick={cancelEditElem}>
                                {t('discard_changes')}
                            </button>
                            <button className={`small-button-green ${editElement.newValue ? '' : 'small-button-green-disabled'}`} onClick={saveEditElem}>
                                <SaveIcon />
                            </button>
                        </div>
                    }
                    {!isCanEdit && (special === TechReviewInfo.DRIVER_INFO) &&
                        <button className={`small-button ${results.length > 0 ? '' : 'small-button-disabled'}`} onClick={() => setCanEdit(true)}>
                            <EditIcon />
                        </button>
                    }
                    <button className={`small-button ${isSettings ? 'small-button-active' : ''} ${results.length > 0 ? '' : 'small-button-disabled-second'}`} onClick={() => setSettings(!isSettings)}>
                        <SettingsIcon />
                    </button>
                    {isSettings && <ToogleSettings setKeys={setSettingsShow} keys={settingsShow} />}
                    <ExportToCsv
                        csvHeaders={csvHeaders}
                        csvData={results}
                        csvFileName="drivers.csv"
                    />
                </div>
                <div className="down-bar-buttons">
                    <button className="very-big-button" onClick={clearFilters}>
                        <p>{t('clear_filters')}</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HeaderTable;