import React from "react";
import { CSVLink } from "react-csv";
import { ReactComponent as DownloadIcon } from "../../assets/DownloadIcon.svg";

const ExportToCsv = ({ csvHeaders, csvData, csvFileName }) => {
  return (
    <>
      <CSVLink
        data={csvData}
        headers={csvHeaders}
        separator={";"}
        filename={csvFileName}
        className={`big-button ${!csvData || csvData.length === 0 ? 'big-button-disabled' : ''}`}
      >
        <DownloadIcon />
        <p>Export CSV</p>
      </CSVLink>
    </>
  );
};

export default ExportToCsv;
