import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import { toJS } from "mobx";
import moment from "moment";
import { t } from "i18next";

import { Table, Input, Button, Icon, DatePicker } from "antd";

import ModalSimple from "../ModalSimple";
import DriverInfo from "../Drivers/DriverInfo";
import PassengersInfo from "../Passengers/PassengersInfo";
import OrderInfo from "../Orders/OrderInfo";
import ExportToCsv from "../ExportToCsv";
import { takePageElements } from "shared/constants";

const { RangePicker } = DatePicker;

@observer
class Payments extends Component {
  state = {
    activeDriverID: 0,
    showDriverInfo: false,
    activeClientID: 0,
    showClientInfo: false,
    activeOrder: 0,
    showOrderInfo: false,
    csvData: [],
    filterClientName: "",
    filterDriverName: "",
    filterPaymentTypeId: "",
    filterOperationTypeId: "",
    filterQuoteFrom: "",
    filterQuoteTo: "",
    skip: 0,
    take: takePageElements,
    filterDateFrom: null,
    filterDateTo: null,
  };

  applyFilters = () => {
    const filterOptions = {
      skip: this.state.skip,
      take: this.state.take,
      clientName: this.state.filterClientName,
      driverName: this.state.filterDriverName,
      paymentTypeId: this.state.filterPaymentTypeId,
      operationTypeId: this.state.filterOperationTypeId,
      quoteFrom: this.state.filterQuoteFrom
        ? Number(this.state.filterQuoteFrom)
        : "",
      quoteTo: this.state.filterQuoteTo ? Number(this.state.filterQuoteTo) : "",
    };
    if (this.state.filterDateFrom) {
      const { filterDateFrom } = { ...this.state };
      filterOptions.dateFrom = moment(filterDateFrom).utcOffset(0).endOf("day");
    }

    if (this.state.filterDateTo) {
      const { filterDateTo } = { ...this.state };
      filterOptions.dateTo = filterDateTo.utcOffset(0).endOf("day");
    }
    this.props.getPaymentsData(filterOptions);
  };

  cancelFilters = () => {
    this.setState({
      filterClientName: "",
      filterDriverName: "",
      filterPaymentTypeId: "",
      filterOperationTypeId: "",
      filterQuoteFrom: "",
      filterQuoteTo: "",
      filterDateFrom: null,
      filterDateTo: null,
    });
    this.props.getPaymentsData({
      skip: this.state.skip,
      take: this.state.take,
    });
  };

  chgDateRange = (date) => {
    if (date.length === 2) {
      this.setState({
        filterDateFrom: date[0].startOf("day"),
        filterDateTo: date[1].endOf("day"),
      });
    }
    console.log(date);
  };

  csvHeaders = [
    { label: t('date'), key: "date" },
    { label: t('order_id'), key: "orderId" },
    { label: t('amount'), key: "quote" },
    { label: t('paying_type'), key: "paymentTypeName" },
    { label: t('client'), key: "clientName" },
    { label: t('driver'), key: "driverName" },
    { label: t('operation_type'), key: "operationTypeName" },
    { label: t('comment'), key: "comment" },
  ];

  showDriverInfo = (id) => {
    this.setState({
      activeDriverID: id,
      showDriverInfo: true,
    });
  };

  showClientInfo = (id) => {
    this.setState({
      activeClientID: id,
      showClientInfo: true,
    });
  };

  showOrderInfo = (id) => {
    this.setState({
      activeOrder: id,
      showOrderInfo: true,
    });
  };

  close = () => {
    this.setState({
      activeDriverID: 0,
      showDriverInfo: false,
      activeClientID: 0,
      showClientInfo: false,
      activeOrder: 0,
      showOrderInfo: false,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          // placeholder={t('search_by')` ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t('clear')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) => {
      if (dataIndex === "orderId") {
        if (record.orderId === "00000000-0000-0000-0000-000000000000") {
          return <></>;
        } else {
          return (
            <span
              className="text-primary"
              onClick={() => this.showOrderInfo(record.orderId)}
            >
              {text}
            </span>
          );
        }
      } else if (dataIndex === "clientName") {
        if (record.clientId === "00000000-0000-0000-0000-000000000000") {
          return <></>;
        } else {
          return (
            <span
              className="text-primary"
              onClick={() => this.showClientInfo(record.clientId)}
            >
              {record.clientName}
            </span>
          );
        }
      } else if (dataIndex === "driverName") {
        if (record.driverId === "00000000-0000-0000-0000-000000000000") {
          return <></>;
        } else {
          return (
            <span
              className="text-primary"
              onClick={() => this.showDriverInfo(record.driverId)}
            >
              {record.driverName}
            </span>
          );
        }
      } else {
        return this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        );
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  columns = [
    {
      title: t('date'),
      dataIndex: "date",
      ...this.getColumnSearchProps("date"),
    },
    {
      title: t('order_id'),
      dataIndex: "orderId",
      sorter: (a, b) => a.orderId - b.orderId,
      ...this.getColumnSearchProps("orderId"),
    },
    {
      title: t('amount'),
      dataIndex: "quote",
      sorter: (a, b) => a.quote - b.quote,
      ...this.getColumnSearchProps("quote"),
    },
    {
      title: t('paying_type'),
      dataIndex: "paymentTypeName",
      ...this.getColumnSearchProps("paymentTypeName"),
    },
    {
      title: t('client'),
      dataIndex: "clientName",
      ...this.getColumnSearchProps("clientName"),
    },
    {
      title: t('driver'),
      dataIndex: "driverName",
      ...this.getColumnSearchProps("driverName"),
    },
    {
      title: t('operation'),
      dataIndex: "operationTypeName",
      ...this.getColumnSearchProps("operationTypeName"),
    },
    {
      title: t('comment'),
      dataIndex: "comment",
      ...this.getColumnSearchProps("comment"),
    },
  ];

  componentDidMount() {
    this.props.getPaymentsData({
      skip: this.state.skip,
      take: this.state.take,
    });
  }

  chgInp = (evt) => {
    if (
      evt.target.name === "filterQuoteFrom" ||
      evt.target.name === "filterQuoteTo"
    ) {
      const numberPattern = new RegExp(/^-?\d*\.?\d*$/);
      const regexpResult = numberPattern.test(evt.target.value);

      if (!regexpResult) {
        return;
      }
    }
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  render() {
    const { paymentsList } = this.props;
    return (
      <div>
        <div className="row" style={{ paddingBottom: "20px" }}>
          <div className="col-8"></div>
          <div className="col-4 text-right">
            {paymentsList.length > 0 ? (
              <ExportToCsv
                csvHeaders={this.csvHeaders}
                csvData={
                  this.state.csvData.length > 0
                    ? this.state.csvData
                    : paymentsList
                }
                csvFileName="transactions.csv"
              />
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterClientName"
              value={this.state.filterClientName}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('client_name')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterDriverName"
              value={this.state.filterDriverName}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('driver_name')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterPaymentTypeId"
              value={this.state.filterPaymentTypeId}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('paying_type')}
            >
              <option value="">{t('paying_type')}</option>
              <option value="0">{t('cash')}</option>
              <option value="1">{t('credit_card')}</option>
              <option value="2">{t('cash_and_bonuses')}</option>
              <option value="3">{t('card_and_bonuses')}</option>
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterOperationTypeId"
              value={this.state.filterOperationTypeId}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('operation')}
            >
              <option value="">{t('operation')}</option>
              <option value="0">{t('order_payment')}</option>
              <option value="1">{t('withdrawals')}</option>
              <option value="2">{t('top_up_balance')}</option>
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterQuoteFrom"
              value={this.state.filterQuoteFrom}
              className="form-control"
              onChange={this.chgInp}
              style={{ maxWidth: "130px", float: "left" }}
              placeholder={t('amount_from')}
            />
            <input
              name="filterQuoteTo"
              value={this.state.filterQuoteTo}
              className="form-control"
              onChange={this.chgInp}
              style={{ maxWidth: "130px", float: "left", marginLeft: "10px" }}
              placeholder={t('amount_to')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <RangePicker
              placeholder={[t('create_date_from'), t('create_date_to')]}
              className="form-control"
              format="YYYY/MM/DD"
              value={[this.state.filterDateFrom, this.state.filterDateTo]}
              onChange={this.chgDateRange}
              allowClear={false}
            />
          </div>
          <div className="col-3 text-right" style={{ paddingBottom: "20px" }}>
            <button
              className="btn btn-warning"
              onClick={this.applyFilters}
              style={{ marginRight: "10px" }}
            >
              {t('filter')}
            </button>
            <button className="btn btn-secondary" onClick={this.cancelFilters}>
              {t('discard')}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={paymentsList}
          onChange={(pagination, filters, sorter, extra) => {
            const filteredData = extra["currentDataSource"].map((item) => {
              return toJS(item);
            });
            this.setState({ csvData: filteredData });
          }}
        />
        {this.state.showDriverInfo ? (
          <ModalSimple close={this.close}>
            <DriverInfo driverID={this.state.activeDriverID} />
          </ModalSimple>
        ) : null}
        {this.state.showClientInfo ? (
          <ModalSimple close={this.close}>
            <PassengersInfo activeClientID={this.state.activeClientID} />
          </ModalSimple>
        ) : null}
        {this.state.showOrderInfo ? (
          <ModalSimple close={this.close}>
            <OrderInfo activeOrder={this.state.activeOrder} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  paymentsList: stores.PaymentsStore.paymentsList,
  getPaymentsData: stores.PaymentsStore.getPaymentsData,
}));

export default withData(Payments);
