import { observable } from "mobx";
import axios from "axios";
import moment from "moment";
import { t } from "i18next";

import { apiIdentity } from "../../config";
import { axiosInstanceWithToken } from "shared/api/axiosInstance";
import { takePageElements } from "shared/constants";

class CarInspectionsStore {
  emptyInspection = {
    id: "",
    driverId: "",
    driverName: "",
    carBrandAndModel: "",
    createdAt: "",
    files: [],
    status: 0,
    comment: "",
    statusComment: "",
  };

  @observable inspectionsList = [];
  @observable currentInspection = { ...this.emptyInspection };
  @observable isError = "";
  @observable inspectionAmount = 0

  getInspections = (filters) => {
    const queryFilters = {};
    
    for (const key in filters) {
      if (filters[key]) {
        queryFilters[key] = filters[key];
      }
    }
  
    if (!queryFilters.skip) {
      queryFilters.skip = 0;
    }
  
    const accessToken = localStorage.getItem("accessToken");
  
    axios
      .post(`${apiIdentity}v2/inspection/search`, queryFilters, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          const fullEditRecord = response.data.data.map((record) => {
            const editRecord = { ...record };
  
            if (editRecord.status == 0) {
              editRecord.status = t('new');
            } else if (editRecord.status == 1) {
              editRecord.status = t('approved_first');
            } else if (editRecord.status == 2) {
              editRecord.status = t('rejected_first');
            }
  
            const stillUtc = moment.utc(record.createdAt).toDate();
            const local = moment(stillUtc)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
            editRecord.createdAt = local;
  
            return editRecord;
          });
  
          this.inspectionsList = [...fullEditRecord];
          this.inspectionAmount = response.data.total;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  

  getInsp = (id) => {
    axiosInstanceWithToken.get(`v2/inspection/${id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.currentInspection = { ...response.data.data };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  

  chgInspectionStatus = (id, status, statusComment) => {
    return new Promise((resolve, reject) => {
      const accessToken = localStorage.getItem("accessToken");
      axios
        .put(
          `${apiIdentity}v2/inspection/${id}/status`,
          { id, statusComment, status },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.success === false) {
            this.isError = true;
            reject(response.data.message);
          } else {
            this.getInspections({ skip: 0, take: takePageElements });
            resolve(true);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  chgInspectionInfo = (field, val) => {
    this.currentInspection[field] = val;
  };
}

export default new CarInspectionsStore();
