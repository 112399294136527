import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import DriverHistory from "./DriverHistory";
import DriverCars from "./DriverCars";
import DriverRating from "./DriverRating";
import ModalSimple from "../ModalSimple";
import FineModal from "./FineModal";
import { t } from "i18next";
import ModalConfirmDelete from "../ModalConfirmDelete";

@observer
class DriverInfo extends Component {
  state = {
    saved: false,
    addBonus: "",
    activeTab: 0,
    showUnplannedToModal: false,
    blockedCar: "",
    showFineModal: false,
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  };

  componentDidMount() {
    this.props.getDriver(this.props.driverID);
    this.props.getDriverCarsData(this.props.driverID);
  }

  updDriver = () => {
    this.setState({
      saved: true,
    });
    this.props.updateDriver();
  };

  chgBonus = (evt) => {
    this.setState({
      addBonus: evt.target.value.replace(/\D/, ""),
    });
  };

  delDriver = () => {
    this.props.close();
    this.props.delDriver();
  };

  chgTab = (id) => {
    this.setState({
      activeTab: id,
    });
  };

  unplannedTO = () => {
    this.setState({
      showUnplannedToModal: true,
    });
  };

  closeUnplannedTO = () => {
    this.setState({
      showUnplannedToModal: false,
      blockedCar: false,
    });
  };

  changePhone = (e) => {
    let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    }
    e.target.value = sanitizedValue;
    this.props.chgDriverInfo(e)
  }

  render() {
    const {
      firstName,
      lastName,
      phone,
      country,
      email,
      driverLicense,
      commission,
      photo,
      isActivated,
      isBlocked,
      blockingReason,
      blockingDate,
      balance,
      bonuses,
      rating,
      callSign,
    } = this.props.activeDriver;

    const driverCars = this.props.carsList.map((car) => {
      return (
        <option value={car.id}>
          {car.govNumber} - {car.fullModelName}
        </option>
      );
    });

    const unplannedModal = (
      <select
        className="form-control"
        value={this.state.blockedCar}
        onChange={(evt) => this.setState({ blockedCar: evt.target.value })}
      >
        <option value="" hidden>
          {" "}
          - {t('must_select_car')} -{" "}
        </option>
        {driverCars}
      </select>
    );

    return (
      <>
        {this.state.showFineModal ? (
          <>
            <ModalSimple
              close={() =>
                this.setState({
                  showFineModal: false,
                })
              }
              styles={{
                width: "500px",
                height: "350px",
              }}
            >
              <FineModal driverId={this.props.driverID} />
            </ModalSimple>
          </>
        ) : null}
        {this.state.showUnplannedToModal ? (
          <>
            <ModalSimple
              close={() =>
                this.setState({
                  showUnplannedToModal: false,
                  blockedCar: false,
                })
              }
              styles={{
                width: "500px",
                height: "250px",
              }}
            >
              <div className="col-12">
                {this.props.isCarInspError ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        {t('failed_maintenance_request')}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-12">{unplannedModal}</div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        this.props.requireCarTo(
                          this.props.driverID,
                          this.state.blockedCar,
                          this.closeUnplannedTO
                        );
                      }}
                      style={{ marginTop: "20px" }}
                    >
                      {t('request_maintenance')}
                    </button>
                  </div>
                </div>
              </div>
            </ModalSimple>
          </>
        ) : null}
        <div className="col-12">
          <div className="row">
            <ul className="tabs-menu">
              <li
                className={this.state.activeTab === 0 ? "active" : null}
                onClick={() => this.chgTab(0)}
              >
                {t('information')}
              </li>
              <li
                className={this.state.activeTab === 1 ? "active" : null}
                onClick={() => this.chgTab(1)}
              >
                {t('trip')}
              </li>
              <li
                className={this.state.activeTab === 2 ? "active" : null}
                onClick={() => this.chgTab(2)}
              >
                {t('car')}
              </li>
              <li
                className={this.state.activeTab === 3 ? "active" : null}
                onClick={() => this.chgTab(3)}
              >
                {t('rating')}
              </li>
            </ul>
          </div>
          {this.state.activeTab === 1 ? (
            <DriverHistory driverId={this.props.driverID} />
          ) : this.state.activeTab === 2 ? (
            <DriverCars driverId={this.props.driverID} />
          ) : this.state.activeTab === 3 ? (
            <DriverRating driverId={this.props.driverID} />
          ) : (
            <div className="row form-row">
              <div className="col-4">
                <img
                  src={
                    photo
                      ? photo
                      : "https://www.infoniac.ru/upload/iblock/a57/a57928a9ff118f9567bc14c16f2b48fc.jpg"
                  }
                  className="img-fluid"
                  alt="Водитель"
                />
                <button
                  className="btn btn-danger btn-block"
                  onClick={() => {
                    this.setState({ modalSettings : {
                      onYes : this.delDriver,
                      onClose : () => this.setState({modalSettings : {isOpen : false}}),
                      isOpen : true,
                      text : t('confirm_delete')
                    }})
                  }}
                  style={{ marginTop: "20px" }}
                >
                  {t('delete_driver')}
                </button>
                {isActivated ? null : (
                  <button
                    className="btn btn-primary btn-block"
                    onClick={this.props.activateDriver}
                    style={{ marginTop: "20px" }}
                  >
                    {t('activate')}
                  </button>
                )}
                {isBlocked ? (
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      this.setState({ modalSettings : {
                        onYes : this.props.unblockDriver,
                        onClose : () => this.setState({modalSettings : {isOpen : false}}),
                        isOpen : true,
                        text : t('confirm_unblock')
                      }})
                    }}
                    style={{ marginTop: "20px" }}
                  >
                    {t('unblock')}
                  </button>
                ) : (
                  <button
                    className="btn btn-danger btn-block"
                    onClick={() => {
                      this.setState({ modalSettings : {
                        onYes : this.props.blockDriver,
                        onClose : () => this.setState({modalSettings : {isOpen : false}}),
                        isOpen : true,
                        text : t('confirm_block')
                      }})
                    }}
                    style={{ marginTop: "20px" }}
                  >
                    {t('block')}
                  </button>
                )}
                <button
                  className="btn btn-danger btn-block"
                  onClick={() => {
                    this.props.resetRequireCarToError();
                    this.unplannedTO();
                  }}
                  style={{ marginTop: "20px" }}
                >
                  {t('request_maintenance')}
                </button>
                <button
                  className="btn btn-danger btn-block"
                  onClick={() => {
                    this.setState({ showFineModal: true });
                  }}
                  style={{ marginTop: "20px" }}
                >
                  {t('fine_the_driver')}
                </button>
              </div>
              <div className="col-8">
                <div className="row form-row">
                  <div className="col-12">{t('call_sign')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={callSign ? callSign : ""}
                      name="callSign"
                      className="form-control"
                      onChange={this.props.chgDriverInfo}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('name')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={firstName}
                      name="firstName"
                      className="form-control"
                      onChange={this.props.chgDriverInfo}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('last_name')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={lastName}
                      name="lastName"
                      className="form-control"
                      onChange={this.props.chgDriverInfo}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('country')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={country === null ? 'UA' : country}
                      name="country"
                      className="form-control"
                      onChange={this.props.chgDriverInfo}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('email')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={email}
                      name="email"
                      className="form-control"
                      onChange={this.props.chgDriverInfo}
                    />
                  </div>
                </div>
                {driverLicense && driverLicense.length > 5 ? (
                  <div className="row form-row">
                    <div className="col-12">{t('rights')}</div>
                    <div className="col-12">
                      {" "}
                      <img
                        src={driverLicense}
                        alt="Права"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                ) : (
                  <>{null}</>
                )}
                <div className="row form-row">
                  <div className="col-12">{t('contact_number')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={phone}
                      maxLength={16}
                      name="phone"
                      className="form-control"
                      onChange={this.changePhone}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('commission')} %</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={commission}
                      name="commission"
                      className="form-control"
                      onChange={this.props.chgDriverInfo}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('rating')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={rating}
                      name="rating"
                      className="form-control"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('balance')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={balance}
                      name="balance"
                      className="form-control"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('bonuses')}</div>
                  <div className="col-12">
                    <input
                      type="text"
                      value={bonuses}
                      name="bonuses"
                      className="form-control"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-12">{t('replenish_bonuses')}</div>
                  <div className="col-8">
                    <input
                      type="text"
                      name="addBonus"
                      value={this.state.addBonus}
                      className="form-control"
                      onChange={this.chgBonus}
                    />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        this.props.addBonus(this.state.addBonus);
                        this.setState({
                          addBonus: "",
                        });
                      }}
                    >
                      {t('replenish')}
                    </button>
                  </div>
                </div>
                {isBlocked ? (
                  <>
                    <div className="row form-row">
                      <div className="col-12">{t('status')}</div>
                      <div className="col-12">
                        <input
                          type="text"
                          value={isBlocked ? t('blocked') : null}
                          name="isBlocked"
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row form-row">
                      <div className="col-12">{t('reason_of_block')}</div>
                      <div className="col-12">
                        <input
                          type="text"
                          value={blockingReason ? blockingReason : null}
                          name="blockingReason"
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row form-row">
                      <div className="col-12">{t('block_date')}</div>
                      <div className="col-12">
                        <input
                          type="text"
                          value={blockingDate ? blockingDate : null}
                          name="blockingDate"
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {this.props.isUpdError ? (
                  <div className="row form-row">
                    <div className="col-12 alert alert-danger">
                    {t('updating_error')}{" "}
                      {this.props.updErrMsg ? (
                        <>: {this.props.updErrMsg}</>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {!this.props.isUpdError && this.props.isUpdError != null ? (
                  <div className="row form-row">
                    <div className="col-12 alert alert-success">
                    {t('changes_saved')}
                    </div>
                  </div>
                ) : null}
                <div className="row form-row">
                  <div className="col-12">
                    <button
                      className="btn btn-success"
                      onClick={this.updDriver}
                    >
                      {t('save_changes')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ModalConfirmDelete {...this.state.modalSettings} />
        </div>
      </>
    );
  }
}

const withData = inject((stores) => ({
  getDriver: stores.DriversStore.getDriver,
  updateDriver: stores.DriversStore.updateDriver,
  activeDriver: stores.DriversStore.activeDriver,
  chgDriverInfo: stores.DriversStore.chgDriverInfo,
  addBonus: stores.DriversStore.addBonus,
  delDriver: stores.DriversStore.delDriver,
  activateDriver: stores.DriversStore.activateDriver,
  blockDriver: stores.DriversStore.blockDriver,
  unblockDriver: stores.DriversStore.unblockDriver,
  isUpdError: stores.DriversStore.isUpdError,
  updErrMsg: stores.DriversStore.updErrMsg,
  getDriverCarsData: stores.CarsStore.getDriverCarsData,
  carsList: stores.CarsStore.carsList,
  isCarInspError: stores.CarsStore.isCarInspError,
  requireCarTo: stores.CarsStore.requireCarTo,
  resetRequireCarToError: stores.CarsStore.resetRequireCarToError,
}));

export default withData(DriverInfo);
