import React, { Component } from 'react'
import { t } from 'i18next'

export default class NewUser extends Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
    error: false,
  }

  componentWillUnmount(){
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      error: false,
    })
  }

  onChg = evt => {
    this.setState({
      [evt.target.name]: evt.target.value,
    })
  }

  createUser = () => {
    const {email, firstName, lastName, password, phone} = this.state
    if(email.length > 5 && firstName && lastName && password.length >= 8){
      const user = {
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "password": password,
        "phone": phone
      }
      this.props.createUser(user, this.props.accessToken)
      this.props.close()
    } else {
      this.setState({
        error: true,
      })
    }
  }

  changePhone = (e) => {
    let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    }
    e.target.value = sanitizedValue;
    this.onChg(e)
  }

  render() {
    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-4">
            {t('email')} 
          </div>
          <div className="col-8">                
            <input type="text" onChange={this.onChg} value={this.state.email} name="email" className="form-control" placeholder="Email" />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">
            {t('name')} 
          </div>
          <div className="col-8">                
            <input type="text" onChange={this.onChg} value={this.state.firstName} name="firstName" className="form-control" placeholder="Имя" />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">
            {t('last_name')} 
          </div>
          <div className="col-8">                
            <input type="text" onChange={this.onChg} value={this.state.lastName} name="lastName" className="form-control" placeholder="Фамилия" />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">
            {t('mobile_number')} 
          </div>
          <div className="col-8">                
            <input type="text" onChange={this.changePhone} value={this.state.phone} maxLength={16} name="phone" className="form-control" placeholder="Телефон" />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">
            {t('password')} 
          </div>
          <div className="col-8">                
            <input type="password" onChange={this.onChg} value={this.state.password} name="password" className="form-control" />
          </div>
        </div>
        {this.state.error ? <div className="row form-row">
          <div className="col-12 text-center alert alert-danger">
            {t('form_incorrectly')} 
          </div>
        </div> : null}
        <div className="row form-row">
          <div className="col-12 text-center">
            <button className="btn btn-success" onClick={this.createUser}>{t('create')}</button>
          </div>
        </div>
      </div>
    )
  }
}
