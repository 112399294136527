import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../config";
import { takePageElements } from "shared/constants";
import { axiosInstanceWithToken } from "shared/api/axiosInstance";

class OrdersStore {
  clearOrder = {
    phoneNumber: "",
    clientName: "",
    cityId: "",
    from: "",
    fromData: {},
    to: "",
    toData: {},
    carClassId: "",
    orderPreferences: [],
    comment: "",
    priceEstimated: 0,
    distance: 0,
    tariffId: "",
  };

  @observable newOrder = { ...this.clearOrder };
  @observable findedStrResult = [];
  @observable findedStrToResult = [];

  @observable ordersList = [];
  @observable currentOrder = {};
  @observable currentDriver = {};
  @observable currentClient = {};

  @observable orderStatus = [];
  @observable paymentStatus = [];
  @observable paymentTypes = [];
  @observable orderClasses = [];
  @observable orderPreferences = [];

  clearOrderObj = () => {
    this.newOrder = { ...this.clearOrder };
  };

  getTariff = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const getTariffByCityId = await axios.get(
        `${apiIdentity}catalog/cities/${this.newOrder.cityId}/tariffs`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      getTariffByCityId.data.data.filter((item) => {
        if (item.classId === this.newOrder.carClassId) {
          this.newOrder.tariffId = item.id;
          return true;
        }
        return false;
      });
      console.log("newOrder", this.newOrder);
    } catch (e) {
      console.log(e);
    }
  };

  makeNewOrder = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const newOrderData = {
      cityId: this.newOrder.cityId,
      tariffId: this.newOrder.tariffId,
      destinationPoints: [this.newOrder.fromData, this.newOrder.toData],
      comment: this.newOrder.comment,
      priceEstimated: this.newOrder.priceEstimated,
      orderPreferences: this.newOrder.orderPreferences,
      distance: this.newOrder.distance,
      clientFullName: this.newOrder.clientName,
      clientPhone: this.newOrder.phoneNumber,
      paymentTypeId: "Cash",
      carClassId: this.newOrder.carClassId,
    };

    try {
      const chkClient = await axios.post(
        `${apiIdentity}clients/list`,
        {
          phoneNumber: this.newOrder.phoneNumber,
          take: takePageElements,
          skip: 0,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (chkClient.data.data.amount === 0) {
        // Создаем пользователя если его нет
        const createClient = await axios.post(
          `${apiIdentity}clients`,
          {
            firstName: this.newOrder.clientName,
            lastName: " ",
            phone: this.newOrder.phoneNumber,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (createClient.data.success) {
          newOrderData.clientId = createClient.data.data.id;
        }
      } else {
        // console.log("chkClient", chkClient.data);
        newOrderData.clientId = chkClient.data.data.content[0].id;
      }
      // console.log("chkClient", chkClient.data);

      const createOrder = await axios.post(
        `${apiIdentity}orders/new`,
        newOrderData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log("createOrder", createOrder.data);
    } catch (e) {
      console.log(e);
    }
  };

  getEstimatePrice = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const estimatePrice = await axios.post(
        `${apiIdentity}orders/new/price`,
        {
          distance: this.newOrder.distance,
          carClassId: this.newOrder.carClassId,
          orderPreferences: this.newOrder.orderPreferences,
          cityId: this.newOrder.cityId,
          tariffId: this.newOrder.tariffId,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.newOrder.priceEstimated = estimatePrice.data.data.totalPrice;
    } catch (e) {
      console.log(e);
    }
  };

  chgAddressField = (field, val) => {
    this.newOrder[field] = val;
  };

  chgOrderField = (field, val) => {
    if ((field === "from" || field === "to") && val.length > 3) {
      // this.getStreetResult(val, field);
    }
    this.newOrder[field] = val;
  };

  clearResults = (type) => {
    if (type === "to") {
      this.findedStrToResult = [];
    } else {
      this.findedStrResult = [];
    }
  };

  getStreetResult = async (text, type) => {
    try {
      const googleResult =
        await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${text}, +Киев&libraries=places&components=country:UA&key=AIzaSyBRXO7yX1U6wMakjryBYUMaLo8sZ9j9u2M
      `);
      if (type === "to") {
        this.findedStrToResult = googleResult.data.results;
      } else {
        this.findedStrResult = googleResult.data.results;
      }
    } catch (e) {
      console.log(e);
    }
  };

  chkboxChange = (val) => {
    if (this.newOrder.orderPreferences.includes(val)) {
      const updOptionList = this.newOrder.orderPreferences.filter((item) => {
        if (item === val) {
          return false;
        }
        return true;
      });
      this.newOrder.orderPreferences = updOptionList;
    } else {
      this.newOrder.orderPreferences.push(val);
    }
  };

  getDriver = (id) => {
    if (id) {
      axiosInstanceWithToken.get(`${apiIdentity}v2/drivers/${id}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.success === false) {
            this.isError = true;
            this.currentDriver = {};
          } else {
            this.currentDriver = { ...response.data.data };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.currentDriver = {};
    }
  };

  getClient = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}clients/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
          this.currentClient = {};
        } else {
          this.currentClient = { ...response.data.data };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOrdersStatus = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}orderdata/orderstatuses`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.orderStatus = [...response.data.data];
          console.log("this.orderStatus", this.orderStatus);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPaymentStatus = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}orderdata/paymentstatuses`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.paymentStatus = [...response.data.data];
          console.log("this.paymentStatus", this.paymentStatus);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPaymentTypes = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}orderdata/paymenttypes`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.paymentTypes = [...response.data.data];
          console.log("this.paymentTypes", this.paymentTypes);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOrderClasses = () => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .get(`${apiIdentity}catalog/classes`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.orderClasses = [...response.data.data];
          console.log("this.orderClasses", this.orderClasses);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOrderPreferences = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}catalog/order-preferences`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.orderPreferences = [...response.data.data];
          console.log("this.orderPreferences", this.orderPreferences);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOrdersData = (options) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .post(`${apiIdentity}orders/list`, options, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          const orderList = response.data.data.content.map((item) => {
            const updItem = { ...item };
            updItem.destinationPoints = item.destinationPoints.map((point) => {
              return point.name;
            });
            updItem.orderPreferences = item.orderPreferences.map((pref) => {
              return pref.name;
            });
            return updItem;
          });
          this.ordersList = orderList;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOrder = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}orders/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          const curOrder = { ...response.data.data };
          curOrder.destinationPoints = response.data.data.destinationPoints.map(
            (point) => {
              return point.name;
            }
          );
          this.currentOrder = curOrder;
          this.getDriver(response.data.data.driverId);
          this.getClient(response.data.data.clientId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDriverOrdersData = (driverId, fromDate, toDate) => {
    const accessToken = localStorage.getItem("accessToken");
    let options = {
      skip: 0,
      take: takePageElements,
      driverId: driverId,
    };
    if (fromDate) {
      options.fromDate = fromDate;
    }
    if (toDate) {
      options.toDate = toDate;
    }

    axios
      .post(`${apiIdentity}orders/list`, options, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          const orderList = response.data.data.content.map((item) => {
            const updItem = { ...item };
            updItem.destinationPoints = item.destinationPoints.map((point) => {
              return point.name;
            });
            updItem.orderPreferences = item.orderPreferences.map((pref) => {
              return pref.name;
            });
            return updItem;
          });
          this.ordersList = orderList.reverse();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getClientOrdersData = (clientId, fromDate, toDate) => {
    const accessToken = localStorage.getItem("accessToken");
    let options = {
      skip: 0,
      take: takePageElements,
      clientId: clientId,
    };
    if (fromDate) {
      options.fromDate = fromDate;
    }
    if (toDate) {
      options.toDate = toDate;
    }

    axios
      .post(`${apiIdentity}orders/list`, options, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          const orderList = response.data.data.content.map((item) => {
            const updItem = { ...item };
            updItem.destinationPoints = item.destinationPoints.map((point) => {
              return point.name;
            });
            updItem.orderPreferences = item.orderPreferences.map((pref) => {
              return pref.name;
            });
            return updItem;
          });
          this.ordersList = orderList.reverse();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new OrdersStore();
