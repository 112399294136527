import React, { useEffect } from 'react';
import { t } from "i18next";
import { ReactComponent as CheckIcon } from '../../assets/CheckIcon.svg';
import { ReactComponent as ForbiddenIcon } from '../../assets/ForbiddenIcon.svg';
import { ReactComponent as CloseBoldIcon } from '../../assets/CloseBoldIcon.svg';
import './styles.scss';

const ModalChangesNotification = ({ success, onClose, text }) => {

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onClose();
        }, 3000);
        return () => clearTimeout(timeoutId);
    }, [onClose]);

    return (
        <div className='save_changes_block'>
            {success
                ?
                <CheckIcon />
                :
                <ForbiddenIcon />
            }
            <p>{t(text)}</p>
            <CloseBoldIcon className='close_bold_icon' onClick={onClose} />
        </div>
    );
};

export default ModalChangesNotification;