import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { t } from "i18next";

@observer
class OrderInfo extends Component {
  componentDidMount() {
    this.props.getOrder(this.props.activeOrder);
  }

  render() {
    const {
      id,
      destinationPoints,
      createdAt,
      updatedAt,
      price,
      country,
      orderComment,
      paymentStatusId,
      paymentTypeId,
      orderSourceId,
      carClassId,
      statusId,
      orderPreferences,
      bonusPaid,
      pendingsDurations,
      pendingsQuote,
      totalQuote,
    } = this.props.currentOrder;
    const dirverInfo = this.props.currentDriver;
    const clientInfo = this.props.currentClient;

    let paymentStatusVal = paymentStatusId === 0 ? "NotPaid" : "Paid";

    let paymentTypeVal = paymentTypeId === 0 ? "Cash" : "CreditCard";

    let orderClassVal = "";
    this.props.orderClasses.map((item) => {
      if (item.id === carClassId) {
        orderClassVal = item.name;
      }
      return item;
    });

    let orderStatusVal = "";
    if (statusId === 0) {
      orderStatusVal = t('order_created');
    } else if (statusId === 1) {
      orderStatusVal = t('order_accepted');
    } else if (statusId === 2) {
      orderStatusVal = t('order_cancelled');
    } else if (statusId === 4) {
      orderStatusVal = t('driver_waiting_customer');
    } else if (statusId === 8) {
      orderStatusVal = t('on_the_way');
    } else if (statusId === 16) {
      orderStatusVal = t('trip_completed');
    } else if (statusId === 32) {
      orderStatusVal = t('car_not_found');
    } else if (statusId === 128) {
      orderStatusVal = t('trip_paid');
    } else {
      orderStatusVal = t('unknown_order_status');
    }

    const orderPreferencesVals = orderPreferences
      ? orderPreferences.map((preference) => {
          return preference.name;
        })
      : "";

    const stillUtchumanCreateTime = moment.utc(createdAt).toDate();
    const localhumanCreateTime = moment(stillUtchumanCreateTime)
      .local()
      .format("YYYY-MM-DD HH:mm:ss");

    const stillUtchumanUpdTime = moment.utc(updatedAt).toDate();
    const localhumanUpdTime = moment(stillUtchumanUpdTime)
      .local()
      .format("YYYY-MM-DD HH:mm:ss");

    const driverName =
      dirverInfo && dirverInfo.lastName && dirverInfo.firstName
        ? dirverInfo.firstName + " " + dirverInfo.lastName
        : "";

    const paymentTypeText =
      paymentTypeVal === "Cash" ? t('cash') : t('credit_card');
    const paymentStatusText =
      paymentStatusVal === "Paid" ?  t('paid'): t('not_paid');

    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-4">{t('order_id')}:</div>
          <div className="col-8">
            <input type="text" value={id} className="form-control" disabled />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('route')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={destinationPoints ? destinationPoints.join("-> ") : ""}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('country')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={country === null ? 'UA' : country}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('order_options')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={
                orderPreferencesVals ? orderPreferencesVals.join(", ") : ""
              }
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('creation_time')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={localhumanCreateTime}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('time_last_update_order')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={localhumanUpdTime}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('ordered_class')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={orderClassVal}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('status')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={orderStatusVal}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('order_comment')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={orderComment}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('initial_cost')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={price}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('waiting_time')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={pendingsDurations}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('paid_waiting_time')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={pendingsQuote}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('total_cost')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={totalQuote}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('bonuses_paid')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={bonusPaid}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('payment_type')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={paymentTypeText}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('payment_status')}:</div>
          <div className="col-8">
            <input
              type="text"
              value={paymentStatusText}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="single-block">
          <div className="row form-row">
            <div className="col-12">
              <h4>{t('driver')}</h4>
            </div>
          </div>
          <div className="row form-row">
            <div className="col-4">{t('first_name_last_name')}:</div>
            <div className="col-8">
              <input
                type="text"
                value={driverName}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-4">{t('mobile_number')}:</div>
            <div className="col-8">
              <input
                type="text"
                value={dirverInfo.phone}
                className="form-control"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="single-block">
          <div className="row form-row">
            <div className="col-12">
              <h4>{t('client')}</h4>
            </div>
          </div>
          <div className="row form-row">
            <div className="col-4">{t('first_name_last_name')}:</div>
            <div className="col-8">
              <input
                type="text"
                value={clientInfo.firstName + " " + clientInfo.lastName}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-4">{t('mobile_number')}:</div>
            <div className="col-8">
              <input
                type="text"
                value={clientInfo.phone}
                className="form-control"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getOrder: stores.OrdersStore.getOrder,
  currentOrder: stores.OrdersStore.currentOrder,
  orderStatus: stores.OrdersStore.orderStatus,
  paymentStatus: stores.OrdersStore.paymentStatus,
  paymentTypes: stores.OrdersStore.paymentTypes,
  orderClasses: stores.OrdersStore.orderClasses,
  orderSources: stores.OrdersStore.orderSources,
  orderPreferences: stores.OrdersStore.orderPreferences,
  currentDriver: stores.OrdersStore.currentDriver,
  currentClient: stores.OrdersStore.currentClient,
}));

export default withData(OrderInfo);
