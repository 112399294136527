import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { MdRestore, MdBlock } from "react-icons/md"
import { t } from 'i18next';
import ModalConfirmDelete from "../ModalConfirmDelete"

import { Table, Tooltip } from 'antd';
import ModalSimple from '../ModalSimple'
import NewUser from './NewUser'
import EditUser from './EditUser'

@observer
class Users extends Component {
  state = {
    showNewUserForm: false,
    showEditUserForm: false,
    editUser: {},
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  }

  close = () => {
    this.setState({
      showNewUserForm: false,
      showEditUserForm: false,
    })
  }

  showNewUserForm = () => {
    this.setState({
      showNewUserForm: true,
    })
  }

  showEditUserForm = (record) => {
    console.log(record)
    this.setState({
      showEditUserForm: true,
      editUser: record,
    })
  }

  columns = [
    {
      title: t('email'),
      dataIndex: 'email',
    },
    {
      title: t('name'),
      dataIndex: 'firstName',
    },
    {
      title: t('last_name'),
      dataIndex: 'lastName',
    },
    {
      title: t('status'),
      dataIndex: 'isBlocked',
      render: (text, record) => {
        const status = record.isBlocked ? t('block') : t('active')
        return status
      }
    },
    {
      title: '',
      dataIndex: 'control',
      render: (text, record) => {
        const { accessToken} = this.props.LoginFromStore
        const { restore, block } = this.props.UsersStore
        const control = record.isBlocked ? <Tooltip title={t('unblock')}><MdRestore onClick={() => {
          this.setState({ modalSettings : {
            onYes : () => {this.close(); restore(record.id, accessToken)},
            onClose : () => this.setState({modalSettings : {isOpen : false}}),
            isOpen : true,
            text : t('confirm_unblock')
          }})
        }} /></Tooltip> : <Tooltip title={t('block')}><MdBlock onClick={() => {
          this.setState({ modalSettings : {
            onYes : () => {this.close(); block(record.id, accessToken)},
            onClose : () => this.setState({modalSettings : {isOpen : false}}),
            isOpen : true,
            text : t('confirm_block')
          }})
        }} /></Tooltip>
        return control
      }
    },
  ];

  
  componentDidMount(){
    const { accessToken, refreshToken, expires } = this.props.LoginFromStore
    const { getUsersData } = this.props.UsersStore
    getUsersData(accessToken, refreshToken, expires)
  }

  render() {
    const { usersList, createUser, updUser } = this.props.UsersStore
    const { accessToken } = this.props.LoginFromStore

    return (
      <>
      <ModalConfirmDelete {...this.state.modalSettings} />
        <div className="row">
          <div className="col-12 text-right" style={{paddingBottom: "15px"}}>
            <button data-tip={t('create_new_system_user')} className="btn btn-primary" onClick={this.showNewUserForm}>{t('create_user')} </button>
          </div>
        </div>
        <div>
          <Table columns={this.columns} dataSource={usersList} onRow={ (record, rowIndex) => ({
          onDoubleClick: () => {
            this.showEditUserForm(record)
          }
        })} />
        </div>
        {this.state.showNewUserForm ? <ModalSimple close={this.close}><NewUser close={this.close} createUser={createUser} accessToken={accessToken} /></ModalSimple> : null}
        {this.state.showEditUserForm ? <ModalSimple close={this.close}><EditUser user={this.state.editUser} chgPwd={this.props.chgUserPassword} close={this.close} updUser={updUser} accessToken={accessToken} /></ModalSimple> : null}
      </>
    )
  }
}

const withData = inject(stores => ({
  UsersStore: stores.UsersStore,
  LoginFromStore: stores.LoginFromStore,
  chgUserPassword: stores.UsersStore.chgUserPassword,
}))

export default withData(Users)
