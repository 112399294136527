import { observable } from "mobx";
import { apiIdentity } from "../config";
import axios from "axios";

class CarStore {
    @observable classes = []
    @observable colors = []
    @observable brandsWithModels = [];

    getStoreOne = async (name, key, accessToken = localStorage.getItem("accessToken")) => {
         
        const response = await axios(apiIdentity + 'catalog/' + name, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
        if (response.data.success) {
            this[key] = response.data.data;
        } else {
            console.log('error ')
        }
    }

    getAllStore = () => {
        const accessToken = localStorage.getItem("accessToken")
        this.getStoreOne('classes','classes', accessToken)
        this.getStoreOne('colors','colors', accessToken)
        this.getStoreOne('brands-with-models','brandsWithModels', accessToken)
    }
}

export default new CarStore();
