export const saveBtn = (data, comment, setStatus, chgInspectionStatus, setModalNotification, t, setModalConfirmAction) => {
    const onSave = () => {
        const inspectionInfo = {
            id: data.id,
            status: 1,
            statusComment: comment,
        }
        setStatus(1)
        chgInspectionStatus(inspectionInfo.id, inspectionInfo.status, inspectionInfo.statusComment)
            .then((success) => {
                if (success) {
                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('changes_success_save'),
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                setModalNotification({
                    success: false,
                    onClose: () => setModalNotification({ isOpen: false }),
                    isOpen: true,
                    text: t('changes_not_save'),
                });
            });
    };
    setModalConfirmAction({
        onAction: onSave,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_save_changes'),
    });
};

export const rejectBtn = (data, comment, setStatus, chgInspectionStatus, setModalNotification, t, setModalConfirmAction) => {
    const onReject = () => {
        const inspectionInfo = {
            id: data.id,
            status: 2,
            statusComment: comment,
        }
        setStatus(2)
        chgInspectionStatus(inspectionInfo.id, inspectionInfo.status, inspectionInfo.statusComment)
            .then((success) => {
                if (success) {
                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('status_change'),
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                setModalNotification({
                    success: false,
                    onClose: () => setModalNotification({ isOpen: false }),
                    isOpen: true,
                    text: t('status_not_change'),
                });
            });
    };
    setModalConfirmAction({
        onAction: onReject,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_reject'),
    });
};

export const approveBtn = (data, comment, setStatus, chgInspectionStatus, setModalNotification, t, setModalConfirmAction) => {
    const onApprove = () => {
        const inspectionInfo = {
            id: data.id,
            status: 1,
            statusComment: comment,
        }
        setStatus(1)
        chgInspectionStatus(inspectionInfo.id, inspectionInfo.status, inspectionInfo.statusComment)
            .then((success) => {
                if (success) {
                    setModalNotification({
                        success: true,
                        onClose: () => setModalNotification({ isOpen: false }),
                        isOpen: true,
                        text: t('status_change'),
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                setModalNotification({
                    success: false,
                    onClose: () => setModalNotification({ isOpen: false }),
                    isOpen: true,
                    text: t('status_not_change'),
                });
            });
    };
    setModalConfirmAction({
        onAction: onApprove,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_approve'),
    });
};