import React from 'react';
import { t } from 'i18next';
import { ReactComponent as ClearIcon } from "../../assets/ClearIcon.svg"
import "./styles.scss";

const ConfirmActionModal = ({ onAction, onClose, text, confirmModalText }) => {

  return (
    <div className='modal_opacity'>
      <div className='modal-content-block'>
        <div className='main-confirm-title'>
          <p>{t('warning')}</p>
          <div className='clear_btn_ovol' onClick={() => onClose()}>
            <ClearIcon />
          </div>
        </div>
        <div className='comment_block'>
          <p><span>*</span> {t('confirm_patern')} {text}</p>
          <button className="confirm_btn" onClick={() => { onAction(); onClose() }}>
            <p>{confirmModalText || t('confirm')}</p>
          </button>
          <div className="reject_remark" onClick={() => onClose()}>
            <p>{t('reject')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmActionModal;
