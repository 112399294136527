import React, { Component } from 'react'
import NavMenu from '../../components/NavMenu'
import Drivers from '../../components/Drivers'
import TopHeader from '../../components/TopHeader'

export default class DriversPage extends Component {
    render() {
        
        const pageTitle = "drivers"

        return (<div className="row">
            <div className="col-1">
                <NavMenu />
            </div>
            <div className="col-11">
                <TopHeader pageTitle={pageTitle} />
                <div className="row">
                    <div className="col-12">
                        <Drivers />
                    </div>
                </div>
            </div>
        </div>)
    }
}
