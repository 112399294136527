import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { t } from "i18next";

@observer
class AddFAQ extends Component {
  state = {
    title: "",
    content: "",
    target: "All",
    error: false,
  };

  chgInp = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  addFAQ = () => {
    const { title, content, target } = this.state;
    if (title.length > 1 && content.length > 1 && target.length > 1) {
      this.props.addFAQ(
        this.state.title,
        this.state.content,
        this.state.target
      );
      this.props.close();
    } else {
      this.setState({
        error: true,
      });
    }
  };

  render() {
    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-4">{t('header')}</div>
          <div className="col-8">
            <input
              type="text"
              value={this.state.title}
              name="title"
              onChange={this.chgInp}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('message')}</div>
          <div className="col-8">
            <textarea
              name="content"
              value={this.state.content}
              onChange={this.chgInp}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-4">{t('audience')}</div>
          <div className="col-8">
            <select
              name="target"
              className="form-control"
              value={this.state.target}
              onChange={this.chgInp}
            >
              <option value="All">{t('all')}</option>
              <option value="Driver">{t('drivers')}</option>
              <option value="Client">{t('clients')}</option>
            </select>
          </div>
        </div>
        {this.state.error ? (
          <div className="row form-row">
            <div className="col-12 alert alert-danger">
              {t('all_fields_must_be_filled_in')}
            </div>
          </div>
        ) : null}
        <div className="row form-row">
          <div className="col-12 text-center">
            <button onClick={this.addFAQ} className="btn btn-success">
              {t('add')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  addFAQ: stores.FAQStore.addFAQ,
}));

export default withData(AddFAQ);
