import React, { Component } from "react";
import { observer } from "mobx-react";

import CatalogStore from "./mobx";
import CarsMark from "./CarsMark";
import CarClass from "./CarClass";
import CarColor from "./CarColor";
import Fares from "./Fares";
import City from "./City";
import OrderOptions from "./OrderOptions";

import "./Catalog.scss";
import FeesReasons from "./FeesReasons";
import { t } from "i18next";

@observer
class Catalog extends Component {
  render() {
    const { activeTab, chgActiveTab } = CatalogStore;
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <ul className="catalog-submenu">
              <li
                onClick={() => chgActiveTab(1)}
                className={activeTab === 1 ? "active" : null}
              >
                {t('marks_and_models')}
              </li>
              <li
                onClick={() => chgActiveTab(2)}
                className={activeTab === 2 ? "active" : null}
              >
                {t('color')}
              </li>
              <li
                onClick={() => chgActiveTab(3)}
                className={activeTab === 3 ? "active" : null}
              >
                {t('class')}
              </li>
              <li
                onClick={() => chgActiveTab(4)}
                className={activeTab === 4 ? "active" : null}
              >
                {t('tariff')}
              </li>
              <li
                onClick={() => chgActiveTab(5)}
                className={activeTab === 5 ? "active" : null}
              >
                {t('cities')}
              </li>
              <li
                onClick={() => chgActiveTab(7)}
                className={activeTab === 7 ? "active" : null}
              >
                {t('order_options')}
              </li>
              <li
                onClick={() => chgActiveTab(8)}
                className={activeTab === 8 ? "active" : null}
              >
                {t('fines')}
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {activeTab === 1 ? <CarsMark /> : null}
            {activeTab === 2 ? <CarColor /> : null}
            {activeTab === 3 ? <CarClass /> : null}
            {activeTab === 4 ? <Fares /> : null}
            {activeTab === 5 ? <City /> : null}
            {activeTab === 7 ? <OrderOptions /> : null}
            {activeTab === 8 ? <FeesReasons /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Catalog;
