import React, { Component } from 'react'
import NavMenu from '../../components/NavMenu'
import Catalog from '../../components/Catalog'
import { t } from 'i18next'

export default class CatalogPage extends Component {
    render() {
        return (<div className="row">
            <div className="col-1">
                <NavMenu />
            </div>
            <div className="col-11">
                <div className="row">
                    <div className="col">
                        <h3>{t('directory')}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <Catalog />
                    </div>
                </div>
            </div>
        </div>)
    }
}
