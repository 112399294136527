import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  fallbackLng: 'ua',
  lng: localStorage.getItem('language_name') || 'ua',
  resources: {
    ru: {
      translations: require('./locales/ru/translations.json'),
    },
    ua: {
      translations: require('./locales/ua/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
})

i18n.languages = ['ru', 'ua']

export default i18n
