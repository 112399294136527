import { observable } from "mobx";

class HeaderStore {
  @observable lengthHeader = null;
  @observable usersName = '';
  getLengthForHeader = (l) => {this.lengthHeader = l ? l : this.lengthHeader};
  getUsersNameForHeader = name => this.usersName = name

}

export default new HeaderStore();
