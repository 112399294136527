import LoginFromStore from "./components/LoginForm/mobx";
import CarsColorStore from "./components/Catalog/CarColor/mobx";
import CarsMarkStore from "./components/Catalog/CarsMark/mobx";
import CarsClassStore from "./components/Catalog/CarClass/mobx";
import RatesStore from "./components/Catalog/Rates/mobx";
import UsersStore from "./components/Users/mobx";
import PassengersStore from "./components/Passengers/mobx";
import DriversStore from "./components/Drivers/mobx";
import OrdersStore from "./components/Orders/mobx";
import CarsStore from "./components/Cars/mobx";
import StatisticsStore from "./components/Statistics/mobx";
import NewsStore from "./components/News/mobx";
import FeedbackStore from "./components/Feedback/mobx";
import FAQStore from "./components/FAQ/mobx";
import CityStore from "./components/Catalog/City/mobx";
import FaresStore from "./components/Catalog/Fares/mobx";
import OrderSourceStore from "./components/Catalog/OrderSource/mobx";
import OrderOptionsStore from "./components/Catalog/OrderOptions/mobx";
import GateHistoryStore from "./components/GateHistory/mobx";
import CashOutStoreStore from "./components/CashOut/mobx";
import CarInspectionsStore from "./pages/CarInspections/mobx";
import SettingsStore from "./components/Settings/mobx";
import PaymentsStore from "./components/Payments/mobx";
import FeesReasonsStore from "./components/Catalog/FeesReasons/mobx";
import InfoPageStore from "./pages/InfoPage/mobx";
import CarStore from "./pages/mobx"
import HeaderStore from "./components/TopHeader/mobx"

export default {
  LoginFromStore,
  CarsColorStore,
  CarsMarkStore,
  CarsClassStore,
  RatesStore,
  UsersStore,
  PassengersStore,
  DriversStore,
  OrdersStore,
  CarsStore,
  StatisticsStore,
  NewsStore,
  FeedbackStore,
  FAQStore,
  CityStore,
  OrderSourceStore,
  OrderOptionsStore,
  GateHistoryStore,
  CashOutStoreStore,
  CarInspectionsStore,
  SettingsStore,
  FaresStore,
  PaymentsStore,
  FeesReasonsStore,
  InfoPageStore,
  CarStore,
  HeaderStore
};
