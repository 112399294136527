import React, { useState } from 'react'
import { t } from 'i18next';
import { ReactComponent as CheckLineIcon } from '../../assets/CheckLineIcon.svg'
import './styles.scss'

export const Select = ({ data, change, datas, text }) => {
    const [isDropMenu, setDropMenu] = useState(false)
    const handleChange = (selectedData) => {
        setDropMenu(false)
        change(selectedData)
    }
    return (
        <div className='driver_input'>
            <p><span>*</span> {t(text)}</p>
            <div className={`select-country ${isDropMenu ? 'select-country-active' : ''}`} onClick={() => setDropMenu(!isDropMenu)}>
                <p>{t(data.name)}</p>
            </div>
            {isDropMenu &&
                <div className='country-options'>
                    {datas.map((selectedData) => (
                        <div
                            key={selectedData.id}
                            className={`country-options-single-option ${data.id === selectedData.id ? 'country-options-single-option-active' : ''}`}
                            onClick={() => handleChange(selectedData)}
                        >
                            <p>{t(selectedData.name)}</p>
                            {data.id === selectedData.id && <CheckLineIcon />}
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}
