import React, { useState } from "react";
import styles from "./styles.module.scss";
import { t } from "i18next";
import { branchIds, gpsStatuses } from "shared/variables";
import { ReactComponent as CheckArrowIcon } from "../../../assets/CheckArrowIcon.svg";

const ManualLocationForm = ({ gpsRegions, getRegions, setGpsRegion }) => {
  const [currentBranchId, setCurrentBranchId] = useState(branchIds.carma);

  const handleChange = (value) => {
    getRegions({ branchId: value });
    setCurrentBranchId(value);
  };

  const setRegion = async (item) => {
    let status = gpsStatuses.unblock;

    if (!item.isBlocked) {
      status = gpsStatuses.block;
    }

    await setGpsRegion({
      status,
      branchId: currentBranchId,
      regionCode: item.regionCode,
    });

    await getRegions({ branchId: currentBranchId });
  };

  const branchIdRadio = (
    <div className={styles.radioGroup}>
      <div
        className={`${styles.radioOptionLeft} ${
          currentBranchId === 1 ? styles.radioOptionActive : ""
        }`}
        onClick={() => handleChange(1)}
      >
        <p>Carma</p>
      </div>
      <div
        className={`${styles.radioOptionRight} ${
          currentBranchId === 2 ? styles.radioOptionActive : ""
        }`}
        onClick={() => handleChange(2)}
      >
        <p>LT taxi</p>
      </div>
    </div>
  );

  const regionSelect = gpsRegions && (
    <>
      <ul className={styles.regionSelect}>
        {gpsRegions.map((item, index) => (
          <li
            key={`${item.regionCode}_${index}`}
            onClick={() => setRegion(item)}
          >
            <div className={styles.leftSide}>
              {item.isBlocked ? (
                <div className={styles.checkBoxActive}>
                  <CheckArrowIcon />
                </div>
              ) : (
                <div className={styles.checkBox} />
              )}
            </div>
            <div className={styles.rightSide}>
              <p>
                {t(item.regionCode)} ({item.regionCode})
              </p>
            </div>
          </li>
        ))}
      </ul>
    </>
  );

  const inputs = [
    { topicTitle: "gps_title", customComponent: branchIdRadio },
    { topicTitle: "region", customComponent: regionSelect },
  ];
  return (
    <div className={styles.createNewWrap}>
      {inputs.map((item, index) => {
        return (
          <li className={styles.newInputWrap} key={`${item.name}_${index}`}>
            {item.customComponent === branchIdRadio && (
              <span className={styles.gpsTitle}>{t(item.topicTitle)}</span>
            )}
            {item.customComponent === regionSelect && (
              <div className={styles.tableHeader}>
                <div className={styles.leftBar} />
                <div className={styles.rightBar}>
                  <p>{t(item.topicTitle)}</p>
                </div>
              </div>
            )}
            {item.customComponent ? item.customComponent : null}
          </li>
        );
      })}
    </div>
  );
};

export default ManualLocationForm;
