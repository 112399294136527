import React, { useEffect, useMemo } from "react";
import NavMenu from "../../components/NavMenu";
import TopHeader from "../../components/TopHeader";
// import { store } from '../../components/Table/store';
import DriverDetailsPage from "../../components/DriverDetailsPage";
import { inject, observer } from "mobx-react";
import InspectionDetailsPage from "../../components/InspectionDetailsPage";
import { useHistory } from "react-router-dom";
import { TechReviewInfo } from "./types";
import LoadingElement from "../../components/LoadingElement";

const InfoPage = ({
  apiInfo,
  data,
  loading,
  classes,
  colors,
  brandsWithModels,
  activateDriver,
  blockDriver,
  unblockDriver,
  delDriver,
  updateDriver,
  chgInspectionStatus,
  addBonus,
  getDriver
}) => {
  const history = useHistory();

  const selectPage = () => {
    switch (apiInfo.special) {
      case TechReviewInfo.DRIVER_INFO:
        return (
          <DriverDetailsPage
            data={data}
            classes={classes}
            colors={colors}
            brandsWithModels={brandsWithModels}
            activateDriver={activateDriver}
            blockDriver={blockDriver}
            unblockDriver={unblockDriver}
            delDriver={delDriver}
            updateDriver={updateDriver}
            addBonus={addBonus}
            getDriver={getDriver}
          />
        );
      case TechReviewInfo.INSPECTION_INFO:
        return (
          <InspectionDetailsPage
            data={data}
            classes={classes}
            colors={colors}
            brandsWithModels={brandsWithModels}
            chgInspectionStatus={chgInspectionStatus}
          />
        );
      default:
        return "loading";
    }
  };

  useEffect(() => {
    if (!apiInfo.id) history.goBack();
  }, []);

  let pageTitle;

  switch (apiInfo.special) {
    case TechReviewInfo.DRIVER_INFO:
      pageTitle = "drivers";
      break;
    case TechReviewInfo.INSPECTION_INFO:
      pageTitle = "technical_inspections";
      break;
    default:
      pageTitle = "default_title";
      break;
  }
  const isLoading = !data || loading

  return (
    <div className="row">
      <div className="col-1">
        <NavMenu />
      </div>
      <div className="col-11">
        <TopHeader pageTitle={pageTitle} />
        <div className="row">
          <div className="col-12">
            {isLoading ? <LoadingElement /> : selectPage()}
          </div>
        </div>
      </div>
    </div>
  );
};

const withData = inject((stores) => ({
  headerName: stores.InfoPageStore.headerName,
  apiInfo: stores.InfoPageStore.apiInfo,
  data: stores.InfoPageStore.data,
  loading: stores.InfoPageStore.loading,
  classes: stores.CarStore.classes,
  colors: stores.CarStore.colors,
  brandsWithModels: stores.CarStore.brandsWithModels,
  activateDriver: stores.DriversStore.activateDriver,
  blockDriver: stores.DriversStore.blockDriver,
  unblockDriver: stores.DriversStore.unblockDriver,
  delDriver: stores.DriversStore.delDriver,
  updateDriver: stores.DriversStore.updateDriver,
  chgInspectionStatus: stores.CarInspectionsStore.chgInspectionStatus,
  addBonus: stores.DriversStore.addBonus,
  getDriver: stores.DriversStore.getDriver,
}));

export default observer(withData(InfoPage));
