import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { t } from "i18next";

const CreateOrder = observer(
  ({
    cityList,
    classList,
    getCarClassData,
    getCityData,
    newOrder,
    chgOrderField,
    optionsList,
    getOptionsData,
    chkboxChange,
    findedStrResult,
    findedStrToResult,
    clearResults,
    chgAddressField,
    makeNewOrder,
    getEstimatePrice,
    getTariff,
    clearOrderObj,
    close,
  }) => {
    const createScript = () => {
      const scriptTag = document.createElement("script");
      scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyComxUEAcX-c__nGZOrJHKQfCyKY0hbrjA&libraries=places&language=ru&region=UA/`;
      scriptTag.async = false;
      scriptTag.defer = false;
      if (document.body) {
        document.body.appendChild(scriptTag);
        let div = document.createElement("div");
        div.className = "mapOrder";
        document.body.append(div);
      }
      return scriptTag;
    };

    const createOrder = () => {
      makeNewOrder();
      close();
    };

    const getDistanceVal = () => {
      const directionsService = new window.google.maps.DirectionsService();
      if (newOrder.fromData?.pointId && newOrder.toData?.pointId) {
        directionsService.route(
          {
            origin: { placeId: newOrder.fromData.pointId },
            destination: { placeId: newOrder.toData.pointId },
            travelMode: "DRIVING",
          },
          function (response, status) {
            if (status === "OK") {
              chgAddressField(
                "distance",
                response.routes["0"].legs["0"].distance.value
              );
            } else {
              console.log("Get distance error:", status, response);
            }
          }
        );
      }
    };

    useEffect(() => {
      if (window.google) {
        getDistanceVal();
      }
    }, [newOrder.from, newOrder.to, getDistanceVal]);

    const initMap = () => {
      new window.google.maps.Map(
        document.getElementsByClassName("mapOrder")[0]
      );

      const addressFrom = document.getElementById("addressFrom");
      const addressTo = document.getElementById("addressTo");
      const searchBox1 = new window.google.maps.places.SearchBox(addressFrom);
      const searchBox2 = new window.google.maps.places.SearchBox(addressTo);

      searchBox1.addListener("places_changed", () => {
        const places = searchBox1.getPlaces();
        chgAddressField("from", places[0].formatted_address);
        chgAddressField("fromData", {
          name: places[0].formatted_address,
          lat: places[0].geometry.location.lat(),
          lon: places[0].geometry.location.lng(),
          pointId: places[0].place_id,
        });
      });

      searchBox2.addListener("places_changed", () => {
        const places = searchBox2.getPlaces();
        chgAddressField("to", places[0].formatted_address);
        chgAddressField("toData", {
          name: places[0].formatted_address,
          lat: places[0].geometry.location.lat(),
          lon: places[0].geometry.location.lng(),
          pointId: places[0].place_id,
        });
      });
      // https://developers.google.com/maps/documentation/javascript/examples/places-searchbox#maps_places_searchbox-javascript
    };

    useEffect(() => {
      clearOrderObj();
      getCarClassData();
      getCityData();
      getOptionsData();

      // Инициализируем карту на странице
      if (!window.google) {
        const script = createScript();
        script.addEventListener("load", () => {
          setTimeout(() => initMap(), 2000);
        });
      } else {
        setTimeout(() => initMap(), 2000);
      }
    }, []);

    const chgInput = (evt) => {
      chgOrderField(evt.target.name, evt.target.value);
      if (
        newOrder.distance > 0 &&
        newOrder.carClassId.length > 0 &&
        newOrder.cityId.length > 0 &&
        newOrder.tariffId.length > 0
      ) {
        getEstimatePrice();
      }
      if (
        newOrder.cityId.length > 0 &&
        newOrder.carClassId.length > 0 &&
        (evt.target.name === "cityId" || evt.target.name === "carClassId")
      ) {
        getTariff();
        setTimeout(() => getEstimatePrice(), 1000);
      }
    };

    const chgCheckboxInput = (evt) => {
      chkboxChange(evt.target.value);
      if (
        newOrder.distance > 0 &&
        newOrder.carClassId.length > 0 &&
        newOrder.cityId.length > 0 &&
        newOrder.tariffId.length > 0
      ) {
        getEstimatePrice();
      }
    };

    const cityListOptions = cityList.map((item) => {
      return <option value={item.id}>{item.name}</option>;
    });

    const classListOptions = classList.map((item) => {
      return <option value={item.id}>{item.name}</option>;
    });

    const optionListFormated = optionsList.map((item) => {
      return (
        <div className="col-3">
          <input
            name={item.name}
            type="checkbox"
            value={item.id}
            checked={newOrder.orderPreferences.includes(item.id)}
            onChange={chgCheckboxInput}
          />{" "}
          {item.name}
        </div>
      );
    });

    const readyForCreate =
      newOrder.phoneNumber &&
      newOrder.clientName &&
      newOrder.cityId &&
      newOrder.fromData &&
      newOrder.toData &&
      newOrder.carClassId &&
      newOrder.priceEstimated &&
      newOrder.tariffId &&
      newOrder.distance
        ? false
        : true;

    return (
      <>
        <div className="row form-row">
          <div className="col-12">{t('clients_number')}:</div>
          <div className="col-12">
            <input
              type="text"
              value={newOrder.phoneNumber}
              name="phoneNumber"
              onChange={chgInput}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('client_name')}:</div>
          <div className="col-12">
            <input
              type="text"
              value={newOrder.clientName}
              name="clientName"
              onChange={chgInput}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('city')}:</div>
          <div className="col-12">
            <select
              name="cityId"
              className="form-control"
              value={newOrder.cityId}
              onChange={chgInput}
            >
              <option value="">-- {t('select')} --</option>
              {cityListOptions}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('from_where')}:</div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={newOrder.from}
              name="from"
              onChange={chgInput}
              className="form-control"
              id="addressFrom"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('where')}:</div>
        </div>
        <div className="row form-row">
          <div className="col-12">
            <input
              type="text"
              value={newOrder.to}
              name="to"
              onChange={chgInput}
              className="form-control"
              id="addressTo"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('car_class')}:</div>
          <div className="col-12">
            <select
              name="carClassId"
              value={newOrder.carClassId}
              className="form-control"
              onChange={chgInput}
            >
              <option value="">-- {t('select')} --</option>
              {classListOptions}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-12">{t('additional_services')}:</div>
        </div>
        <div className="row form-row">{optionListFormated}</div>
        <div className="row form-row">
          <div className="col-12">{t('comment')}:</div>
          <div className="col-12">
            <input
              type="text"
              value={newOrder.comment}
              name="comment"
              onChange={chgInput}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-3">{newOrder.priceEstimated} грн</div>
          <div className="col-3">{newOrder.distance} м</div>
          <div className="col-6 text-right">
            <button
              className="btn btn-primary"
              onClick={createOrder}
              disabled={readyForCreate}
            >
              {t('create_order')}
            </button>
          </div>
        </div>
      </>
    );
  }
);

const withData = inject((stores) => ({
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
  cityList: stores.CityStore.cityList,
  getCityData: stores.CityStore.getCityData,
  newOrder: stores.OrdersStore.newOrder,
  chgOrderField: stores.OrdersStore.chgOrderField,
  getOptionsData: stores.OrderOptionsStore.getOptionsData,
  optionsList: stores.OrderOptionsStore.optionsList,
  chkboxChange: stores.OrdersStore.chkboxChange,
  findedStrResult: stores.OrdersStore.findedStrResult,
  findedStrToResult: stores.OrdersStore.findedStrToResult,
  clearResults: stores.OrdersStore.clearResults,
  chgAddressField: stores.OrdersStore.chgAddressField,
  makeNewOrder: stores.OrdersStore.makeNewOrder,
  getEstimatePrice: stores.OrdersStore.getEstimatePrice,
  getTariff: stores.OrdersStore.getTariff,
  clearOrderObj: stores.OrdersStore.clearOrderObj,
}));

export default withData(CreateOrder);
