import { observable } from "mobx";
import axios from "axios";

import { apiIdentity } from "../../../config";

class CityStore {
  @observable isError = false;
  @observable cityList = [];

  getCityData = () => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(`${apiIdentity}catalog/cities`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.cityList = [...response.data.data];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addCity = (city, lon, lat, orderFreeWait) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .post(
        `${apiIdentity}catalog/cities`,
        { name: city, latitude: lat, longitude: lon, orderFreeWait },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCityData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  delCity = (id) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .delete(`${apiIdentity}catalog/cities/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCityData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updCityName = (id, name, latitude, longitude, orderFreeWait) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log({ id, name, latitude, longitude });

    axios
      .put(
        `${apiIdentity}catalog/cities/${id}`,
        { id, name, latitude, longitude, orderFreeWait },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        if (response.data.success === false) {
          this.isError = true;
        } else {
          this.getCityData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export default new CityStore();
