import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Input, Button, Icon } from "antd";
import Table from '../../components/Table/index'
import { t } from "i18next";
import { TechReviewInfo } from "../InfoPage/types";
import typeTable from "../../components/Table/constants/typesTable";
import { takePageElements } from "shared/constants";

@observer
class CarInspections extends Component {
  state = {
    skip: 0,
    take: takePageElements,
    showModal: false,
    activeInspectionID: 0,
    filterCarBrand: "",
    filterCarModel: "",
    filterDriverName: "",
    filterDriverCallSign: "",
    filterGovNumber: "",
    filterDateFrom: null,
    filterDateTo: null,
  };

  close = () => {
    this.setState({
      showModal: false,
      activeInspectionID: 0,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeInspectionID: id,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t('clear')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  columns = [
    {
      title: t('first_name_last_name'),
      dataIndex: "driverName",
    },
    {
      title: t('car'),
      dataIndex: "carBrandAndModel",
    },
    {
      title: t('application_date'),
      dataIndex: "createdAt",
    },
    {
      title: t('country'),
      dataIndex: "country",
    },
    {
      title: t('status'),
      dataIndex: "status",
    },
  ];

  componentDidMount() {
    this.props.getCarBrandsData();
  }

  closeCreateDriver = () => {
    this.setState({
      showCreateDriver: false,
    });
  };

  openCreateDriver = () => {
    this.setState({
      showCreateDriver: true,
    });
  };

  applyFilters = () => {
    const filterOptions = {
      skip: this.state.skip,
      take: this.state.take,
      brandId: this.state.filterCarBrand,
      modelId: this.state.filterCarModel,
      driverName: this.state.filterDriverName,
      driverCallSign: this.state.filterDriverCallSign,
      govNumber: this.state.filterGovNumber,
    };
    if (this.state.filterDateFrom) {
      const { filterDateFrom } = { ...this.state };
      filterOptions.dateFrom = moment(filterDateFrom).utcOffset(0).endOf("day");
    }

    if (this.state.filterDateTo) {
      const { filterDateTo } = { ...this.state };
      filterOptions.dateTo = filterDateTo.utcOffset(0).endOf("day");
    }

    this.props.getInspections(filterOptions);
  };

  cancelFilters = () => {
    this.setState({
      filterCarBrand: "",
      filterCarModel: "",
      filterDriverName: "",
      filterDriverCallSign: "",
      filterGovNumber: "",
      filterDateFrom: null,
      filterDateTo: null,
    });
    this.props.getInspections({
      skip: this.state.skip,
      take: this.state.take,
    });
  };

  chgInp = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  chgDateRange = (date) => {
    if (date.length === 2) {
      this.setState({
        filterDateFrom: date[0].startOf("day"),
        filterDateTo: date[1].endOf("day"),
      });
    }
    console.log(date);
  };

  render() {
    const { inspectionsList, carBrands } = this.props;

    const carBrandList = carBrands.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    let carModelList = "";
    if (this.state.filterCarBrand.length > 0) {
      const carModels = carBrands.filter((item) => {
        if (item.id === this.state.filterCarBrand) {
          return true;
        }
        return false;
      });

      carModelList = carModels[0].models.map((model) => {
        return (
          <option value={model.id} key={model.id}>
            {model.name}
          </option>
        );
      });
    }

    return (
      <div>
      <Table
        headerName={'inspection info'}
        keys={typeTable.inspection.keys}
        showKeys={'inspection'}
        special={TechReviewInfo.INSPECTION_INFO}
        getData={this.props.getInspections}
        data={this.props.inspectionsList}
        changeLengthForHeader={this.props.getLengthForHeader}
        totalCount={this.props.inspectionAmount}
        getUsersNameForHeader={this.props.getUsersNameForHeader}
        openInfoPage={this.props.openInfoPage}
        chgInspectionStatus={this.props.chgInspectionStatus}
        cityList={this.props.cityList}
        getCityData={this.props.getCityData}
        addCity={this.props.addCity}
        delCity={this.props.delCity}
        updCityName={this.props.updCityName}
        updateDriver={this.props.updateDriver}
        brandList={this.props.brandList}
        brandsWithModels={this.props.brandsWithModels}
        carClassList={this.props.carClassList}
        getCarClassData={this.props.getCarClassData}
      />
    </div>
    );
  }
}

const withData = inject((stores) => ({
  inspectionsList: stores.CarInspectionsStore.inspectionsList.map((elem) => { if(!elem.country) {elem.country = 'UA'} ;return elem}),
  getInspections: stores.CarInspectionsStore.getInspections,
  carBrands: stores.CarsMarkStore.carBrands,
  getCarBrandsData: stores.CarsMarkStore.getCarBrandsData,
  openInfoPage: stores.InfoPageStore.openInfoPage,
  driversList: stores.DriversStore.driversList,
  inspectionAmount: stores.CarInspectionsStore.inspectionAmount,
  getDriversData: stores.DriversStore.getDriversData,
  getDriver: stores.DriversStore.getDriver,
  block: stores.DriversStore.block,
  getLengthForHeader: stores.HeaderStore.getLengthForHeader,
  getUsersNameForHeader: stores.HeaderStore.getUsersNameForHeader,
  chgInspectionStatus: stores.CarInspectionsStore.chgInspectionStatus,
  cityList: stores.CityStore.cityList,
  getCityData: stores.CityStore.getCityData,
  addCity: stores.CityStore.addCity,
  delCity: stores.CityStore.delCity,
  updCityName: stores.CityStore.updCityName,
  updateDriver: stores.DriversStore.updateDriver,
  brandList: stores.CarsMarkStore.carBrands,
  brandsWithModels: stores.CarStore.brandsWithModels,
  carClassList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
}));

export default withData(CarInspections);
