import React, { useState, useMemo, useEffect } from "react";
import { t } from "i18next";
import { ReactComponent as CheckLineIcon } from "../../assets/CheckLineIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";
import { ReactComponent as TechPassportIcon } from "../../assets/TechPassportIcon.svg";
import { ReactComponent as NoneInfoIcon } from "../../assets/NoneInfoIcon.svg";
import { ReactComponent as TrashIcon } from "../../assets/TrashIcon.svg";
import { CarInfo } from "./components/CarInfo";
import ConfirmActionModal from "../ConfirmActionModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalChangesNotification from "components/ModalChangesNotification";
import { delBtn, blockBtn, unblockBtn, approveBtn, saveBtn } from "./helpers";
import { tryAddBonuses } from "./helpers/tryAddBonuses";
import "./styles.scss";
import { accessLogins } from "config";
import AddMoneyDriver from "./components/AddMoneyDriver";
import { addBalanceDriver } from "./helpers/addBalanceDriver";
import { accessChangeBalanceLogins } from "config";

const DriverDetailsPage = ({
  data,
  classes,
  colors,
  brandsWithModels,
  activateDriver,
  blockDriver,
  unblockDriver,
  delDriver,
  updateDriver,
  addBonus,
  getDriver,
}) => {
  const [modalConfirmAction, setModalConfirmAction] = useState({
    onAction: () => {},
    onClose: () => setModalConfirmAction({ isOpen: false }),
    isOpen: false,
    text: "",
  });

  const [oldUser, setOldUser] = useState(JSON.parse(JSON.stringify(data)));

  const [modalNotification, setModalNotification] = useState({
    success: false,
    onClose: () => setModalNotification({ isOpen: false }),
    isOpen: false,
    text: "",
  });

  const car = data.carInfo;
  const history = useHistory();
  const driver = data.driver;
  const infoCarsId = data.infoCarsId;
  const [isBlock, setIsBlock] = useState(data.driver.isBlocked);
  const [isActivated, setIsActivated] = useState(driver.isActivated);

  const [firstName, setFirstName] = useState(driver?.firstName);
  const [lastName, setLastName] = useState(driver?.lastName);
  const [callSign, setCallSign] = useState(driver?.callSign);
  const [phoneNumber, setPhoneNumber] = useState(driver?.phone);
  const [emailPost, setEmailPost] = useState(driver?.email);
  const [bonusCount, setBonusCount] = useState("");
  const [bonuses, setBonuses] = useState(driver?.bonuses);
  const [access, setAccess] = useState(false);
  const [accessChangeBalance, setAccessChangeBalance] = useState(false);
  const [changedBalance, onChangeChangedBalance] = useState("");

  const [cars, setCars] = useState([]);

  useEffect(() => {
    if (car && brandsWithModels.items && classes && colors && infoCarsId) {
      const cars = car.map((c) => {
        const id = c.id;
        const car = infoCarsId.find((e) => e.id === id);

        const classesCar = classes.find((e) => e.id === car.classId);
        const colorCar = colors.find((e) => e.id === car.colorId);
        const carBrend = brandsWithModels.items.find(
          (e) => e.id === car.brandId
        );
        const carModel = carBrend.models.find((c) => c.id === car.modelId);

        return {
          ...car,
          carClass: classesCar,
          color: colorCar,
          brend: carBrend,
          model: carModel,
          numberCar: "",
          files: c.files,
          id,
          changes: false,
        };
      });

      setCars(cars);
    }
  }, [brandsWithModels.items, car, classes, colors, infoCarsId]);

  const memoCarsInfo = useMemo(
    () =>
      cars.map((c) => (
        <CarInfo
          car={c}
          photos={c.files}
          key={c.id}
          id={c.id}
          classes={classes}
          colors={colors}
          brandsWithModels={brandsWithModels}
        />
      )),
    [cars]
  );

  const handleChangeFirstName = (event) => {
    const inputValue = event.target.value;
    const regex = /^[^\d]*$/u;

    if (regex.test(inputValue) || inputValue === "") {
      setFirstName(inputValue);
    }
  };

  const handleChangeLastName = (event) => {
    const inputValue = event.target.value;
    const regex = /^[^\d]*$/u;

    if (regex.test(inputValue) || inputValue === "") {
      setLastName(inputValue);
    }
  };

  const handleChangeCallSign = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      if (inputValue.length <= 7) {
        setCallSign(inputValue);
      }
    }
  };

  useEffect(() => {
    const userLogin = localStorage.getItem("login");

    if (accessChangeBalanceLogins && accessChangeBalanceLogins.length > 0) {
      switch (true) {
        case accessChangeBalanceLogins
          .split(",")
          .map((login) => login.trim())
          .includes(userLogin):
          setAccessChangeBalance(true);
          break;
        default:
          setAccessChangeBalance(false);
          break;
      }
    }

    if (accessLogins && accessLogins.length > 0) {
      if (
        accessLogins
          .split(",")
          .map((login) => login.trim())
          .includes(userLogin)
      ) {
        setAccess(true);
      } else {
        setAccess(false);
      }
    } else {
      setAccess(true);
    }
  }, []);

  const handleChangePhoneNumber = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;

    if (inputValue.length <= 16 && regex.test(inputValue)) {
      setPhoneNumber(inputValue);
    }
  };

  const handleChangeEmailPost = (event) => {
    const inputValue = event.target.value;
    setEmailPost(inputValue);
  };

  const handleAddBonuses = (event) => {
    let inputValue = event.target.value.replace(/\D/g, "");
    inputValue = inputValue.replace(/^0+/, "");
    setBonusCount(inputValue);
  };

  const onAddBalance = async () => {
    onChangeChangedBalance("");
    await addBalanceDriver({ balance: changedBalance, driverId: driver.id });
    await getDriver(driver.id);
  };

  return (
    <div className="driver_details_page">
      {modalConfirmAction.isOpen && (
        <ConfirmActionModal {...modalConfirmAction} />
      )}
      {modalNotification.isOpen && (
        <ModalChangesNotification {...modalNotification} />
      )}
      <p className="drivers_data_title">{t("drivers_data")}</p>
      <div className="drivers_info_block">
        <div className="drivers_photo">
          {driver.photo ? (
            <img src={driver.photo} alt="" />
          ) : (
            <div className="drivers_photo_none">
              <div className="drivers_circle_none_photo">
                <UserIcon />
              </div>
            </div>
          )}
        </div>
        <div className="drivers_info_grounds">
          <div className="drivers_info_ground">
            <div className="driver_input">
              <p>
                <span>*</span> {t("name")}
              </p>
              <input value={firstName} onChange={handleChangeFirstName} />
            </div>
            <div className="driver_input">
              <p>
                <span>*</span> {t("last_name")}
              </p>
              <input value={lastName} onChange={handleChangeLastName} />
            </div>
          </div>
          <div className="drivers_info_ground">
            <div className="driver_input pointer_none">
              <p>
                <span>*</span> {t("country")}
              </p>
              <input value={t(driver?.country)} />
            </div>
            <div className="driver_input">
              <p>
                <span>*</span> {t("call_sign")}
              </p>
              <input value={callSign} onChange={handleChangeCallSign} />
            </div>
          </div>
          <div className="drivers_info_ground">
            <div className="driver_input">
              <p>
                <span>*</span> {t("mobile_number")}
              </p>
              <input value={phoneNumber} onChange={handleChangePhoneNumber} />
            </div>
            <div className="driver_input">
              <p>
                <span>*</span> {t("email")}
              </p>
              <input value={emailPost} onChange={handleChangeEmailPost} />
            </div>
          </div>
        </div>
      </div>
      <p className="drivers_data_title" style={{ paddingTop: "30px" }}>
        {t("drivers_license")}
      </p>
      <div className="driver_license_block" style={{ paddingBottom: "30px" }}>
        <div className="driver_license_single_item">
          {driver.driverLicense ? (
            <img src={driver.driverLicense} alt="" />
          ) : (
            <div className="driver_license_block_none">
              <div className="driver_license_block_none_icon">
                <TechPassportIcon />
              </div>
            </div>
          )}
        </div>
      </div>
      {memoCarsInfo}
      <div className="buttons_block">
        {accessChangeBalance && (
          <AddMoneyDriver
            balance={driver.balance}
            changedBalance={changedBalance}
            onChangeChangedBalance={(event) =>
              onChangeChangedBalance(event.target.value)
            }
            onAddBalance={onAddBalance}
          />
        )}
        {access && (
          <div className="drivers_add_bonuses">
            <div className="add_bonus_block">
              <div className="driver_input">
                <p>
                  <span>*</span> {t("bonuses_cost")} {bonuses}
                </p>
                <input
                  placeholder={t("replenish_bonuses")}
                  value={bonusCount}
                  onChange={handleAddBonuses}
                />
              </div>
              <button
                className={`add_bonus_button ${
                  !bonusCount ? "disabled_button" : ""
                }`}
                onClick={() =>
                  tryAddBonuses(
                    driver,
                    addBonus,
                    bonusCount,
                    setModalNotification,
                    setModalConfirmAction,
                    t,
                    setBonuses,
                    setBonusCount
                  )
                }
              >
                {t("replenish")}
              </button>
            </div>
          </div>
        )}
        <div className="info_buttons">
          <button
            className="main-button delete_btn"
            onClick={() =>
              delBtn(driver, delDriver, history, setModalConfirmAction, t)
            }
          >
            <TrashIcon />
            {t("delete_driver")}
          </button>
          {isBlock ? (
            <button
              className="main-button"
              onClick={() =>
                unblockBtn(
                  driver,
                  unblockDriver,
                  setIsBlock,
                  setModalNotification,
                  setModalConfirmAction,
                  t
                )
              }
            >
              <NoneInfoIcon />
              {t("unblock")}
            </button>
          ) : (
            <button
              className="main-button"
              onClick={() =>
                blockBtn(
                  driver,
                  blockDriver,
                  setIsBlock,
                  setModalNotification,
                  setModalConfirmAction,
                  t
                )
              }
            >
              <NoneInfoIcon />
              {t("add_block")}
            </button>
          )}
          <div className="border-buttons" />
          {isActivated && (
            <button
              className="aprove-button"
              onClick={() =>
                saveBtn(
                  driver,
                  firstName,
                  lastName,
                  callSign,
                  phoneNumber,
                  emailPost,
                  oldUser,
                  updateDriver,
                  setModalNotification,
                  setModalConfirmAction,
                  setPhoneNumber,
                  setCallSign,
                  t
                )
              }
            >
              <CheckLineIcon />
              {t("save_changes")}
            </button>
          )}
          <button
            className="aprove-button"
            onClick={() =>
              approveBtn(
                driver,
                activateDriver,
                setIsActivated,
                setModalNotification,
                setModalConfirmAction,
                t
              )
            }
          >
            <CheckLineIcon />
            {isActivated ? t("re-aprove") : t("aprove")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DriverDetailsPage;
