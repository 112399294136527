export const tryAddBonuses = (driver, addBonus, bonusCount, setModalNotification, setModalConfirmAction, t, setBonuses, setBonusCount) => {
    const onApprove = async () => {
        try {
            const response = await addBonus(bonusCount, driver.id);
            if (response.status === 204) {
                const bonusAmount = Number(bonusCount);
                setBonuses(driver?.bonuses + bonusAmount);
                setBonusCount("")
                setModalNotification({
                    success: true,
                    onClose: () => setModalNotification({ isOpen: false }),
                    isOpen: true,
                    text: t('add_bonus_success'),
                });
            }
        } catch (error) {
            setBonuses(driver?.bonuses);
            setModalNotification({
                success: false,
                onClose: () => setModalNotification({ isOpen: false }),
                isOpen: true,
                text: t('add_bonus_not_success'),
            });
        }
    };    
    setModalConfirmAction({
        onAction: onApprove,
        onClose: () => setModalConfirmAction({ isOpen: false }),
        isOpen: true,
        text: t('confirm_add_bonus'),
    });
};