import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { MdDeleteForever } from 'react-icons/md'
import ModalConfirmDelete from "../../ModalConfirmDelete"

import { Table } from 'antd';
import { t } from 'i18next';


@observer
class CarColor extends Component {
  state = {
    colorName: '',
    colorID: null,
    updColorname: '',
    modalSettings : {
      onYes : () => {},
      onClose : () => {},
      isOpen : false,
      text : null
    },
  }

  carColumns = [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('color'),
      dataIndex: 'name',
    },
    {
      title: t('actions'),
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (text, record) => <MdDeleteForever onClick={() => this.delBtn(record)}/>,
    },
  ];

  
  componentDidMount(){
    this.props.getColorData()
  }
  
  delBtn = record => {
    const recordId = record.id
    this.setState({
      modalSettings: {
        onYes: () => this.props.delCarColor(recordId),
        onClose: () => this.setState({ modalSettings: { isOpen: false } }),
        isOpen: true,
        text: t('confirm_delete')
      }
    });
  }

  chgInput = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  editColor = color => {
    this.setState({
      colorID: color.id,
      updColorname: color.name,
    })
  }

  updCarColor = (id, name) => {
    this.setState({
      colorID: null,
      updColorname: '',
    })
    this.props.updCarColor(id, name)
  }

  cancelEdit = () => {
    this.setState({
      colorID: null,
      updColorname: '',
    })
  }

  render() {
    const colorList = this.props.colorList

    return (<>
      <div className="row">
        <div className="col-12">
          {this.state.colorID ? <>{t('edit_color')}</> : <>{t('add_color')}</>}
        </div>
      </div>
      
      {
        this.state.colorID ?
        <div className="row" style={{paddingBottom: "20px"}}>
          <div className="col-4">
            <input type="text" name="updColorname" value={this.state.updColorname} onChange={this.chgInput} className="form-control" />
          </div>
          <div className="col-4">
            <button onClick={() => this.updCarColor(this.state.colorID, this.state.updColorname)} className="btn btn-success" style={{marginRight: "20px"}}>{t('edit')}</button>
            <button onClick={this.cancelEdit} className="btn btn-warning">{t('cancel')}</button>
          </div>
        </div>
        :
        <div className="row" style={{paddingBottom: "20px"}}>
          <div className="col-4">
            <input type="text" name="colorName" value={this.state.colorName} onChange={this.chgInput} className="form-control" />
          </div>
          <div className="col-4">
            <button onClick={() => this.props.addCarColor(this.state.colorName)} className="btn btn-success">{t('add')}</button>
          </div>
        </div>
      }
      <ModalConfirmDelete {...this.state.modalSettings} />
      <div>
        <Table columns={this.carColumns} dataSource={colorList}  onRow={ (record, rowIndex) => ({
          onDoubleClick: () => {
            this.editColor(record)
          }
        })}/>
      </div></>
    )
  }
}

const withData = inject(stores => ({
  colorList: stores.CarsColorStore.colorList,
  getColorData: stores.CarsColorStore.getColorData,
  addCarColor: stores.CarsColorStore.addCarColor,
  delCarColor: stores.CarsColorStore.delCarColor,
  updCarColor: stores.CarsColorStore.updCarColor,
}))

export default withData(CarColor)
