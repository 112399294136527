import React from "react";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import { t } from "i18next";

const EditFee = observer(
  ({ id, amount, fineReason, editFee, isEditError, showAddNewFee }) => {
    console.log("amount", id, amount, fineReason);

    return (
      <>
        <Formik
          initialValues={{ defaultFee: amount, description: fineReason }}
          validateOnChange={false}
          validateOnBlur={false}
          validate={(values) => {
            const errors = {};

            if (!values.description) {
              errors.description = t('required_field');
            } else if (values.description.length < 5) {
              errors.description = t('reason_for_the_penalty');
            }

            if (!values.defaultFee) {
              errors.defaultFee = t('required_field');
            } else if (!Number.isInteger(values.defaultFee)) {
              errors.defaultFee = t('incorrect_penalty_value');
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            editFee(id, values.description, values.defaultFee);
            showAddNewFee();
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="row">
                  {isEditError ? (
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        {isEditError}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-5">
                    <div className="col-12">
                      <b>{t('amount_for_the_penalty')}:</b>
                    </div>
                    <div className="col-12">
                      <input
                        type="number"
                        name="defaultFee"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.defaultFee}
                      />
                    </div>
                    {errors.defaultFee &&
                    touched.defaultFee &&
                    errors.defaultFee ? (
                      <div className="col-12" style={{ marginTop: "20px" }}>
                        <div className="alert alert-danger" role="alert">
                          {errors.defaultFee &&
                            touched.defaultFee &&
                            errors.defaultFee}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-5">
                    <div className="col-12">
                      <b>{t('reason_for_the_penalty')}:</b>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                    </div>
                    {errors.description &&
                    touched.description &&
                    errors.description ? (
                      <div className="col-12" style={{ marginTop: "20px" }}>
                        <div className="alert alert-danger" role="alert">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="col-2"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary btn-block"
                    >
                      {t('change')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  }
);

const withData = inject((stores) => ({
  isEditError: stores.FeesReasonsStore.isEditError,
  editFee: stores.FeesReasonsStore.editFee,
}));

export default withData(EditFee);
