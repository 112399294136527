import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { t } from "i18next";

@observer
class AddClient extends Component {
  state = {
    phone: "",
    firstName: "",
    lastName: "",
    photo: "",
    comment: "",
  };

  chgInp = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value.replace(/^\s+/g, ""),
    });
  };

  createNewClient = () => {
    const clientData = { ...this.state };
    clientData.phone.trim();
    clientData.firstName.trim();
    clientData.lastName.trim();
    if (
      clientData.phone.length > 9 &&
      clientData.firstName.length > 2 &&
      this.state.lastName.length > 2
    ) {
      this.props.addPassengers(clientData);
      this.props.close();
    }
  };

  render() {
    return (
      <div className="col-12">
        <div className="row form-row">
          <div className="col-4">
            <img
              src="https://www.infoniac.ru/upload/iblock/a57/a57928a9ff118f9567bc14c16f2b48fc.jpg"
              className="img-fluid"
              alt="Водитель"
            />
          </div>
          <div className="col-8">
            <div className="row form-row">
              <div className="col-12">{t('name')}</div>
              <div className="col-12">
                <input
                  type="text"
                  value={this.state.firstName}
                  name="firstName"
                  className="form-control"
                  onChange={this.chgInp}
                />
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('last_name')}</div>
              <div className="col-12">
                <input
                  type="text"
                  value={this.state.lastName}
                  name="lastName"
                  className="form-control"
                  onChange={this.chgInp}
                />
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('contact_number')}</div>
              <div className="col-12">
                <input
                  type="text"
                  value={this.state.phone}
                  name="phone"
                  className="form-control"
                  onChange={this.chgInp}
                />
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">{t('comment')}</div>
              <div className="col-12">
                <input
                  type="text"
                  value={this.state.comment}
                  name="comment"
                  className="form-control"
                  onChange={this.chgInp}
                />
              </div>
            </div>
            <div className="row form-row">
              <div className="col-12">
                <button
                  className="btn btn-success"
                  onClick={this.createNewClient}
                  disabled={
                    this.state.phone.length > 9 &&
                    this.state.firstName.length > 2 &&
                    this.state.lastName.length > 2
                      ? false
                      : true
                  }
                >
                  {t('add_client')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const withData = inject((stores) => ({
  addPassengers: stores.PassengersStore.addPassengers,
}));

export default withData(AddClient);
