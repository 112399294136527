import { observable} from 'mobx';
import axios from 'axios';

import { apiIdentity } from '../../../config'

class OrderSourceStore {
  @observable isError = false
  @observable orderSourceList = []

  getOrderSourceData = () => {
    const accessToken = localStorage.getItem('accessToken')
    axios.get(`${apiIdentity}catalog/order-sources`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        console.log(response.data)
        if(response.data.success === false){
          this.isError = true
        } else {         
          this.orderSourceList = [...response.data.data]
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  addCity = city => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.post(`${apiIdentity}catalog/cities`, {name: city}, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getOrderSourceData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  delOrderSource = id => {
    const accessToken = localStorage.getItem('accessToken')
    axios.delete(`${apiIdentity}catalog/order-sources/${id}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getOrderSourceData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  addOrderSource = name => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.post(`${apiIdentity}catalog/order-sources`, {name: name}, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getOrderSourceData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  updSourceName = (id, name) => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.put(`${apiIdentity}catalog/order-sources/${id}`, {id, name}, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getOrderSourceData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }
}

export default new OrderSourceStore();