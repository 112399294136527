import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Table } from "antd";

import ModalSimple from "../ModalSimple";
import CarInfo from "./CarInfo";
import AddCar from "./AddCar";
import ExportToCsv from "../ExportToCsv";
import { t } from "i18next";
import { takePageElements } from "shared/constants";

@observer
class Cars extends Component {
  state = {
    showModal: false,
    showCreateCar: false,
    activeCarID: 0,
    skip: 0,
    take: takePageElements,
    filterCarClass: "",
    filterCarBrand: "",
    filterCarModel: "",
    filterDriverName: "",
    filterDriverCallSign: "",
    filterGovNumber: "",
  };

  close = () => {
    this.setState({
      showModal: false,
    });
  };

  openModal = (id) => {
    this.setState({
      showModal: true,
      activeCarID: id,
    });
  };

  csvHeaders = [
    { label: t('id'), key: "id" },
    { label: t('call_sign'), key: "driverCallSign" },
    { label: t('car'), key: "fullModelName" },
    { label: t('class'), key: "carClass" },
    { label: t('number_plate'), key: "govNumber" },
    { label: t('driver'), key: "driverName" },
  ];

  columns = [
    {
      title: t('id'),
      dataIndex: "id",
    },
    {
      title: t('call_sign'),
      dataIndex: "driverCallSign",
    },
    {
      title: t('car'),
      dataIndex: "fullModelName",
    },
    {
      title: t('car_class'),
      dataIndex: "carClass",
    },
    {
      title: t('number_plate'),
      dataIndex: "govNumber",
    },
    {
      title: t('driver'),
      dataIndex: "driverName",
    },
  ];

  componentDidMount() {
    this.props.getCarBrandsData();
    this.props.getCarClassData();
    this.props.getCarsData({
      skip: this.state.skip,
      take: this.state.take,
    });
  }

  closeCreateCar = () => {
    this.setState({
      showCreateCar: false,
    });
  };

  openCreateCar = () => {
    this.setState({
      showCreateCar: true,
    });
  };

  chgInp = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  applyFilters = () => {
    this.props.getCarsData({
      skip: this.state.skip,
      take: this.state.take,
      classId: this.state.filterCarClass,
      brandId: this.state.filterCarBrand,
      modelId: this.state.filterCarModel,
      driverName: this.state.filterDriverName,
      driverCallSign: this.state.filterDriverCallSign,
      govNumber: this.state.filterGovNumber,
    });
  };

  cancelFilters = () => {
    this.setState({
      filterCarClass: "",
      filterCarBrand: "",
      filterCarModel: "",
      filterDriverName: "",
      filterDriverCallSign: "",
      filterGovNumber: "",
    });
    this.props.getCarsData({
      skip: this.state.skip,
      take: this.state.take,
    });
  };

  render() {
    const { carsList, classList, carBrands } = this.props;
    console.log(carBrands);

    const carBrandList = carBrands.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    const carClass = classList.map((carClass) => {
      return (
        <option value={carClass.id} key={carClass.id}>
          {carClass.name}
        </option>
      );
    });

    let carModelList = "";
    if (this.state.filterCarBrand.length > 0) {
      const carModels = carBrands.filter((item) => {
        if (item.id === this.state.filterCarBrand) {
          return true;
        }
        return false;
      });

      carModelList = carModels[0].models.map((model) => {
        return (
          <option value={model.id} key={model.id}>
            {model.name}
          </option>
        );
      });
    }

    return (
      <div>
        <div className="row">
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterCarClass"
              value={this.state.filterCarClass}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('car_class')}
            >
              <option value="">{t('car_class')}</option>
              {carClass}
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterCarBrand"
              value={this.state.filterCarBrand}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('car_brand')}
            >
              <option value="">{t('car_brand')}</option>
              {carBrandList}
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <select
              name="filterCarModel"
              value={this.state.filterCarModel}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('model')}
              disabled={this.state.filterCarBrand.length > 0 ? false : true}
            >
              <option value="">{t('model')}</option>
              {carModelList}
            </select>
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterDriverName"
              value={this.state.filterDriverName}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('driver_name')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterDriverCallSign"
              value={this.state.filterDriverCallSign}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('call_sign')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <input
              name="filterGovNumber"
              value={this.state.filterGovNumber}
              className="form-control"
              onChange={this.chgInp}
              placeholder={t('car_number')}
            />
          </div>
          <div className="col-3" style={{ paddingBottom: "20px" }}>
            <button
              className="btn btn-warning"
              onClick={this.applyFilters}
              style={{ marginRight: "10px" }}
            >
              {t('filter')}
            </button>
            <button className="btn btn-secondary" onClick={this.cancelFilters}>
              {t('discard')}
            </button>
          </div>
          <div className="col-3 text-right" style={{ paddingBottom: "20px" }}>
            <ExportToCsv
              csvHeaders={this.csvHeaders}
              csvData={carsList}
              csvFileName="cars.csv"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-9"></div>
          <div className="col-3 text-right" style={{ paddingBottom: "20px" }}>
            <button className="btn btn-primary" onClick={this.openCreateCar}>
            {t('create_car')}
            </button>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={carsList}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              this.openModal(record.id);
            },
          })}
        />
        {this.state.showModal ? (
          <ModalSimple close={this.close}>
            <CarInfo carID={this.state.activeCarID} close={this.close} />
          </ModalSimple>
        ) : null}
        {this.state.showCreateCar ? (
          <ModalSimple close={this.closeCreateCar}>
            <AddCar close={this.closeCreateCar} />
          </ModalSimple>
        ) : null}
      </div>
    );
  }
}

const withData = inject((stores) => ({
  getCarsData: stores.CarsStore.getCarsData,
  carsList: stores.CarsStore.carsList,
  classList: stores.CarsClassStore.classList,
  getCarClassData: stores.CarsClassStore.getCarClassData,
  carBrands: stores.CarsMarkStore.carBrands,
  getCarBrandsData: stores.CarsMarkStore.getCarBrandsData,
}));

export default withData(Cars);
