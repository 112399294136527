import { observable} from 'mobx';
import axios from 'axios';

import { apiIdentity } from '../../../config'

class RatesStore {
  @observable ratesList = []

  getRatesData = () => {
    const accessToken = localStorage.getItem('accessToken')
    axios.get(`${apiIdentity}catalog/fares`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        console.log(response.data)
        if(response.data.success === false){
          this.isError = true
        } else {         
          this.ratesList = [...response.data.data]
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  addRate = (value, carClassId, carDeliveryFee) => {
    const accessToken = localStorage.getItem('accessToken')
    const sendedData = {fare: value, carClassId: carClassId, carDeliveryFee: carDeliveryFee }
    
    axios.post(`${apiIdentity}catalog/fares`, sendedData, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getRatesData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  delRate = id => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.delete(`${apiIdentity}catalog/fares/${id}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getRatesData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

}

export default new RatesStore();