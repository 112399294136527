import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Table } from 'antd';
import { t } from 'i18next';

import ModalSimple from '../ModalSimple'
import FeedbackInfo from './FeedbackInfo'

import './Feedback.scss'

@observer
class Feedback extends Component {
  state = {
    showModal: false,
    activeFeedbackID: 0,
    who: 'clients'
  }

  close = () => {
    this.setState({
      showModal: false,
      activeFeedbackID: 0,
    })
  }

  openModal = id => {
    this.props.getFeedbackInfo(id, this.state.who)
    this.setState({
      showModal: true,
      activeFeedbackID: id,
    })
  }

  columns = [    
    {
      title: t('client'),
      dataIndex: 'userName',
    },
    {
      title: t('last_message_date'),
      dataIndex: 'lastMessageAt',      
      render: (text, record) => {
        if(record.lastMessageAt){
          const stillUtc = moment.utc(text).toDate();
          const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
          return (<>{local}</>)
        } else {
          return (<></>)
        }
      }
    },
    {
      title: t('messages'),
      dataIndex: 'messagesCnt',
    },
    {
      title: t('unread'),
      dataIndex: 'newIncomingMessagesCnt',
    },
  ];

  chgFromWho = who => {
    this.setState({
      who
    }, () => {
      this.props.getFeedbackData(this.state.who)
    })
  }

  
  componentDidMount(){
    this.props.getFeedbackData(this.state.who)
  }

  render() {
    const { feedbackList } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-12" style={{marginBottom: "20px"}}>
            {this.state.who === 'clients' ? <button className="btn btn-info" onClick={() => this.chgFromWho('drivers')}>{t('view_requests_from_drivers')}</button> : <button className="btn btn-info" onClick={() => this.chgFromWho('clients')}>{t('view_requests_from_clients')}</button>}
          </div>
        </div>
        <Table columns={this.columns} dataSource={feedbackList} onRow={ (record, rowIndex) => ({
          onDoubleClick: () => {
            this.openModal(record.userId)
          }
        })}/>
        
        {this.state.showModal ? <ModalSimple close={this.close}><FeedbackInfo feedbackID={this.state.activeFeedbackID} who={this.state.who} /></ModalSimple> : null}
      </div>
    )
  }
}

const withData = inject(stores => ({
  feedbackList: stores.FeedbackStore.feedbackList,
  getFeedbackData: stores.FeedbackStore.getFeedbackData,
  getFeedbackInfo: stores.FeedbackStore.getFeedbackInfo,
}))

export default withData(Feedback)
