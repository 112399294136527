import { observable} from 'mobx';
import axios from 'axios';

import { apiIdentity } from '../../../config'

class CarsColorStore {
  @observable isError = false
  @observable colorList = []

  getColorData = () => {
    const accessToken = localStorage.getItem('accessToken')
    axios.get(`${apiIdentity}catalog/colors`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        console.log(response.data)
        if(response.data.success === false){
          this.isError = true
        } else {         
          this.colorList = [...response.data.data]
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  addCarColor = color => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.post(`${apiIdentity}catalog/colors`, {name: color}, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getColorData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  editCarColor = (id, newName) => {
    const updColorList = this.colorList.map( item => {
      const currentItem = {...item}
      if(currentItem.id === id){
        currentItem.className = newName
      }
      return currentItem
    })
    this.colorList = updColorList
  }

  delCarColor = id => {
    const accessToken = localStorage.getItem('accessToken')
    axios.delete(`${apiIdentity}catalog/colors/${id}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getColorData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }

  updCarColor = (id, name) => {
    const accessToken = localStorage.getItem('accessToken')
    
    axios.put(`${apiIdentity}catalog/colors/${id}`, {id, name}, { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then( response => {
        if(response.data.success === false){
          this.isError = true
        } else {
          this.getColorData()
        }
      })
      .catch( err => {
        console.log(err)
      })
  }
}

export default new CarsColorStore();